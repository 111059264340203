import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import store from './store'
import JSZip from 'jszip'
import DialogPromise from '@/../../app-common/vuetify-dialog-promise-pwet'
Vue.use(DialogPromise)

import VueVisible from 'vue-visible'
Vue.use(VueVisible)

import date from 'date-and-time'
import 'date-and-time/plugin/meridiem';
date.plugin('meridiem')
window.date = date

// jquery
window.$ = require('jquery')
window.jQuery = window.$

import VueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
Vue.component('vue-draggable-resizable', VueDraggableResizable)

import '@/../../app-common/js/utilities.js'
import '@/../../app-common/js/sparkl-version.js'
import '@/../../app-common/js/default_site_config.js'
import '@/../../app-common/js/Collection.js'
import './Activity.js'
import '@/../../app-common/scss/sparkl-base.scss'
import '@/../../app-common/scss/sparkl-flavors.scss'
import '@/../../app-common/scss/star-background-2.scss'

Vue.config.productionTip = false

new Vue({
	vuetify,
	store,
	render: h => h(App)
}).$mount('#app')
