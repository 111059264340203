<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container>
		<div style="visibility:hidden" v-html="lti_form"></div>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
	}},
	computed: {
		...mapState(['lti_form']),
	},
	created() {
	},
	mounted() {
		this.$nextTick(()=>$('[name=ext_submit]').click())
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
