<template>
<div class="k-collections-view">
	<div v-if="signed_in" class="k-portal-welcome-message">Good {{ time_of_day }}, {{user_info.name_given}}!</div>

	<!-- "Call to action" message and buttons at the top; should only be shown if the user is or might be a teacher -->
	<div v-if="show_user_calls_to_action" class="k-login-intro-to-sparkl k-login-intro-to-sparkl-no-animation mt-8">
		<div>{{app_noun}} is a system for creating and delivering digital learning activities.</div>
		<div class="text-center mt-2">
			<v-btn large color="primary" class="ma-2" style="width:300px" @click="show_about_dialog"><v-icon class="mr-2">fas fa-info-circle</v-icon>Learn More About {{app_noun}}</v-btn>
			<v-btn v-if="!demo_collection" large color="primary" class="ma-2" style="width:300px" @click="show_demo_activities">View Demo Activities <v-icon class="ml-2">fas fa-arrow-alt-circle-right</v-icon></v-btn>
		</div>
	</div>

	<!-- controls to search for activities and create a new activity -->
	<div v-if="!show_user_calls_to_action||user_is_instructor" class="mt-4 mx-auto" :style="signed_in&&user_is_instructor?'max-width:1000px':'max-width:600px'">
		<div class="d-flex flex-wrap align-center justify-center">
			<v-text-field v-if="activities.length>0" dense class="ma-2" style="flex:1 1 300px" outlined hide-details label="Search activities…" v-model="search" clearable clear-icon="fas fa-circle-xmark" append-icon="fa-search" autocomplete="new-password"></v-text-field>
			<!-- <v-autocomplete v-if="teacher_is_using_activities" dense style="flex:0 1 180px" class="ma-2" :menu-props="{ contentClass:'k-dense-autocomplete-menu', maxHeight: 600, light:false }" outlined hide-details label="Filter…" background-color="#333" small-chips deletable-chips multiple v-model="activity_labels" :items="label_options" :filter="label_option_filter"></v-autocomplete> -->
			<!-- <v-select v-if="activities.length>0&&teacher_is_using_activities" dense class="ma-2" style="flex:0 1 180px" :menu-props="{ light:false }" v-model="sort_by" :items="sort_by_options" label="Sort Activities By…" outlined background-color="#333" hide-details></v-select> -->
			<v-btn v-if="user_is_instructor" large class="ma-2" color="pink darken-4" dark @click="create_activity"><v-icon class="mr-2">fas fa-plus</v-icon>Create a New Activity</v-btn>
		</div>
	</div>

	<!-- "Featured collection" specifically requested by the user -->
	<div v-if="featured_collection_title" class="k-portal-collection-featured-collection-wrapper mb-8">
		<div class="k-collections-view-part-heading"><span v-html="featured_collection_title"></span><nobr v-if="featured_collection_type=='student'&&featured_collection_creator_displayname" class="ml-4" style="font-size:0.8em">({{featured_collection_creator_displayname}})</nobr></div>
		<CollectionView v-if="featured_collection_obj" :collection_obj="featured_collection_obj" />
		<div v-else class="k-portal-collection"><div class="k-portal-collection-activities-outer-wrapper px-2 py-4">
			<v-spacer/>
			<div v-if="!signed_in" class="text-center">You must <v-btn color="primary" class="mx-1" @click="sign_in"><v-icon small class="mr-2">fas fa-sign-in-alt</v-icon>Sign In</v-btn> to view activities in this collection</div>
			<div v-else class="text-center">
				<div v-if="show_featured_collection_subscription_btn">
					You must <v-btn @click="subscribe_to_featured_collection" color="primary" class="mx-2 k-tight-btn">Enter an access code<v-icon small class="ml-2">fas fa-unlock-alt</v-icon></v-btn> to view activities in this collection
					<div v-if="user_is_student" class="mt-5"><i>If you’re not sure what Access Code to enter, <b class="pink--text text--lighten-1">ask your teacher</b>.</i></div>
				</div>
				<div v-else>You do not have access to view activities in this collection</div>
			</div>
			<v-spacer/>
		</div></div>
	</div>

	<!-- Collections the user is subscribed to (exception: don't show the featured collection here, even if the user is explicitly subscribed to it) -->
	<div v-if="show_user_collections">
		<div v-if="more_collections_heading_text" class="d-flex align-center pb-2 mb-2" style="border-bottom:1px solid #ccc;">
			<div class="k-collections-view-part-heading" v-html="more_collections_heading_text"></div>	<!-- this may be blank -->
		</div>

		<div v-if="visible_collection_objs.length==0&&activities.length>0" class="k-portal-collection"><div class="k-portal-collection-activities-outer-wrapper pa-2">
			<i>No activities match your search criteria</i>
		</div></div>

		<div :class="(signed_in||visible_collection_objs.length>1)?'':'k-portal-collection-featured-collection-wrapper'"> <!-- note that we need this enclosing div for first-of-type to work in CollectionView -->
			<CollectionView v-for="(vco, i) in visible_collection_objs" :key="vco.uuid"
				:collection_obj="vco"
				@edit_collection="edited_collection_id=vco.collection.collection_id"
				/>
		</div>
	</div>

	<!-- Collection of “standard” demo activities (allaccess-s); may or may not be shown -->
	<div v-if="demo_collection_obj" class="k-portal-collection-featured-collection-wrapper mb-8">
		<div class="k-collections-view-part-heading">Demo Activities</div>
		<CollectionView :collection_obj="demo_collection_obj" />
	</div>
	<div v-if="!show_user_calls_to_action&&!demo_collection_obj" class="text-right mt-6">
		<v-btn v-if="!demo_collection" small text @click="show_demo_activities">View Demo Activities <v-icon small class="ml-2">fas fa-arrow-alt-circle-right</v-icon></v-btn>
	</div>

	<!-- More call-to-action info -->
	<div v-if="show_user_calls_to_action">
		<div v-if="user_is_instructor" class="k-portal-collection-no-activities text-center mt-12 mb-4">
			<div v-if="demo_collection">Try a <b class="pink--text text--lighten-1">demo activity</b> above, or click a button above to <b class="pink--text text--lighten-1">create</b> your first {{app_noun}} activity <nobr>or <b class="pink--text text--lighten-1">learn more</b> about {{app_noun}}.</nobr></div>
			<div v-else>Click a button above to <b class="pink--text text--lighten-1">learn more</b> about {{app_noun}}, view <b class="pink--text text--lighten-1">demo activities</b>, <nobr>or <b class="pink--text text--lighten-1">create</b> a new activity.</nobr></div>
		</div>
		<div v-else>
			<ul class="k-portal-collection-no-activities">
				<li class="mb-2">If you're a <b class="pink--text text--lighten-1">teacher</b>, you can request teacher access by clicking the button below, {{!demo_collection ? 'or click a button above to learn more about '+app_noun+' or try demo activities.' : 'learn more about '+app_noun+' by clicking the button above, or try a demo activity.'}}</li>
				<li>If you’re a <b class="pink--text text--lighten-1">student</b>, your teacher may not have assigned any {{app_noun}} activities to you yet. Check back later, or try a demo activity if you’re curious about {{app_noun}}.</li>
			</ul>
			<div class="text-center mt-8">
				<v-btn x-large color="pink darken-4" dark @click="request_teacher_access" class="mx-2">Request Teacher Access <v-icon class="ml-2">fas fa-chalkboard-teacher</v-icon></v-btn>
			</div>
		</div>
	</div>

	<EditCollection v-if="edited_collection_id!=-1"
		:edited_collection_id="edited_collection_id"
		:student_search_master="false"
		:show_activities="true"
		@dialog_cancel="edited_collection_id=-1"
	/>

	<ChangeLog v-if="show_changelog" @dialog_done="show_changelog=false" />
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'
import CollectionView from './CollectionView'
import ChangeLog from './ChangeLog'
import EditCollection from '@/../../app-common/components/collections/EditCollection'

export default {
	components: { ChangeLog, CollectionView, EditCollection },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		show_changelog: false,
		show_instructions: true,
		show_templates: false,

		search: '',
		sort_by: '',
		sort_by_options: [
			{ value: 'collection_sequence', text: 'Collection Order'},
			{ value: 'login_at', text: 'Last Access Date' },
			{ value: 'updated_at', text: 'Last Modified Date' },
			{ value: 'created_at', text: 'Creation Date' },
			{ value: 'title', text: 'Title' },
		],
		expand_trigger: 0,

		edited_collection_id: -1,
		time_of_day: 'day',
	}},
	computed: {
		...mapState(['user_info', 'activities', 'groups', 'teacher_collections', 'demo_collection', 'featured_collection_id', 'grades', 'subjects', 'user_is_instructor', 'user_is_student', 'featured_collection_title', 'featured_collection_type', 'featured_collection_creator_displayname']),
		...mapGetters(['lti_link_id', 'context_standalone', 'context_gc', 'context_lti', 'signed_in', 'app_noun']),
		search_terms() { return empty(this.search) ? [] : this.search.toLowerCase().split(/\s+/) },
		activity_labels:{
			get() {
				if (!this.user_is_instructor) return []
				return this.$store.state.activity_labels
			},
			set(val) {
				this.$store.commit('set', ['activity_labels', val])
				U.local_storage_set('sparkl_portal_activity_labels', val)
			}
		},
		demo_collection_obj() {
			if (!this.demo_collection) return null

			let c = this.demo_collection

			let co = {
				uuid: U.new_uuid(),
				collection: c,
				initially_expanded: true,
				activities: [],
			}

			for (let activity of c.activities) {
				activity = this.activities.find(x=>x.activity_id==activity.activity_id)
				if (activity) {
					// if we have search terms, filter on them
					let include = true
					if (this.search_terms.length > 0) {
						let title = activity.title.toLowerCase()
						for (let t of this.search_terms) {
							if (title.indexOf(t) == -1) include = false
						}
					}

					if (include) co.activities.push(activity)
				}
			}

			return co
		},
		featured_collection_obj() {
			if (!this.featured_collection_id) return null
			let c = this.groups.find(x=>x.collection_id == this.featured_collection_id)
			if (!c) c = this.teacher_collections.find(x=>x.collection_id == this.featured_collection_id)
			if (!c) return null

			// initially expand the featured collection iff we're only viewing one collection
			let initially_expanded = false
			if ((this.groups.length + this.teacher_collections.length) <= 1) {
				initially_expanded = true
			}

			let co = {
				uuid: U.new_uuid(),
				collection: c,
				initially_expanded: initially_expanded,
				activities: [],
			}

			for (let activity of c.activities) {
				activity = this.activities.find(x=>x.activity_id==activity.activity_id)
				if (activity) {
					// if we have search terms, filter on them
					let include = true
					if (this.search_terms.length > 0) {
						let title = activity.title.toLowerCase()
						for (let t of this.search_terms) {
							if (title.indexOf(t) == -1) include = false
						}
					}

					if (include) co.activities.push(activity)
				}
			}

			return co
		},
		raw_collection_objs() {
			let arr = []

			// show teacher collections
			// add teacher collections that you're an editor for, then teacher collections you aren't an editor for
			for (let c of this.teacher_collections) {
				if (c.collection_id == this.featured_collection_id) continue	// skip featured collection
				if (c.user_is_editor) arr.push({uuid: U.new_uuid(), collection: c})
			}
			for (let c of this.teacher_collections) {
				if (c.collection_id == this.featured_collection_id) continue	// skip featured collection
				if (!c.user_is_editor) arr.push({uuid: U.new_uuid(), collection: c})
			}

			// finally, for teachers add a "fake collection" for all activities you're an editor for
			if (this.user_is_instructor) {
				let extra_collection = new Collection({
					collection_id: -2,
					title: 'All activities you’ve created',
					activities: [],
				})
				for (let activity of this.activities) {
					if (activity.user_is_editor()) {
						extra_collection.activities.unshift(activity)
					}
				}
				arr.push({uuid: U.new_uuid(), collection: extra_collection})
			}

			return arr
		},
		visible_collection_objs() {
			// note that this array will include all collections we're showing the user *except* the featured collection
			let arr = []
			++this.expand_trigger	// triggers CollectionView to honor initially_expanded

			// for each raw_collection_objs object...
			for (let rco of this.raw_collection_objs) {
				// reassign to a new object, adding some additional properties
				rco = {
					uuid: rco.uuid,
					collection: rco.collection,
					initially_expanded: (this.raw_collection_objs.length == 1),
					expand_trigger: this.expand_trigger,
					activities: [],
					total_activity_count: 0,
					has_hidden_activities: false,
					filtering_by_search_terms: (this.search_terms.length > 0),
				}

				// get a list of this collection's activities that match the search/filter criteria
				for (let activity of rco.collection.activities) {
					let include = true
					// get the activity object from the store.activities list, which includes more data than that in the collections record
					activity = this.activities.find(x=>x.activity_id==activity.activity_id)

					// filter on activity_labels
					for (let label of this.activity_labels) {
						// if any label is not found, don't include the activity
						if (!activity.mappings.find(x=>x.mapping==label)) include = false
					}

					// if we have search terms, filter on them
					if (this.search_terms.length > 0) {
						let title = activity.title.toLowerCase()
						for (let t of this.search_terms) {
							if (title.indexOf(t) == -1) include = false
						}
					}

					// keep a count of how many would be showing if we weren't hiding some in the next line
					++rco.total_activity_count
					
					// don't show more than X in a category; user can search to find things that are hidden
					if (rco.activities.length >= 100) {
						include = false
						rco.has_hidden_activities = true
					}

					if (include) {
						rco.activities.push(activity)
					}
				}

				// if no activities, don't show the collection
				if (rco.activities.length == 0) continue

				// sort the rco's activities
				rco.activities.sort((a,b)=>{
					// for actual collections, sort by collection_sequence, then title
					if (rco.collection.collection_id != -2) {
						// sort ascending
						if (a.collection_sequence < b.collection_sequence) return -1
						if (b.collection_sequence < a.collection_sequence) return 1
						return 0

					} else {
						// else sort by updated_at, descending
						if (a.updated_at < b.updated_at) return 1
						if (b.updated_at < a.updated_at) return -1
						return 0
					}

					// // if sorting by title or collection_sequence, ascending
					// if (!this.user_is_instructor || this.sort_by == 'collection_sequence') {
					// 	if (a[this.sort_by] < b[this.sort_by]) return -1
					// 	if (b[this.sort_by] < a[this.sort_by]) return 1
					//
					// } else if (this.sort_by == 'title') {
					// 	if (a[this.sort_by].toLowerCase() < b[this.sort_by].toLowerCase()) return -1
					// 	if (b[this.sort_by].toLowerCase() < a[this.sort_by].toLowerCase()) return 1
					//
					// // otherwise by date; sort descending
					// } else {
					// 	if (a[this.sort_by] < b[this.sort_by]) return 1
					// 	if (b[this.sort_by] < a[this.sort_by]) return -1
					// }
					// return 0
				})
				// console.log(rco.activities)

				// push the rco to arr
				arr.push(rco)
			}

			// sort the rcos
			arr.sort((a,b)=>{
				// always put the "other" rco last
				if (a.collection.collection_id == -2) return 1
				if (b.collection.collection_id == -2) return -1

				// sort by collection type: student, non-lti first; then student, lti; then teacher-owned by this user; then teacher-not owned; then allaccess-s
				let a_type = ((a.collection.type.indexOf('allaccess') > -1) ? 'z' : a.collection.type) // 'student' comes before 'teacher'
					+ a.collection.is_lti_context_group()	// for student types, could be false or true (false comes before true); for teacher types, always false
					+ (a.collection.user_is_editor() ? '0' : '1')	// makes owned teacher collections come before unowned ones
				let b_type = ((b.collection.type.indexOf('allaccess') > -1) ? 'z' : b.collection.type) + b.collection.is_lti_context_group() + (b.collection.user_is_editor() ? '0' : '1')
				if (a_type < b_type) return -1
				if (b_type < a_type) return 1

				// then sort by name
				if (a.collection.title < b.collection.title) return -1
				if (b.collection.title < a.collection.title) return 1
				return 0
			})

			// If a search term is entered, or if (the user isn't signed in and there's only one collection), reveal all matching collections; otherwise don't reveal any
			if (this.search_terms.length > 0 || (!this.signed_in && arr.length == 1)) {
				for (let rco of arr) rco.initially_expanded = true
			}

			// return array of rco objects
			return arr
		},
		teacher_is_using_activities() {
			// determine if the user is a teacher who's "using" activities; to start out, if it's not a teacher, return false
			if (!this.user_is_instructor) return false

			for (let activity of this.activities) {
				// if the user is an editor or result_count is > 0, return true
				if (activity.result_count > 0 || activity.user_is_editor()) return true

				// else if the activity is included in one of the teacher's classes, return true
				for (let group of this.groups) {
					if (group.includes_activity(activity.activity_id)) return true
				}
			}

			// if we get to here, it appears the teacher isn't using any activities yet
			return false
		},
		label_options() {
			let arr = [
				{header: 'Subjects'},
			]

			for (let val in this.subjects) {
				arr.push({value: val, text: this.subjects[val].l})
			}

			arr.push({header: 'Grade Levels'})

			for (let val in this.grades) {
				arr.push({value: val, text: this.grades[val]})
			}

			return arr
		},
		more_collections_heading_text() {
			// if not signed in or the user isn't subscribed to any collections, this won't be showing
			// if a student
			if (this.user_is_student) {
				// TODO: for students we should show activities they've already started
				// if we didn't get any activities, don't show this at all; the user can request to see demo activities if they like
				if (this.activities.length == 0) {
					return ''
				// else we got activities. if we're not showing a featured collection's activities, just say "Activities assigned..."
				} else if (!this.featured_collection_obj) {
					return 'Activities Assigned to Your Classes'
				// else we got a featured collection, so we say "your other classes"
				} else {
					return 'Activities Assigned to Your Other Classes'
				}

			// if we get to here it's a teacher, so show "Your collections" if we're not showing a featured collection, or "Your other collections" if we are
			} else {
				if (!this.featured_collection_obj) {
					return 'Your Collections'
				} else {
					return sr('Your Other Collections')
				}
			}
		},
		show_user_collections() {
			// if you're not signed in, you don't have user collections, so return false
			if (!this.signed_in) return false
			// else you're signed in, so return true if we have at least one raw_collection_obj to show that
			if (this.raw_collection_objs.length == 0) return false
			// if you have a single raw_collection_obj...
			if (this.raw_collection_objs.length == 1) {
				// if it's not the "all activities" collection, it's a "real" collection, so return true
				if (this.raw_collection_objs[0].collection.collection_id != -2) return true
				// else it is the "all activities" collection; if it has at least one activity, return true
				if (this.raw_collection_objs[0].collection.activities.length > 0) return true
				// if we get to here, you don't actually have any activities, so return false
				return false
			} else {
				// else you must have at least one "real" collection, so return true
				return true
			}
			return false
		},
		show_user_calls_to_action() {
			// show the "calls to action" -- help getting teachers configured to use Sparkl -- if...
			// the user isn't signed in
			if (!this.signed_in) return true
			// or the user's account only has student access, but it might be a teacher who hasn't requested teacher access...
			if (this.user_is_student) {
				// if the user is subscribed to at least one student collection, assume they're actually a student
				if (this.groups.length > 0) return false

				// else if a featured collection wasn't requested, or a featured collection *was* requested and it's a teacher collection, they might be a teacher
				if (this.featured_collection_type == 'teacher' || !this.featured_collection_type) return true
				// (if the featured collection is type student, it's more likely that it's a student who hasn't registered their access code to get to their teacher's assignments for them)
			} else {
				// or the user's account has teacher access, and they aren't using any activities yet
				if (!this.teacher_is_using_activities) return true
			}
			// if we get to here don't show the calls to action
			return false
		},
		show_featured_collection_subscription_btn() {
			// this flag will only come into play if featured_collection_title is set but featured_collection_obj is not set,
			// meaning that the user asked to see a collection, but isn't currently subscribed to see the collection.
			// show the btn to allow the user to subscribe to the featured collection if...

			// the user is a teacher and they're viewing a teacher collection
			if (this.user_is_instructor && this.featured_collection_type == 'teacher') return true
			// or the user is a student and they're viewing a student collection
			if (this.user_is_student && this.featured_collection_type == 'student') return true
			// else don't show the btn (instead we'll just show a message saying they don't have access)
			return false
		}
	},
	watch: {
		sort_by: { immediate: true, handler(val) {
			if (empty(val)) {
				this.sort_by = U.local_storage_get('sparkl_portal_sort_activities_by', 'updated_at')
			} else {
				U.local_storage_set('sparkl_portal_sort_activities_by', val)
			}
		}},
	},
	created() {
		// make sure we don't have the legacy 'featured' label
		if (this.activity_labels.includes('featured')) {
			this.activity_labels = this.activity_labels.filter(x=>x!='featured')
		}

		vapp.collections_component = this
	},
	mounted() {
		// hash shortcuts to various places
		if (document.location.hash == '#privacy') vapp.show_privacy_dialog()
		else if (document.location.hash == '#about') vapp.show_about_dialog()
		else if (document.location.hash == '#changelog') vapp.show_about_dialog()
		else if (document.location.hash == '#canvas') this.show_canvas_instructions()

		this.calculate_time_string()
		setInterval(()=>this.calculate_time_string(), 60000)
	},
	methods: {
		calculate_time_string() {
			let d = new Date()
			let hour = d.getHours()
			if (hour > 2 && hour < 12) this.time_of_day = 'morning'
			else if (hour < 18) this.time_of_day = 'afternoon'
			else this.time_of_day = 'evening'
		},

		label_option_filter(item, query_text, item_text) {
			query_text = query_text.toLowerCase()
			return item_text.toLowerCase().indexOf(query_text) > -1
		},

		create_activity() {
			if (this.context_lti) {
				let payload = { activity_id: 0 }
				// set new_activity flag so that we'll open in exercises mode and with a note to click the edit btn
				U.local_storage_set('new_activity', 'yes')

				// call configure_lti_link with 0 to set up the new link
				this.$store.dispatch('configure_lti_link', payload)
				return
			}

			this.$prompt({
				text: 'Enter a title for your new Activity (you can always change it later):',
				initialValue: 'Untitled Activity',
				acceptText: 'Create Activity'
			}).then(title => {
				if (!empty(title)) {
					this.$store.dispatch('add_activity', {title: title}).then((activity)=>{
						window.location = vapp.activity_url(activity.activity_id)
					})
				}
			}).catch(n=>{
			}).finally(f=>{});
		},

		subscribe_to_featured_collection() {
			// if (this.featured_collection_type == 'student') this.subscribe_to_featured_student_collection
			// else this.subscribe_to_featured_teacher_collection
			let title, msg
			if (this.featured_collection_type == 'student') {
				// mimic the language we use when entering an access code on a direct activity link
				title = 'Enter Access Code'
				msg = 'Enter an Access Code to join this '+this.app_noun+' Group.<div style="color:#777; font-size:0.9em; text-align:left; font-style:italic; padding-top:10px;">(If you don’t know what Access Code to use, ask your teacher.)</div>'

			} else {
				title = 'Subscribe to an Activity Collection'
				msg = sr('Enter the Access Code to subscribe to collection <b>“$1”</b>. (If you don’t know this access code, ask the person who created the collection.)', this.featured_collection_title)
			}

			this.$prompt({
				title: title,
				text: msg,
				promptType: 'text',
				initialValue: '',
				dialogMaxWidth: 500,
				acceptText: 'OK',
			}).then(access_code => {
				access_code = $.trim(access_code)
				if (!empty(access_code)) {
					let payload = {
						access_code: access_code,
						collection_id: this.featured_collection_id,
					}
					this.$store.dispatch('subscribe_to_collection', payload).then(()=>{
						this.$alert('Success!')

					}).catch((error)=>{
						console.log('subscription error: ' + error)
						let msg
						if (error == 'invalid_code' || error == 'code_mismatch') {
							// invalid_code means the code wasn't valid for any collection; code_mismatch means it's valid but not for this collection.
							// give the same msg for either one, but we console.log the error for debugging/support purposes
							if (this.featured_collection_type == 'student') {
								msg = 'The Access Code you entered is not valid. Ask your teacher for the Access Code you should use.'
							} else {
								msg = sr('The Access Code you entered does not correspond to collection “$1”.', this.featured_collection_title)
							}
						} else {
							msg = 'An error occurred while attempting to verify the Access Code you entered.'
						}

						this.$alert(msg).then(()=>{
							this.subscribe_to_featured_collection()
						})
					})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		show_about_dialog() {
			vapp.show_about_dialog()
		},

		request_teacher_access() {
			vapp.request_teacher_access()
		},

		show_demo_activities() {
			vapp.initialize_app({show_demo_activities:'true'}, true)
		},

		sign_in() {
			// to sign in, call the click event on the google sign-in btn
			$('.k-google_signin_btn').click()
		},
	}
}
</script>

<style lang="scss">
@keyframes login-welcomecolorchange {
	0%   {color: $v-green-lighten-1;}
	20%  {color: $v-blue-lighten-1;}
	40%  {color: $v-purple-lighten-1;}
	60%  {color: $v-orange-lighten-1;}
	80%  {color: $v-red-lighten-1;}
	100% {color: $v-green-lighten-1;}
}
$login-welcome-color-animation: login-welcomecolorchange 20s infinite;

.k-portal-welcome-message {
	text-align:center;
	font-size:30px;
	margin:32px 0 20px 0;
	font-weight:bold;
	// animation: $login-welcome-color-animation;
}

.k-collections-view {
	max-width:calc(100vw - 40px);
	margin:0 auto 44px auto;	// need the margin at the bottom to make sure the bottom of the UI doesn't overlap with the footer
	// padding:12px 0;
}

.k-collections-view-part-heading {
	font-size:36px;
	margin-top:8px;
	margin-bottom:8px;
}

.k-learn-more {
	display:inline-block;
	padding: 4px 12px;
	margin-top:32px;
	border-radius:12px!important;
	background-color:#444;
}

.k-portal-collection-no-activities {
	max-width:720px;
	margin:0 auto;
	font-size:18px;
}

// smartphone screens, landscape or portrait
@media (max-width: 760px) {
	.k-collections-view {
		max-width:100vw;
		margin:0 0 50px 0;
		padding:10px 0;
		border-radius:0px;
	}

	.k-learn-more {
		border-radius:0!important;
		border-left:0!important;
		border-right:0!important;
		font-size:16px!important;
		.k-portal-activities-learn-more-or {
			display:none;
		}
	}
}
</style>
