<template><v-dialog v-model="dialog_open" max-width="1000" persistent overlay-opacity="0.95" scrollable><v-card class="k-dark-dialog-card k-full-width-dialog-card">
	<v-card-title class="k-dark-dialog-card-title"><b>Manage Teacher Collections</b></v-card-title>
	<v-card-text class="mt-3" style="font-size:16px">

		<!-- TEACHER COLLECTIONS TABLE -->
		<div class="k-text-block mb-3 pt-2 d-flex">
			<div style="font-size:18px">Your <b>Activity Collections:</b></div>
			<v-spacer />
			<v-btn small color="primary" @click="new_collection('teacher')"><v-icon class="mr-1" small>fas fa-plus</v-icon> New Activity Collection</v-btn>
		</div>
		<div v-if="teacher_rows.length==0" class="k-text-block text-center mb-4">You have not created any Activity Collections.</div>
		<v-data-table v-else
			:headers="teacher_headers"
			:items="teacher_rows"
			disable-sort
			hide-default-footer
			:items-per-page="-1"
			class="k-manage-collections-table-class"
		>
			<template v-slot:item="{ item }"><tr :class="row_css(item)">
				<td>
					<v-icon class="mr-1" small @click="edit_collection(item.collection)">fas fa-edit</v-icon> {{item.title}} <v-icon v-if="item.collection.type=='allaccess-s'" color="#fff" small>fas fa-exclamation-circle</v-icon>
				</td>
				<td class="text-center">
					<span class="k-access-code">{{item.access_code}} <CopyBtn v-if="item.collection.access_code" color="#999" small :val="item.access_code"/></span>
					<v-icon v-if="item.collection.access_code" @click="show_collection_url(item)" small color="#999" class="ml-2">fas fa-link</v-icon>
				</td>
				<td class="text-center">
					{{item.n_subscribers}}
				</td>
				<td class="text-center">
					{{item.n_activities}}
				</td>
			</tr></template>
		</v-data-table>

		<!-- TEACHER-SUBSCRIBED COLLECTIONS TABLE -->
		<div class="k-text-block mb-3 pt-5 d-flex" style="border-top:1px solid #999">
			<div style="font-size:18px">Other Activity Collections you’ve <b>subscribed to:</b></div>
			<v-spacer />
			<v-btn small color="primary" @click="subscribe_to_collection" class="k-tight-btn"><v-icon class="mr-1" small>fas fa-plus</v-icon> Subscribe to an Activity Collection</v-btn>
		</div>
		<div v-if="teacher_subscription_rows.length==0" class="k-text-block text-center">You have not subscribed to any Activity Collections.</div>
		<v-data-table v-else
			:headers="teacher_subscription_headers"
			:items="teacher_subscription_rows"
			disable-sort
			hide-default-footer
			:items-per-page="-1"
			class="k-manage-collections-table-class"
		>
			<template v-slot:item="{ item }"><tr :class="row_css(item)">
				<td>
					<v-icon class="mr-1" small @click="unsubscribe_collection(item.collection)">fas fa-times</v-icon> {{item.title}} <v-icon v-if="item.collection.type=='allaccess-s'" color="#fff" small>fas fa-exclamation-circle</v-icon>
				</td>
				<td class="text-center">
					<span class="k-access-code">{{item.access_code}} <CopyBtn v-if="item.collection.access_code" color="#999" small :val="item.access_code"/></span>
					<v-icon v-if="item.collection.access_code" @click="show_collection_url(item)" small color="#999" class="ml-2">fas fa-link</v-icon>
				</td>
				<!-- don't show subscribers for collections you're not an editor for -->
				<td class="text-center">
					{{item.n_activities}}
				</td>
			</tr></template>
		</v-data-table>

		<EditCollection v-if="edit_collection_dialog_open"
			:edited_collection_id="edited_collection_id"
			:collection_type="new_collection_type"
			:show_activities="true"
			@dialog_cancel="edit_collection_dialog_open=false"
		/>

	</v-card-text>
	<v-card-actions class="k-dark-dialog-card-btns">
		<v-spacer></v-spacer>
		<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import draggable from 'vuedraggable'
import CopyBtn from '@/../../app-common/components/CopyBtn'
import EditCollection from './EditCollection'
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'ManageTeacherCollections',
	components: { draggable, CopyBtn, EditCollection },
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		teacher_headers: [
			{ text: 'Collection Title', align: 'left', value:'name' },
			{ text: 'Access Code/Link', align: 'center', value:'access_code' },
			{ text: 'Subscriber Count', align: 'center', value:'n_subscribers' },
			{ text: 'Activity Count', align: 'center', value:'n_activities' },
		],
		teacher_subscription_headers: [
			{ text: 'Collection Title', align: 'left', value:'name' },
			{ text: 'Access Code/Link', align: 'center', value:'access_code' },
			{ text: 'Activity Count', align: 'center', value:'n_activities' },
		],
		edit_collection_dialog_open: false,
		edited_collection_id: -1,
		new_collection_type: '',
	}},
	computed: {
		...mapState(['user_info', 'groups', 'teacher_collections', 'ad']),
		...mapGetters([]),
		teacher_rows() {
			let arr = []
			let i = 0
			for (let collection of this.teacher_collections) {
				// skip if this user isn't an editor for the collection
				if (!collection.user_is_editor()) continue

				let o = {
					index: i,
					title: collection.title,
					access_code: collection.access_code ? collection.access_code : '  —  ',
					n_subscribers: collection.subscriber_count(),
					n_activities: collection.activities.length,
					collection: collection
				}
				arr.push(o)
				++i
			}

			arr.sort((a,b)=>{
				// all-access collections on the bottom
				if (a.collection.type=='allaccess-s' && !b.collection.type!='allaccess-s') return 1
				if (b.collection.type=='allaccess-s' && !a.collection.type!='allaccess-s') return -1

				// then sort alphabetically
				if (a.collection.title.toLowerCase() < b.collection.title.toLowerCase()) return -1
				if (b.collection.title.toLowerCase() < a.collection.title.toLowerCase()) return 1
				return 0
			})

			return arr
		},
		teacher_subscription_rows() {
			let arr = []
			let i = 0
			for (let collection of this.teacher_collections) {
				// skip if this user *is* an editor for the collection -- i.e. only do collections where the user is subscribed
				if (collection.user_is_editor()) continue

				let o = {
					index: i,
					title: collection.title,
					access_code: collection.access_code ? collection.access_code : '  —  ',
					n_subscribers: collection.subscriber_count(),
					n_activities: collection.activities.length,
					collection: collection
				}
				arr.push(o)
				++i
			}

			arr.sort((a,b)=>{
				// all-access collections on the bottom
				if (a.collection.type=='allaccess-s' && !b.collection.type!='allaccess-s') return 1
				if (b.collection.type=='allaccess-s' && !a.collection.type!='allaccess-s') return -1

				// then sort alphabetically
				if (a.collection.title.toLowerCase() < b.collection.title.toLowerCase()) return -1
				if (b.collection.title.toLowerCase() < a.collection.title.toLowerCase()) return 1
				return 0
			})

			return arr
		},
	},
	watch: {
	},
	created() {
	},
	mounted() {
		vapp.manage_collections_interface = this
	},
	methods: {
		row_css(item) {
		},

		collection_url(item) {
			return window.location.origin + '/c/' + item.collection.collection_id
		},

		show_collection_url(item) {
			let url = this.collection_url(item)
			let msg = sr('Use this URL to share this Activity Collection with other teachers.')
			if (item.collection.restricted) msg += sr(' Remember to pass along the Access Code for the collection: <b>$1</b>', item.collection.access_code)
			this.$prompt({
				title: sr('Link for “$1”', item.collection.title),
				text: msg,
				initialValue: url,
				acceptText: 'OK',
				hideCancel: false,	// set to true to hide the cancel button
			})
		},

		edit_collection(c) {
			this.edited_collection_id = c.collection_id
			this.edit_collection_dialog_open = true
		},

		new_collection(type) {
			this.edited_collection_id = 0
			this.new_collection_type = type
			this.edit_collection_dialog_open = true
		},

		subscribe_to_collection() {
			let initial_value = ''
			// TEMP: use a static value for testing purposes
			// initial_value = 'CWRNM'

			this.$prompt({
				title: 'Subscribe to an Activity Collection',
				text: 'Enter the Access Code for the Sparkl Activity Collection you’d like to subscribe to.',
				promptType: 'text',
				initialValue: initial_value,
				acceptText: 'OK',
			}).then(access_code => {
				access_code = $.trim(access_code)
				if (!empty(access_code)) {
					let payload = {
						access_code: access_code,
						collection_id: this.featured_collection_id,
					}
					this.$store.dispatch('subscribe_to_collection', payload).then(()=>{
						this.$alert('Subscription successful.')

					}).catch((error)=>{
						console.log(error)
						let msg
						if (error == 'invalid_code') {
							msg = 'The Access Code you entered does not correspond to a known Activity Collection.'
						} else if (error == 'not_teacher_collection') {
							msg = 'The Access Code you entered is for a Sparkl Group, not a teacher-available Activity Collection. You may want to ask the creator of the activities you want to use to create an Activity Collection, add the activities to that Collection, and give you the Access Code for the collection.'
						} else {
							msg = 'An error occurred while attempting to verify the Access Code you entered.'
							console.log(error)
						}

						this.$alert(msg).then(()=>{
							this.subscribe_to_collection()
						})
					})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		unsubscribe_collection(collection) {
			this.$confirm({
			    title: 'Unsubscribe From Collection',
			    text: sr('Are you sure you want to unsubscribe from collection “$1”?', collection.title),
			    acceptText: 'Unsubscribe',
				acceptColor: 'red',
			}).then(y => {
				this.$store.dispatch('unsubscribe_to_collection', collection.collection_id)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

	}
}
</script>

<style lang="scss">
// css is in sparkl-base.scss
</style>
