<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-container>
	<v-layout text-xs-center wrap>
		<v-flex xs12>
			<h2>SPARKL Statistics</h2>
			<v-data-table
				:headers="headers"
				:items="stats"
				:footer-props="footer_options"
				:options="table_options"
				:sort-by="'timestamp'"
				:sort-desc="true"
				:must-sort="true"
				class="k-sparkl-stats-table mt-4"
				dense
			>
				<template v-slot:item="{ item }"><tr>
					<td style="white-space:nowrap">{{item.date_display}}</td>
					<td>{{item.total_users}}</td>
					<td>{{item.total_logins}}</td>
					<td>{{item.total_activities}}</td>
					<td>{{item.total_results}}</td>
					<td><v-btn x-small @click="show_users(item)">{{item.today_logins}}</v-btn></td>
					<td><v-btn x-small @click="show_activities(item)">{{item.today_activities}}</v-btn></td>
					<td>{{item.today_results}}</td>
				</tr></template>
			</v-data-table>

		</v-flex>
	</v-layout>
</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
export default {
	data() { return {
		stats: [],
		footer_options: {
			itemsPerPageOptions: [10,20,50,100,250,-1],
		},
		table_options: {
			itemsPerPage: -1,
			page: 1,
		},
		headers: [
			{ text: 'Date', align: 'left', sortable: true, value:'timestamp' },
			{ text: 'Tot. Users', align: 'center', sortable: true, value:'total_users' },
			{ text: 'Tot. Logins', align: 'center', sortable: true, value:'total_logins' },
			{ text: 'Tot. Activities', align: 'center', sortable: true, value:'total_activities' },
			{ text: 'Tot. Results', align: 'center', sortable: true, value:'total_results' },
			{ text: 'Day Logins', align: 'center', sortable: true, value:'today_logins' },
			{ text: 'Day Activities', align: 'center', sortable: true, value:'today_activities' },
			{ text: 'Day Results', align: 'center', sortable: true, value:'today_results' },
		],
	}},
	computed: {
		...mapState(['user_info']),
	},
	created() {

	},
	mounted() {
		this.get_stats()
	},
	methods: {
		get_stats() {
			U.loading_start()
			U.ajax('get_sparkl_stats', {user_id: this.user_info.user_id}, result=>{
				U.loading_stop()
				if (result.status != 'ok') {
					console.log('Error getting system stats')
					return
				}

				console.log(result)
				this.stats = []
				let clines = result.counts.split('\n')
				let ulines = result.users.split('\n')
				let alines = result.activities.split('\n')
				// 1580610570:{"u":235,"l":1643,"a":29,"r":325,"l24":5,"a24":2,"r24":3}
				for (let i = 0; i < clines.length; ++i) {
					if (clines[i].search(/^(\d+):(.*)/) > -1) {
						let ts = RegExp.$1
						let counts = JSON.parse(RegExp.$2)
						this.stats.push({
							timestamp: ts,
							date_display: date.format(new Date(ts*1000), 'MM-DD-YYYY'),
							total_users: counts.u,
							total_logins: counts.l,
							total_activities: counts.a,
							total_results: counts.r,
							today_logins: counts.l24,
							today_activities: counts.a24,
							today_results: counts.r24,
							user_json: ulines[i].split(':')[1],
							users: '',
							activity_json: alines[i].split(':')[1],
							activities: '',
						})
					}
				}
			});

		},

		show_users(o) {
			if (o.users == '') {
				U.loading_start()
				U.ajax('get_sparkl_stats', {user_id: this.user_info.user_id, user_json:o.user_json}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error getting system stats')
						return
					}
					for (let user of result.users) {
						user.email_domain = user.email.replace(/.*@(.*)/, '$1')
					}
					o.users = result.users.sort((a,b)=>{
						if (a.role != b.role) return b.role - a.role
						if (a.email_domain > b.email_domain) return 1
						if (b.email_domain > a.email_domain) return -1
						if (a.displayname > b.displayname) return 1
						if (b.displayname > a.displayname) return -1
						return 0
					})
					this.show_users_finish(o)
				});
			} else {
				this.show_users_finish(o)
			}
		},

		show_users_finish(o) {
			let s = '<table class="k-stats-detail-table">'
			s += '<tr><th>Role</th><th>Email</th><th>Name</th></tr>'
			for (let u of o.users) {
				s += sr('<tr><td>$1 ($2)</td><td>$3</td><td>$4</td></tr>', u.role, u.user_id, u.email, u.displayname)
			}
			s += '</table>'
			this.$alert({
				title: 'Users with activity on ' + o.date_display,
				text: s,
				dialogMaxWidth: 700,
			})
		},

		show_activities(o) {
			if (o.users == '') {
				U.loading_start()
				U.ajax('get_sparkl_stats', {user_id: this.user_info.user_id, activity_json:o.activity_json}, result=>{
					U.loading_stop()
					if (result.status != 'ok') {
						console.log('Error getting system stats')
						return
					}
					o.activities = result.activities.sort((a,b)=>{
						if (a.email > b.email) return 1
						if (b.email > a.email) return -1
						if (a.title > b.title) return 1
						if (b.title > a.title) return -1
						return 0
					})
					this.show_activities_finish(o)
				});
			} else {
				this.show_activities_finish(o)
			}
		},

		show_activities_finish(o) {
			let s = '<table class="k-stats-detail-table">'
			s += '<tr><th>ID</th><th>Activity Title</th><th>Ex. Count</th><th>Teacher</th></tr>'
			for (let l of o.activities) {
				s += sr('<tr><td>$1</td><td><a href="/$1" target="_blank">$2</a></td><td>$3</td><td>$4 ($5 - $6)</td></tr>', l.activity_id, l.title, l.exercise_count, l.displayname, l.email, l.user_id)
			}
			// link to open activity
			s += '</table>'
			this.$alert({
				title: 'Activities with logins on ' + o.date_display,
				text: s,
				dialogMaxWidth: 900,
			})
		},
	}
}
</script>

<style lang="scss">
.k-sparkl-stats-table {
	th {
		white-space:nowrap;
	}
	td {
		text-align:center;
	}
}

.k-stats-detail-table {
	border-collapse:collapse;
	margin:0 auto;

	td, th {
		padding:2px 8px;
		font-size:14px;
		border:1px solid #ccc;
	}
}
</style>
