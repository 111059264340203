<template><div>
	<v-dialog v-model="dialog_open" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="700" overlay-opacity="0.9" :dark="dark_mode" persistent>
		<v-card class="k-dark-dialog-card k-activity-settings-form" :style="$vuetify.breakpoint.xsOnly?'border:0!important':''">
			<v-card-title style="border-bottom:1px solid #666"><b>{{app_noun}} Privacy Policy</b></v-card-title>
			<v-card-text class="pt-2">

<h3>Introduction</h3>
<p>{{app_noun}} is an online digital assignment tool designed for use by teachers and students in K-12 classrooms. {{app_noun}} is created and maintained by Common Good Learning Tools LLC. In order to effectively function, we must collect certain information from our users. This Privacy Policy describes what Personal Information we collect, how it collected, how it is protected, and how it can be controlled. </p>
<p>As required by law, children under the age of 13 in the U.S may only use our Services with the express prior consent of a parent or legal guardian. If you are a Teacher or Administrator, you must obtain all necessary parental consent before allowing students to use the Services. </p>

<h3>Contact Us</h3>
<p>For any questions or comments about this Privacy Policy, please contact us via email at pepper@commongoodlt.com. </p>

<h3>FERPA Compliance</h3>
<p>{{app_noun}} complies with all applicable provisions of the United States Family Educational Rights and Privacy Act, 20 U.S.C. 1232g, 34 CFR Part 99 (FERPA) in receiving and handling personally identifiable information from education records as a "school official" under FERPA. We only process Student Data for educational purposes.</p>

<h3>COPPA Compliance</h3>
<p>{{app_noun}} is designed for Primary and Secondary Education, however, {{app_noun}} is not designed or intended to collect Personal Information from young children under the age of 13 for any commercial purposes. In the event the Children’s Online Privacy Protection Act (COPPA), 15 U.S.C. 6501 et seq. is deemed to apply to the collection of any Personal Information, {{app_noun}} will comply with all applicable provisions of COPPA and collect and use such information for educational purposes only at the direction of the Educator and on the basis of Educator consent.</p>

<h3>Information Collection</h3>
<p>{{app_noun}} may collect the following Personal Information from users who access our services: </p>
<ul>
	<li>First and last name, </li>
	<li>Email address.</li>
</ul>
<p class="mt-2">Any information combined or associated with Personal Information is also treated as Personal Information. The following are the only pieces of information associated with names and email addresses of students: </p>
<ul>
	<li>Activity scores,</li>
	<li>Activity interaction duration,</li>
	<li>Activity question responses.</li>
</ul>
<p class="mt-2">The only pieces of information associated with names and email addresses of teachers are the names and email addresses of students who have interacted with activities the teacher has created.</p>
<p>Users will never be asked to enter Personal Information directly into {{app_noun}} services. We obtain users’ names and email addresses upon login with a Google account, or via the LTI protocol when a {{app_noun}} activity is launched from an LMS. No passwords are collected or stored by {{app_noun}}.</p>

<h3>Information Use and What Information is Visible to Others</h3>
<p>The information that {{app_noun}} collects on a student is used only to display activity results to their teachers. The information that {{app_noun}} collects about a teacher is used to show the teacher statistics about their own activities. If a teacher chooses to publish their activity to other {{app_noun}} teachers, anonymized statistics about their class’s aggregate performance may be shared with registered users as well. </p>
<p>Personal Information about a student (name, email, and activity interaction) is available only to teachers whose activities that student has interacted with (i.e. the students in their classes). No student may view the Personal Information of another student; no teacher may view the Personal Information of a student that has not accessed their activities. </p>
<p>Personal Information about teachers (name, email, student scores) is not available to any other user. No student may view the Personal Information of their teacher; no teacher may view the Personal Information of another teacher.</p>

<h3>Protecting Children’s Information</h3>
<p>Protecting the privacy of young children is especially important in the {{app_noun}} system. As such, {{app_noun}} is designed to help protect the Personal Information relating to children who are less than 13 years of age as required by applicable law. </p>
<p>{{app_noun}} does not knowingly permit users under 13 years of age to use our Services outside of agreements with schools or districts, as permitted by the Children’s Online Privacy Protection Rule (COPPA) and the Family Educational Rights and Privacy Act (FERPA). If we learn that the Personal Information of a user under 13 years of age has been collected in our services without the appropriate consent, we will delete this information. If you are a parent or guardian and discover that your child under the age of 13 has used our services without your consent, please contact your child’s school and alert {{app_noun}} and request that we delete that child’s personal information from our systems.</p>
<p>Users under the age of 13 cannot access {{app_noun}}’s services without first being approved by their teacher or school administrator. {{app_noun}} obligates schools and teachers to first obtain any necessary parental consent before permitting children to access {{app_noun}}’s services.</p>
<p>No Personal Information about students is available to the public. For more information on what information is visible to others, see the section entitled “Information Use & What Information is Visible to Others.”</p>

<h3>Third-Party Services</h3>
<p>No third parties are given access to any information about users. </p>

<h3>Information Protection</h3>
<p>Common Good Learning Tools takes data security for {{app_noun}} very seriously. Reasonable security measures are taken to protect the Personal Information submitted to us such as:</p>
<ul>
	<li>We continually test {{app_noun}}’s security practices for vulnerabilities.</li>
	<li>We periodically review our information collection, storage, and processing practices to guard against unauthorized access to systems.</li>
	<li>All traffic to and from our application is over secure, encrypted protocols (SSL/TLS). </li>
	<li>Developers peer-review code to make sure changes adhere to best practices for security.</li>
</ul>

<h3 class="mt-4">Opting Out of Providing Information</h3>
<p>Any user may decline to share their personal information with us by declining to log in via a Google account or their LMS. However, it is important to remember that if a user declines to share their name and email, that user will only have access to a selection of {{app_noun}} demo assignments; they will not be able to view or complete activities created by teachers.</p>
<p>Any teacher may decline to share the {{app_noun}} activities that they create at any time. Once a teacher has opted out, no new information will be published about their activity.</p>


			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #666;">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_done')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: { },
	props: {
	},
	data() { return {
		dialog_open: true,
	}},
	computed: {
		...mapGetters(['dark_mode']),
		app_noun() { return vapp.$store.getters.app_noun },
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
</style>
