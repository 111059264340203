<template><v-dialog v-model="dialog_open" max-width="640" persistent overlay-opacity="0.9" scrollable><v-card class="k-dark-dialog-card k-full-width-dialog-card">
	<v-card-title class="k-dark-dialog-card-title"><b>{{(edited_collection.collection_id==-1)?'Create':'Edit'}} {{edited_collection_type_display}}</b></v-card-title>
	<v-card-text class="mt-3" style="font-size:16px">
		<div class="k-text-block d-flex align-center mt-2">
			<v-text-field ref="collection_title_text_field" :label="edited_collection_type_display+' Title'" :disabled="edited_collection_is_default_group" hide-details outlined v-model="edited_collection.title"></v-text-field>
			<v-btn v-if="edited_collection.title && edited_collection.title!=original_collection.title" class="ml-3" color="primary" @click="save_collection_title"><v-icon small class="mr-2">fas fa-check</v-icon>Save</v-btn>
		</div>
		<div v-if="edited_collection.type=='student'&&!edited_collection.title" class="k-text-block mt-2 mb-4 mx-3" style="font-size:.85em;"><span v-if="edited_collection.type=='student'">You should use a simple description, e.g. “Period 3 World History”. Students will see your name next to this description, so there’s no need to include your name in the description itself.</span></div>

		<!-- access code settings -->
		<div class="k-text-block mt-4 mb-4" v-if="edited_collection.title">
			<div v-if="edited_collection.type=='allaccess-s'"><v-icon small>fas fa-exclamation-circle</v-icon> This is an "All Access" collection. Students can access activities tagged to this collection without entering an access code.</div>

			<!-- hiding access code stuff for now... -->
			<div v-if="edited_collection.type=='student'">
				<div class="text-right" v-if="!edited_collection.is_lti_context_group()">
					<div v-if="edited_collection.access_code">Access Code: <span class="k-access-code">{{edited_collection.access_code}}</span> <CopyBtn color="#999" small :val="edited_collection.access_code"/></div>
				</div>
			</div>

			<div v-if="edited_collection.type=='teacher'">
				<div class="d-flex align-center">
					<v-checkbox class="mt-0 pt-0" :label="'Require an access code to subscribe to this Activity Collection'" v-model="edited_collection.restricted" hide-details @change="restricted_updated"></v-checkbox>
					<div class="ml-2" v-if="edited_collection.access_code"><span class="k-access-code">{{edited_collection.access_code}}</span> <CopyBtn color="#999" small :val="edited_collection.access_code"/></div>
				</div>
				<div class="mx-3 mt-2" style="font-size:.85em;">If you require an access code for this Activity Collection, other teachers will be required to enter this code to find and access the activities in the Collection.<!-- Otherwise, teachers will be able to find the collection from the Sparkl Group Activity Tracker, and subscribe to the Collection without entering an access code (but teachers can always use the code to locate the collection from the Sparkl Group Activity Tracker).--></div>
				<div v-if="edited_collection.collection_id>0" class="mt-4 text-center">Collection URL: <a :href="edited_collection_url" target="_blank" class="mx-2">{{edited_collection_url}}</a> <CopyBtn color="#999" small :val="edited_collection_url"/> <v-icon small color="blue lighten-2" @click="show_collection_url">fas fa-info-circle</v-icon></div>
			</div>
		</div>

		<!-- Collection users -->
		<div v-if="edited_collection.collection_id!=-1" class="pt-2 mt-4" style="border-top:1px solid #999">
			<div class="k-text-block px-4 pt-1 d-flex">
				<div style="font-size:18px"><b>Users:</b></div>
				<v-spacer />
				<v-text-field v-model="student_search" prepend-inner-icon="fa fa-search" clearable clear-icon="fa fa-times-circle" :label="edited_collection.type=='student'?'Student Search':'User Search'" outlined single-line hide-details dense></v-text-field>
				<v-spacer />
				<!-- hiding this at least for now (9/25/2024) -->
				<v-btn v-if="false" small color="primary" @click="add_collection_user" class="mt-1"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add User</v-btn>
			</div>
			<div v-if="edited_collection.users.length==0" class="text-center"><i>This {{edited_collection_type_display}} does not include any users</i></div>
			<div v-else><v-simple-table class="k-manage-collections-table-class" dense><template v-slot:default>
				<thead><tr>
					<th class="text-left">Name</th>
					<th class="text-center"></th>
				</tr></thead>
				<tbody><tr v-for="item in edited_collection_users" v-if="item.sparkl_role==0" :key="item.user_id">
					<td style="font-size:14px">{{ item.name_given }} {{ item.name_family }} <span style="font-size:0.85em">({{U.email_display(item.email)}})</span></td>
					<td style="font-size:14px" class="text-center">
						<!-- <b>{{user_role_table_display(item)}}</b> -->
						<v-icon v-if="item.user_id!=user_info.user_id" class="ml-2" small @click="remove_collection_user(item)">fas fa-times-circle</v-icon>
						<v-icon v-if="item.user_id!=user_info.user_id&&edited_collection.type=='teacher'" class="ml-2" small @click="update_user_role(item)">fas fa-edit</v-icon>
					</td>
				</tr></tbody>
			</template></v-simple-table></div>
		</div>

		<!-- Collection activities -->
		<div v-if="show_activities&&edited_collection.collection_id!=-1&&!edited_collection.is_default_group()" class="pt-2" style="border-top:1px solid #999">
			<div class="k-text-block px-4 pt-1 pb-3 d-flex">
				<div style="font-size:18px"><b>Activities:</b></div>
				<v-spacer />
				<v-btn small color="primary" @click="add_collection_activity"><v-icon class="mr-1" small>fas fa-plus</v-icon> Add Activity</v-btn>
			</div>
			<div v-if="edited_collection.activities.length==0" class="text-center mb-4"><i>This {{edited_collection_type_display}} does not include any activities</i></div>
			<div v-else>
				<draggable v-bind="drag_options" v-model="edited_collection.activities" @start="drag_activity_start" @end="drag_activity_complete">
					<div v-for="item in edited_collection.activities" :key="item.activity_id" class="k-manage-collections-activity-row">
						<v-icon class="k-manage-collections-order-activity mr-3" small color="#ccc">fas fa-arrows-alt-v</v-icon>
						<span v-html="item.title"></span>
						<v-spacer/>
						<v-icon v-if="item.user_id!=user_info.user_id" class="ml-2" small @click="remove_collection_activity(item)">fas fa-times-circle</v-icon>
					</div>
				</draggable>
			</div>
		</div>
	</v-card-text>
	<v-card-actions class="k-dark-dialog-card-btns">
		<v-btn small v-if="edited_collection.collection_id!=-1&&(!edited_collection_is_default_group||edited_collection.is_archived_default_group())" class="k-tight-btn mr-1" dark color="red darken-2" @click="delete_collection"><v-icon small class="mr-2">fas fa-trash-alt</v-icon>Delete {{edited_collection_type_display}}</v-btn>
		<v-btn small v-if="edited_collection.collection_id!=-1" class="k-tight-btn" dark color="orange darken-4" @click="archive_collection"><v-icon small class="mr-2">fas fa-box-archive</v-icon>Archive {{edited_collection_type_display}}</v-btn>
		<v-btn color="light-blue" icon @click="archive_help"><v-icon>fas fa-circle-question</v-icon></v-btn>
		<v-spacer></v-spacer>
		<v-btn small color="primary" @click="$emit('dialog_cancel')"><v-icon small class="mr-2">fas fa-check</v-icon>Done</v-btn>
	</v-card-actions>
</v-card></v-dialog></template>

<script>
import draggable from 'vuedraggable'
import CopyBtn from '@/../../app-common/components/CopyBtn'
import { mapState, mapGetters } from 'vuex'

export default {
	components: { draggable, CopyBtn },
	props: {
		edited_collection_id: { type: Number, required: true },
		student_search_master: { required: false, default() { return false } },
		// collection_type is required only for new collections
		collection_type: { type: String, required: false, default() { return ''} },
		// currently we don't load the full list of activities for each collection in the teacherapp, so we don't want to show the list there
		// we could in theory load this list on the fly when the editor was opened
		show_activities: { type: Boolean, required: false, default() { return true} },
	},
	data() { return {
		dialog_open: true,
		edited_collection: {},
		drag_options: {
		    animation: 300,
		    handle: ".k-manage-collections-order-activity",
		},
		student_search_local: '',
	}},
	computed: {
		...mapState(['user_info', 'groups', 'teacher_collections', 'ad']),
		...mapGetters([]),
		student_search: {
			get() {
				// if student_search_master === false, use student_search_local; otherwise use student_search_master
				if (this.student_search_master === false) return this.student_search_local
				else return this.student_search_master
			},
			set(val) {
				if (this.student_search_master === false) this.student_search_local = val
				else this.$emit('set_student_search', val)
			}
		},
		original_collection() {
			// if edited_collection_id is 0, there is no original collection
			if (this.edited_collection_id == 0) return {}

			// else we will always find it in either groups or teacher_collections
			let c = this.groups.find(x=>x.collection_id == this.edited_collection_id)
			if (!c) c = this.teacher_collections.find(x=>x.collection_id == this.edited_collection_id)

			return c
		},
		edited_collection_type_display() {
			if (this.edited_collection.type == 'student') return 'Student Group'
			return 'Activity Collection'
		},
		edited_collection_users() {
			if (empty(this.edited_collection.users)) return []
			if (!this.student_search) return this.edited_collection.users

			let ss = this.student_search.toLowerCase()
			let arr = []
			for (let user of this.edited_collection.users) {
				let found = false
				if (user.name_family.toLowerCase().indexOf(ss) > -1) found = true
				if (user.name_given.toLowerCase().indexOf(ss) > -1) found = true
				if (user.email.toLowerCase().indexOf(ss) > -1) found = true
				if (found) arr.push(user)
			}
			return arr
		},
		edited_collection_url() { return window.location.origin + '/c/' + this.edited_collection.collection_id },
		edited_collection_is_default_group() { return this.edited_collection.is_default_group() },
	},
	watch: {
	},
	created() {
		// create a new collection to edit...
		if (this.edited_collection_id == 0) {
			this.edited_collection = new Collection({type: this.collection_type})
			// focus in the title field after we open
			setTimeout(x=>{
				$(this.$refs.collection_title_text_field.$el).find('input').focus()
				$(this.$refs.collection_title_text_field.$el).find('input').select()
			}, 10)

		} else {
			// or create a copy of the existing collection to edit
			this.edited_collection = new Collection(this.original_collection)
		}
	},
	mounted() {
	},
	methods: {
		show_collection_url(item) {
			let msg
			if (this.edited_collection.type == 'student') {
				msg = sr('Students in this Group can access activities from the following URL. Also remember to give them the Access Code for the Group: <b>$1</b>', this.edited_collection.access_code)
			} else {
				msg = 'Other teachers can access activities from the following URL.'
				if (this.edited_collection.restricted) {
					msg += sr(' Teachers will also need the Access Code for the collection: <b>$1</b>', this.edited_collection.access_code)
				}
			}
			this.$prompt({
				title: sr('Link for “$1”', this.edited_collection.title),
				text: msg,
				initialValue: this.edited_collection_url,
				acceptText: 'OK',
				hideCancel: true,	// set to true to hide the cancel button
			})
		},

		user_role_table_display(user) {
			if (user.user_id == this.user_info.user_id) return 'YOU'
			if (this.edited_collection.type == 'student') {
				if (user.collection_role == 'editor') return 'Group Editor'
				else return 'Student'
			} else {	// 'teacher'
				if (user.collection_role == 'editor') return 'Collection Editor'
				else return 'Subscriber'
			}
		},

		save_collection_title() {
			let payload = {
				collection_id: this.edited_collection.collection_id,
				type: this.edited_collection.type,
				title: this.edited_collection.title,
				// send the value for restricted in case this is a new collection
				restricted: this.edited_collection.restricted ? 'yes' : 'no',
			}
			this.$store.dispatch('save_collection', payload).then((c)=>{
				// if this was a new collection, close the editor
				if (this.edited_collection.collection_id == -1) {
					this.$emit('dialog_cancel')
				} else {
					this.edited_collection = new Collection(c)
				}
			})
		},

		add_collection_user() {
			let msg
			if (this.edited_collection.type == 'student') msg = 'Enter the email address of the user you’d like to add to this Group. <div class="mt-2" style="font-size:14px; line-height:18px">Students will be added as students to the Group; teachers will be added as additional editors of the Group. (Group editors can manage who has access to the Group and can see activity results for students in the Group.)</div>'
			else msg = 'Enter the email address of a teacher you’d like to add as a subscriber to this Activity Collection.'
			this.$prompt({
				title: 'Add ' + this.edited_collection_type_display + ' User',
				text: msg,
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: 'Add User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')
				if (empty(email) || email.indexOf('@') == -1) {
					this.$alert('Please enter a valid email.').then(x=>{this.add_collection_user()})
					return
				}

				// if we get to here, try to add the email as a subscriber
				let payload = {
					collection_id: this.edited_collection.collection_id,
					add_users: [email],
				}
				this.$store.dispatch('save_collection', payload).then((c)=>{
					if (this.edited_collection.collection_id == -1) {
						this.alert('New ' + this.edited_collection_type_display + ' Created')
					}
					this.edited_collection = new Collection(c)
				}).catch(result=>{
					if (result.status == 'emails_dont_exist') {
						let msg
						if (result.bad_emails.length == 1) msg = sr('The email address “$1” does not correspond to a current user in the Sparkl system.', result.bad_emails[0])
						else msg = sr('The following email addresses do not correspond to current users in the Sparkl system: $1', result.bad_emails.join(', '))
						this.$alert(msg)
					} else if (result.status == 'user_not_teacher') {
						// should always get one email here
						let msg = sr('The user account for email address “$1” is not a teacher account, so they cannot be added as a subscriber to a Teacher Collection.', result.bad_emails[0])
						this.$alert(msg)
					}
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		remove_collection_user(user) {
			if (this.remove_collection_user_confirmed) {
				this.remove_collection_user_finish(user)
				return
			}

			this.$confirm({
				title: 'Remove ' + this.edited_collection_type_display + ' User',
				text: sr('Are you sure you want to remove user $1 $2 ($3) from $4 “$5”?', user.name_given, user.name_family, user.email, this.edited_collection_type_display, this.edited_collection.title),
				acceptText: 'Remove User',
				acceptColor: 'red darken-2'
			}).then(x => {
				this.remove_collection_user_confirmed = true
				this.remove_collection_user_finish(user)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		remove_collection_user_finish(user) {
			let payload = {
				collection_id: this.edited_collection.collection_id,
				remove_users: [user.user_id],
			}
			this.$store.dispatch('save_collection', payload).then((c)=>{
				this.edited_collection = new Collection(c)
			})
		},

		update_user_role(user) {
			// toggle teacher_collection user role between subscriber and editor
			let msg, new_role
			if (user.collection_role == 'subscriber') {
				new_role = 'editor'
				msg = sr('Are you sure you want to change user $1 $2 ($3) from a subscriber to an <b>editor</b> of Activity Collection “$4”? <div class="mt-2">This will allow the user to edit which activities are included in the Collection and which other teachers are subscribed to the Collection.</div>', user.name_given, user.name_family, user.email, this.edited_collection.title)
			} else {
				new_role = 'subscriber'
				msg = sr('Are you sure you want to change user $1 $2 ($3) from an editor to a <b>subscriber</b> of Activity Collection “$4”?', user.name_given, user.name_family, user.email, this.edited_collection.title)
			}
			this.$confirm({
				title: 'Update User Role',
				text: msg,
				acceptText: 'Update User Role',
			}).then(x => {
				let payload = {
					collection_id: this.edited_collection.collection_id,
					update_user_role: {user_id: user.user_id, role: new_role},
				}
				this.$store.dispatch('save_collection', payload).then((c)=>{
					this.edited_collection = new Collection(c)
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		add_collection_activity() {

		},

		remove_collection_activity() {

		},

		drag_activity_start(evt) {
			// nothing to do for now
		},

		drag_activity_complete(evt) {
			// save updated activity order
			let collection_sequences = []
			for (let i = 0; i < this.edited_collection.activities.length; ++i) {
				collection_sequences.push({
					activity_id: this.edited_collection.activities[i].activity_id,
					collection_sequence: i,
				})
			}

			let payload = {
				collection_id: this.edited_collection.collection_id,
				collection_sequences: collection_sequences,
			}
			this.$store.dispatch('save_collection', payload).then((c)=>{
				this.edited_collection = new Collection(c)
			})
		},

		restricted_updated() {
			// for new collections, we save this value when we enter a title and click save
			if (this.edited_collection.collection_id == -1) return

			let payload = {
				collection_id: this.edited_collection.collection_id,
				restricted: this.edited_collection.restricted ? 'yes' : 'no'
			}

			this.$store.dispatch('save_collection', payload).then((c)=>{
				this.edited_collection = new Collection(c)
			})
		},

		archive_help() {
			let msg = this.archive_help_msg()
			if (!this.edited_collection_is_default_group) {
				if (this.edited_collection.type == 'student') msg = '<div class="mb-2"><b>Deleting</b> the Group will not delete any activities, nor will it delete any student results. But you may no longer be able to view results for students in the Group.</div><b>Archiving</b> the Group will have the same effect, but you can always restore an archived Group later.'
				else msg = '<div class="mb-2">Note that deleting the Activity Collection will not delete any activities. However, any teachers who were previously accessing the activities in the Collection may no longer have access to the activities if you delete the Collection.</div><b>Archiving</b> the Collection will have the same effect, but you can always restore an archived Collection later.'
			} else {
				msg = 'If you <b>archive</b> your current Default Group, you may no longer see results for students who were automatically added to that Group, and a new Default Group will be created to accommodate students who launch activities for you in the future. Note that existing student results will not be deleted, and you can always restore the archived Default Group (including access to results from students in the Group) later if needed.'
			}
			this.$alert(msg)
		},

		archive_help_msg() {
			let msg = ''
			return msg
		},

		archive_collection() {
			let msg = sr('Are you sure you want to archive $1 “$2”?', this.edited_collection_type_display, this.edited_collection.title)
			msg += '<div class="mt-2">'
			if (this.edited_collection_is_default_group && !this.edited_collection.is_archived_default_group()) msg += 'If you <b>archive</b> your current Default Group, you may no longer see results for students who were automatically added to that Group, and a new Default Group will be created to accommodate students who launch activities for you in the future. Note that existing student results will not be deleted, and you can always restore the archived Default Group (including access to results from students in the Group) later if needed.'
			else if (this.edited_collection.type == 'student') msg += '<b>Archiving</b> the Group will not delete any activities, nor will it delete any student results, but you will no longer be able to view results grouped by students in the Group. Note that you can always restore the Group (including access to results from students in the Group) later if needed.'
			else msg += '<b>Archiving</b> the Activity Collection will not delete any activities. However, any teachers who were previously accessing the activities in the Collection may no longer have access to the activities if you archive the Collection. Note that you can always restore the Collection later if needed.'
			msg += '</div>'

			this.$confirm({
				title: 'Archive ' + this.edited_collection_type_display,
				text: msg,
				acceptText: 'Archive',
				acceptColor: 'orange darken-4',
				dialogMaxWidth: 600,
			}).then(x => {
				let payload = {
					collection_id: this.edited_collection.collection_id,
					date_string: date.format(new Date(), 'YYYY-MM-DD HH:MM'),	// this is used if the archived collection is a default class
					archived: 'yes',	// this gets translated to 1 on the server
				}

				this.$store.dispatch('save_collection', payload).then((c)=>{
					// reload the window to load the unarchived collection
					this.$alert('Your browser window will now reload, removing access to the archived ' + this.edited_collection_type_display).then(x=>{
						U.reload_window()
					})
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		delete_collection() {
			let msg = sr('Are you sure you want to delete $1 “$2”?', this.edited_collection_type_display, this.edited_collection.title)
			if (this.edited_collection.type == 'student') msg += '<div class="mt-2">Note that deleting the Group will <i>not</i> delete any activities, nor will it delete any student results.</div>'
			else msg += '<div class="mt-2">Note that deleting the Activity Collection will <i>not</i> delete any activities. However, any teachers who were previously accessing the activities in the Collection may no longer have access to the activities if you delete the Collection.</div>'

			this.$confirm({
				title: 'Delete ' + this.edited_collection_type_display,
				text: msg,
				acceptText: 'Delete',
				acceptColor: 'red darken-2'
			}).then(x => {
				let payload = {
					collection_id: this.edited_collection.collection_id,
					type: this.edited_collection.type,
					delete_collection: 'yes',
				}
				this.$store.dispatch('save_collection', payload).then((c)=>{
					// once the collection is deleted, close this dialog
					this.$emit('dialog_cancel')
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		}
	}
}
</script>

<style lang="scss">
.k-manage-collections-activity-row {
	display:flex;
	align-items: center;
	padding:4px 16px;
	font-size:14px;
	width:100%;
	// background-color:#2f2f2f;
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.k-manage-collections-activity-row:hover {
	// background-color:#616161;
}

</style>
