// default values for site_config
// starred properties are also used in server-side code, so they must also be set there
window.default_site_config = {
	'flavor': 'sparkl',

	// * what to call the Sparkl application
	'app_noun': 'Sparkl',

	'bot_noun': 'Sparkl-BOT',

	// * where to direct people for help
	'support_email': 'support@commongoodlt.com',

	'primary_color': '#5C6BC0',
	'activity_progress_color': 'indigo darken-1',
	'banner_color': '',

	// for the Logo.vue component
	'logo_html': '<span class="k-sparkl-logo-letter-holder"><span class="k-logo-letter k-logo__color1">S</span><span class="k-logo-letter k-logo__color2">P</span><span class="k-logo-letter k-logo__color6">A</span><span class="k-logo-letter k-logo__color3">R</span><span class="k-logo-letter k-logo__color4">K</span><span class="k-logo-letter k-logo__color5">L</span></span>',

	// for TeacherStart, FrontMatter, and other places where we show the app name in stylized text
	'app_name_html': '<span class="k-frontmatter-logo" style="font-size:22px"><span class="k-logo__color1">S</span><span class="k-logo__color2">P</span><span class="k-logo__color6">A</span><span class="k-logo__color3">R</span><span class="k-logo__color4">K</span><span class="k-logo__color5">L</span></span>',

	// if this is true, show the app logo in the top-left corner when the activity is in embedded mode
	'show_app_logo_in_embedded_activity': true,

	'teacher_start_about_text': ', a digital learning tool for <nobr>K-12 teachers and students</nobr>',
	'show_teacher_start': true,
	'show_sparkl_about': true,		// if false, hide the "ABOUT SPARKL" link from the teacher app

	'activity_collection_image': '',

	'dark_mode_available': true,

	// if this is true, the sparkl partner wants to make it possible for interactive reading to be disableable (e.g. AP)
	// NOTE: as of 7/14/2024, we do allow teachers to disable interactive reading if they wish regardless of this setting; but if interactive_reading_disableable is true, we change some display things
	'interactive_reading_disableable': false,

	'site_default_activity_display_settings': {
		'sound_effects': 'on',
		'colors': 'more',
		'background_color': 'grey',
		'sparkl_bot': 'on',
		'font_size': 'smaller',
		'star_noun': 'star',
		'text_color': 'black',
		'text_font': 'serif',
		'high_scores': 'on',
		'fireworks': 'on',
		'score_tracker_value': 'default',
	},

	// activity id (e.g. 1239) of exercise bank to open when an activity with no exercises launches -- this will be used with certain flavors (e.g. "velocity_lc")
	'exercise_bank': '',

	// title to show when we launch the learning check interface for new activities
	'lc_title': 'Learning Check',

	// settings listed here (e.g. 'sound_effects') won't be shown at all for this server/flavor (originally created for AP)
	'hidden_activity_display_settings': [],

	// if true we will only allow/use completion mode (originally created for AP)
	'completion_mode_only': false,

	// if true we will disable everything around sparkl-bot, including all teacher assistance (originally created for AP)
	'disable_all_sparkl_bot': false,

	// if true we will hide the student welcome message from the frontmatter (originally created for AP)
	'hide_student_welcome_message_and_screen_name': false,

	// urls for scripts to include in index.html (by inserting them into the DOM) for the teacher and student apps
	'scripts_for_index_html': [],
	// 'scripts_for_index_html': ['https://apsources.commongoodlt.com/test.js'],

	// show the microsoft signing button
	'allow_msft_login': 'no',

	// time between websocket pings in ms
	'ws_ping_interval': 5000,

	'attributions': {},
}

// common initializations we have to do across all apps
window.initialize_site_config = function() {
	let site_config = vapp.$store.state.site_config

	// this is for demoing things with the Velocity logo; set SPARKL_TEST_VELOCITY in sparkl-version.js
	if (window.SPARKL_TEST_VELOCITY) {
		site_config.flavor = 'velocity'
		site_config.app_noun = 'Velocity'
		site_config.bot_noun = 'Velocity-BOT'
		site_config.primary_color = '#5C6BC0'
		site_config.activity_progress_color = 'indigo darken-1'
		// for the Logo.vue component
		site_config.logo_html = '<span class="k-velocity-logo"><span><i><span class="k-logo__color1" style="font-size:1.2em;">V</span><span class="k-logo__color2">E</span><span class="k-logo__color6">L</span><span class="k-logo__color3">O</span><span class="k-logo__color4">C</span><span class="k-logo__color5">I</span><span class="k-logo__color7">T</span><span class="k-logo__color8">Y</span></i></span></span>'
		site_config.app_name_html = '<span class="k-frontmatter-logo k-velocity-logo" style="font-size:20px;line-height:0px;background-color:transparent"><span><i><span class="k-logo__color1" style="font-size:24px;">V</span><span class="k-logo__color2">E</span><span class="k-logo__color6">L</span><span class="k-logo__color3">O</span><span class="k-logo__color4">C</span><span class="k-logo__color5">I</span><span class="k-logo__color7">T</span><span class="k-logo__color8">Y</span></i></span></span>'
	}

	$('body').addClass(`k-flavor-${site_config.flavor}`)
	vapp.$vuetify.theme.themes.dark.primary = vapp.$vuetify.theme.themes.light.primary = vapp.$vuetify.theme.defaults.dark.primary = vapp.$vuetify.theme.defaults.light.primary = site_config.primary_color

	// see Satchel
	// let style_rules = [
	// 	sr(' .v-application .primary { background-color:$1!important; border-color:$1!important; }', state.site_config.primary_color),
	// ]
	// $('head').append('<style type="text/css">' + style_rules.join('\n') + '</style>')

	// if we got any stylesheets, append them to head
	if (!empty(vapp.$store.state.stylesheets)) for (let stylesheet of vapp.$store.state.stylesheets) {
		$('head').append(`<style type="text/css">${stylesheet}</style>`)
	}

	// and if we got any scripts, append them to head
	if (!empty(site_config.scripts_for_index_html)) for (let scpt of site_config.scripts_for_index_html) {
		$('head').append('<script src="' + scpt + '" type="text/javascript"></script>')
	}
}

/*

// from store...
{
	// set flavor
	if (window.SPARKL_TEST_VELOCITY || window.location.hostname == 'velocity.gadoe.org') {
		state.flavor = 'velocity'
	} else {
		// this could be null, which is fine
		state.flavor = state.ad.amd.flavor
	}
	U.set_flavor(state.flavor)
}

U.set_flavor = function(flavor) {
	if (!flavor) return

	if (flavor == 'ap') {
		$('body').addClass('k-flavor-ap')
		vapp.$vuetify.theme.themes.dark.primary = vapp.$vuetify.theme.themes.light.primary = vapp.$vuetify.theme.defaults.dark.primary = vapp.$vuetify.theme.defaults.light.primary = '#006298'
		vapp.$store.state.activity_collection_image = 'ap-sankofa.png'

		// always use points instead of stars??
		if (vapp.$store.state.ad) vapp.$store.commit('set', [vapp.$store.state.ad.default_activity_display_settings, 'star_noun', 'point'])
	}
}

*/