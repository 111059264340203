<template><div style="z-index:11000"><v-dialog v-if="shown" :dark="dark_mode" :content-class="'simple-dialog '+message.dialogClass" :fullscreen="fullscreen" eager v-model="shown" :max-width="message.dialogMaxWidth || 500" scrollable persistent :overlay-opacity="opacity">
	<v-card class="k-dark-dialog-card">
		<v-card-title v-if="message.title">
			<h3 v-html="message.title"></h3>
		</v-card-title>
		<v-card-text>
			<div v-html="message.text"></div>
			<v-text-field ref="passwordInput" outlined hide-details class="simple-dialog-text-field" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" v-if="type === 'prompt' && message.promptType === 'password'" v-model="text_input" @keyup="checkSubmit" autocomplete="new-password"  :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password"></v-text-field>
			<v-text-field ref="textInput" outlined hide-details class="simple-dialog-text-field" v-if="type === 'prompt' && message.promptType === 'text'" :type="message.type || 'text'" v-model="text_input" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" @keyup="checkSubmit"></v-text-field>
			<v-textarea ref="textareaInput" outlined hide-details auto-grow class="simple-dialog-textarea" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" v-if="type === 'prompt' && message.promptType === 'textarea'" v-model="text_input"></v-textarea>
			<v-file-input ref="fileInput" outlined hide-details placeholder="Select a file…" class="simple-dialog-file-input" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" v-if="type === 'prompt' && message.promptType === 'file'" v-model="file_input"></v-file-input>
			<v-autocomplete ref="autocompleteInput" outlined hide-details auto-grow class="simple-dialog-autocomplete" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" v-if="type === 'prompt' && message.promptType === 'autocomplete'" v-model="text_input" :items="autocomplete_items" :loading="autocomplete_loading" :search-input.sync="autocomplete_search" hide-no-data hide-selected></v-autocomplete>
			<v-select ref="selectInput" outlined hide-details class="simple-dialog-select-input" :background-color="dark_mode?'#2f2f2f':'#f8f8f8'" v-if="type === 'prompt' && message.promptType === 'select'" v-model="text_input" :items="message.selectOptions" label=""></v-select>

			<div v-if="message.secondaryCheckbox" class="mt-3">
				<v-checkbox class="mt-0 pt-0" v-model="checkbox_value" hide-details><template v-slot:label><div :style="dark_mode?'color:#fff':'color:#000'" v-html="message.secondaryCheckbox"></div></template></v-checkbox>
			</div>
		</v-card-text>
		<v-card-actions>
			<v-btn v-on:click="cancel" class="simple-dialog-dismiss-btn" dark :color="message.cancelColor" v-if="type !== 'alert'" :style="message.hideCancel==true?'visibility:hidden;height:0px;':''">
				<v-icon v-if="message.cancelIcon" small class="mr-2">{{message.cancelIcon}}</v-icon>
				<span v-html="message.cancelText"></span>
				<v-icon v-if="message.cancelIconAfter" small class="ml-2">{{message.cancelIconAfter}}</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
			<v-btn v-if="message.extraBtnText" v-on:click="extra_btn_clicked" dark :color="message.extraBtnColor" ref="extraBtn" class="mr-2">
				<v-icon v-if="message.extraBtnIcon" small class="mr-2">{{message.extraBtnIcon}}</v-icon>
				<span v-html="message.extraBtnText"></span>
				<v-icon v-if="message.extraBtnIconAfter" small class="ml-2">{{message.extraBtnIconAfter}}</v-icon>
			</v-btn>
			<v-btn v-on:click="accept" :class="type=='alert'?'simple-dialog-dismiss-btn':''" dark :color="message.acceptColor" :style="message.hideAccept==true?'visibility:hidden;height:0px;':''" :disabled="accept_disabled" ref="acceptBtn">
				<v-icon v-if="message.acceptIcon" small class="mr-2">{{message.acceptIcon}}</v-icon>
				<span v-html="message.acceptText"></span>
				<v-icon v-if="message.acceptIconAfter" small class="ml-2">{{message.acceptIconAfter}}</v-icon>
			</v-btn>
		</v-card-actions>
	</v-card>
</v-dialog></div></template>

<script>
	export default {
		name : "SimpleDialog",
		props : {
			type : String,
			message : Object,
			resolve : Function
		},
		data() { return {
			shown: false,
			text_input: '',
			file_input: [],
			autocomplete_loading: false,
			autocomplete_search: '',
			autocomplete_items: [],
			checkbox_value: false,
			show_password: false,
		}},
		created() {
			if (this.message.initialValue != null) {
				this.text_input = this.message.initialValue;
			}
			if (this.message.secondaryCheckboxInitiallyOn) this.checkbox_value = true
		},
		computed: {
			fullscreen() { return this.message.fullscreen === true },
			dark_mode() { 
				if (vapp.$store.getters.dark_mode === undefined) return true
				return vapp.$store.getters.dark_mode 
			},
			accept_disabled() {
				if (this.message.disableForEmptyValue) {
					return (this.text_input == '' && this.file_input.length == 0)
				}
				return false
			},
			// added 1/14/2023
			opacity() { return this.message.opacity ?? '0.9' },
		},
		watch: {
			autocomplete_search(val) {
				/*
					To use autocomplete, either specify `autocompleteItems` in the $prompt call (they should be an array of strings),
					or specify a `serviceName` value, in which case:
						- this fn will call serviceName to retrieve the items based on the first 3 characters the user types
						- the service must return an array of `entries` in the result
						- caller can optionally specify `entryField` to use a field of the returned objects;
						  if entryField isn't specified, the service will receive `return_string:yes`, which should signal it to return an array of strings
				*/

				if (this.message.autocompleteItems) {
					// if requiredAutocompleteCharacters parameter is included, require the user to type at least that many characters before the list appears
					if (this.message.requiredAutocompleteCharacters && (!val || val.length < this.message.requiredAutocompleteCharacters)) this.autocomplete_items = []
					else this.autocomplete_items = this.message.autocompleteItems
					return
				}

				if (!this.message.serviceName) return

				// items have already been requested
				if (this.autocomplete_loading) return

				// don't search until we have three characters; then if the value drops back below three characters, clear autocomplete_items so we'll search again when we get back to 3
				if (!val || val.length < 3) {
					this.autocomplete_items = []
					return
				} else if (this.autocomplete_items.length > 0 && val.length > 3) {
					// if we don't have any items, the search service may have overloaded, so keep trying if the user keeps typing
					return
				}

				// if we get to here, search for items
				this.autocomplete_loading = true
				let payload = {
					user_id: vapp.user_info.user_id,
					// if an entryField is specified, we want the service to return an object; otherwise tell it to return a string
					return_string: (this.message.entryField) ? 'no' : 'yes',
					stem: val,
				}
				U.ajax(this.message.serviceName, payload, result=>{
					// parse results, which should be in result.entries
					let arr = []
					for (let entry of result.entries) {
						// if entryField is specified, entries should be objects; get the right field
						if (this.message.entryField) arr.push(entry[this.message.entryField])
						// else entries should be strings
						arr.push(entry)
					}
					this.autocomplete_items = arr
					this.autocomplete_loading = false
				})
			},
		},
		methods : {
			// Note: if caller asked for the checkbox, send an array with the checkbox_value the second item in the array, if the user clicked the accept or extra btn
			cancel() {
				this.shown = false;
				// if they click the cancel btn, we have to return false for the resolve fn to work properly (this could be updated if we really need to)
				this.resolve( false );
			},
			accept() {
				this.shown = false;
				if (this.type == 'prompt') {
					// note that although file_input is defined as an array, since we only currently allow for one file to be selected, only a single file object is returned.
					if (this.message.promptType == 'file') {
						let rv = (this.message.secondaryCheckbox) ? [this.file_input, this.checkbox_value] : this.file_input
						this.resolve(rv)
						return
					}

					// if type is 'autocomplete' and we didn't get a text_input, return the autocomplete_search value
					if (!this.text_input) this.text_input = this.autocomplete_search

					let rv = (this.message.secondaryCheckbox) ? [this.text_input, this.checkbox_value] : this.text_input
					this.resolve(rv)

				} else {
					let rv = (this.message.secondaryCheckbox) ? [true, this.checkbox_value] : true
					this.resolve(rv)
				}
			},
			extra_btn_clicked() {
				// extra btn option -- allows for a choice of two things with an additional cancel option -- only used with 'confirm'
				this.shown = false;
				let rv = (this.message.secondaryCheckbox) ? ['extra', this.checkbox_value] : 'extra'
				this.resolve(rv);
				// caller should look for `x === 'extra'` as the return value
			},
			checkSubmit(evt) {
				if (evt.key === "Enter"){
					this.accept();
				}

				// if allCaps is true, transform input to upper case
				if (this.message.allCaps) {
					this.text_input = this.text_input.toUpperCase()
				}
			}
		},
		mounted() {
			this.shown = true;
			if (this.type === "prompt"){
				setTimeout(function() {
					if (this.message.promptType == 'text') {
						$(this.$refs.textInput.$el).find('input').focus()
						$(this.$refs.textInput.$el).find('input').select()
					} else if (this.message.promptType == 'textarea') {
						$(this.$refs.textareaInput.$el).find('textarea').focus()
						$(this.$refs.textareaInput.$el).find('textarea').select()
					}
				}.bind(this), 300 );
			}

			if (this.message.focusBtn) {
				setTimeout(function() {
					$(this.$refs.acceptBtn.$el).focus()
				}.bind(this), 300 );
			}
		}
	}
</script>

<style>
/* .simple-dialog .v-card {
	background-color:#f8f8f8!important;
} */

.simple-dialog-textarea textarea {
	margin-top:0!important;
	font-size:16px;
	line-height:21px;
}

.simple-dialog-text-field {
	margin-top:8px!important;
}

.simple-dialog-file-input {
	margin-top:15px!important;
}

.simple-dialog-autocomplete {
	margin-top:8px!important;
}

.simple-dialog .v-card__text {
	font-size:16px;
	line-height:21px;
	/* color:#111!important; */
	padding:12px!important;
	/* border-color:#ddd;
	border-style:solid;
	border-width:8px 4px; */
}

.simple-dialog .v-card__actions {
	/* background-color:#ddd; */
	padding:8px 8px 8px 8px;
}

.simple-dialog .v-card__title {
	/* background-color:#ddd; */
	padding:8px 12px 0 12px!important;
	word-break: normal;
}
.simple-dialog .v-card__title h3 {
	font-size:24px;
	margin:0;
	padding:0;
	/* color:#111!important; */
}

.simple-dialog .v-input--checkbox .v-input__slot {
	align-items:flex-start;
}

.simple-dialog-almost-fullscreen {
	margin:12px;
	max-height:calc(100vh - 24px)!important;
}

@media (max-width: 600px) {
	.simple-dialog {
		margin: 0;
		border-radius:0!important;
		/* height:100vh; */
	}

	.simple-dialog .v-card {
		border-radius:0!important;
		/* height:100vh; */
	}

	.simple-dialog .v-card__text {
		font-size:15px;
		line-height:19px;
		padding:8px!important;
	}

	.simple-dialog .v-card__title {
		padding:8px 8px 0 8px!important;
	}
	.simple-dialog .v-card__title h3 {
		font-size:20px;
		text-align:center;
	}

	.simple-dialog .v-card__actions {
		flex-wrap:wrap;
	}

	.simple-dialog .v-card__actions .v-btn {
		width:100%;
		margin-top:5px;
	}
}
</style>
