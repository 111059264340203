<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<v-app v-show="app_mode!='uninitialized' && app_mode!='launch_activity'">
	<div v-show="app_mode!='login'||activity_id" class="k-top-bar">
		<div class="k-portal-logo-holder" @click="toggle_sparkler"><Logo v-if="app_mode!='login'||activity_id" ref="logo"></Logo></div>
		<!-- <div class="k-portal-app-title" v-if="!activity_id">Portal</div> -->
		<v-spacer/>

		<v-btn v-if="user_is_instructor&&site_config.flavor!='velocity'&&app_mode=='collections'" color="green darken-3" dark class="mt-2 k-tight-btn" @click="show_assigning=true"><v-icon small class="mr-2">fas fa-users</v-icon>Use {{app_noun}} with your Students</v-btn>

		<v-spacer/>

		<!-- hide FAQ button for now, until we can update it... -->
		<!-- <v-btn class="mr-3 mt-3" small color="light-blue" @click="show_faq=!show_faq"><v-icon small class='mr-2'>fas fa-question</v-icon> FAQ</v-btn> -->

		<div v-show="app_mode!='login'&&!signed_in" class="k-app-google-sign-in-btn" id="app_google_sign_in_btn"></div>

		<v-menu offset-y bottom left eager><template v-slot:activator="{ on }"><v-btn v-if="app_mode!='dialog'" style="margin-right:8px; margin-top:-2px;" v-on="on" fab text><v-icon>fas fa-bars</v-icon></v-btn></template>
			<v-list dense min-width="250" class="k-portal-app-menu-vlist">
				<v-list-item v-if="user_is_instructor" @click="teacher_collections_manager_showing=true"><v-list-item-icon><v-icon small>fas fa-ellipsis-h</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Manage Teacher Collections</v-list-item-title></v-list-item-content></v-list-item>
				<v-list-item v-if="user_is_admin" @click="create_teacher_account"><v-list-item-icon><v-icon small>fas fa-user</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Create a New Teacher Account</v-list-item-title></v-list-item-content></v-list-item>
				<v-list-item v-if="user_is_instructor" @click="import_archive"><v-list-item-icon><v-icon small>fas fa-file-import</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Import Activity from Archive</v-list-item-title></v-list-item-content></v-list-item>
				<v-list-item v-if="user_is_admin" @click="mimic_user"><v-list-item-icon><v-icon small>fas fa-user-secret</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Mimic Another User</v-list-item-title></v-list-item-content></v-list-item>
				<!-- <v-list-item @click="show_changelog=true"><v-list-item-icon><v-icon small>fas fa-clipboard-list</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Show System Change Log</v-list-item-title></v-list-item-content></v-list-item> -->
				<v-list-item v-if="user_is_pepper" @click="toggle_sparkl_stats"><v-list-item-icon><v-icon small>fas fa-chart-bar</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title>Show {{app_noun}} Stats</v-list-item-title></v-list-item-content></v-list-item>
				<v-divider v-if="user_is_instructor"></v-divider>

				<v-list-item v-if="signed_in" @click="sign_out"><v-list-item-icon style="margin-top:40px; margin-bottom:0"><v-icon>fas fa-sign-out-alt</v-icon></v-list-item-icon><v-list-item-content><v-list-item-title style="vertical-align:top"><div style="margin-bottom:4px; font-size:.8em;"><i>Signed in with {{user_is_instructor?'teacher':'student'}} access<br>as {{signed_in_as}}</i></div>Sign out</v-list-item-title></v-list-item-content></v-list-item>
				<div class="k-sparkl-version-string" v-html="sparkl_version_string"></div>
			</v-list>
		</v-menu>
	</div>

	<v-main class="mb-6">
		<LoginView ref="login_view" v-if="app_mode=='login'"/>
		<LaunchActivityView ref="launch_activity_view" v-if="app_mode=='launch_activity'"/>
		<Collections ref="collections_view" v-if="app_mode=='collections'"/>
		<StatsView ref="stats_view" v-if="app_mode=='sparkl_stats'"/>

		<ManageTeacherCollections v-if="teacher_collections_manager_showing" @dialog_cancel="teacher_collections_manager_showing=false" />
	</v-main>
	<v-footer v-if="!$vuetify.breakpoint.xsOnly&&app_mode!='dialog'">
		<!-- <v-btn v-if="user_is_pepper" small text color="#ccc" @click="show_satchel_demo=!show_satchel_demo"><v-icon small class="mr-2">fas fa-suitcase</v-icon> Satchel Demo</v-btn> -->
		<v-spacer/>
		<div><v-btn small text @click="show_about=true"><v-icon small class="mr-2">fas fa-info-circle</v-icon><b>About {{app_noun}}</b></v-btn></div>
		<div><v-btn small text @click="show_privacy=true">Privacy Policy</v-btn></div>
		<div class="ml-3">&copy; {{ new Date().getFullYear() }} <a class="k-cglt-footer-link" href="https://commongoodlt.com" target="_blank">Common Good Learning Tools</a></div>
		<div class="ml-4" v-if="dark_mode_available"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="dark_mode_lst" mandatory>
			<v-btn small class="k-tight-btn" light :value="true"><span style="font-weight:normal">Dark Mode</span></v-btn>
			<v-btn small class="k-tight-btn" light :value="false">&nbsp;Light Mode&nbsp;</v-btn>
		</v-btn-toggle></div>
	</v-footer>
	<v-footer v-if="$vuetify.breakpoint.xsOnly&&app_mode!='dialog'">
		<v-spacer></v-spacer>
		<v-menu bottom left><template v-slot:activator="{on}"><v-btn v-on="on" icon text><v-icon>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item><v-list-item-title class="text-center"><v-btn small text @click="show_about=true">About {{app_noun}}</v-btn></v-list-item-title></v-list-item>
				<v-list-item><v-list-item-title class="text-center"><v-btn small text @click="show_privacy=true">Privacy Policy</v-btn></v-list-item-title></v-list-item>
				<v-list-item v-if="dark_mode_available"><v-list-item-title class="text-center"><v-btn-toggle dense active-class="k-toggle-btn-active-class" class="k-toggle-btn" v-model="dark_mode_lst" mandatory>
					<v-btn small class="k-tight-btn" light :value="true"><span style="font-weight:normal">Dark Mode</span></v-btn>
					<v-btn small class="k-tight-btn" light :value="false">&nbsp;Light Mode&nbsp;</v-btn>
				</v-btn-toggle></v-list-item-title></v-list-item>
				<v-list-item><v-list-item-title class="text-center">&copy; {{ new Date().getFullYear() }} <a class="k-cglt-footer-link" href="https://commongoodlt.com" target="_blank">Common Good Learning Tools</a></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</v-footer>

	<ChangeLog v-if="show_changelog" @dialog_done="show_changelog=false" />
	<PrivacyPolicy v-if="show_privacy" @dialog_done="show_privacy=false" />
	<AboutSparkl v-if="show_about" @dialog_done="show_about=false" />
	<AssigningSparkl v-if="show_assigning" @dialog_cancel="show_assigning=false" />
	<TeacherRequest v-if="teacher_access_request_showing" @dialog_cancel="teacher_access_request_showing=false" />
	<FAQTree v-show="show_faq" @hide_tree="show_faq=false" />
	<SatchelInline ref="satchel" :small_frame="satchel_small_frame" />
	<SatchelDemo v-if="show_satchel_demo" @dialog_cancel="show_satchel_demo=false" />
</v-app>
</template>

<script>
import Logo from '@/../../app-common/components/Logo'
import PrivacyPolicy from '@/../../app-common/components/PrivacyPolicy'
import AboutSparkl from '@/../../app-common/components/about/AboutSparkl'
import AssigningSparkl from '@/../../app-common/components/about/AssigningSparkl'
import TeacherRequest from '@/../../app-common/components/about/TeacherRequest'
import LoginView from './components/LoginView'
import LaunchActivityView from './components/LaunchActivityView'
import Collections from './components/Collections'
import StatsView from './components/StatsView'
import ChangeLog from './components/ChangeLog'
import FAQTree from '@/../../app-common/components/faq/FAQTree'
import ManageTeacherCollections from '@/../../app-common/components/collections/ManageTeacherCollectionsPortalApp'
// import CreateQuiz from '@/../../app-common/one-time/create-quiz'
import SatchelInline from '@/../../app-common/components/SatchelInline'
import SatchelDemo from '@/components/SatchelDemo'
import { mapState, mapGetters } from 'vuex'

export default {
	name: 'App',
	components: { LoginView, LaunchActivityView, Collections, StatsView, Logo, ChangeLog, PrivacyPolicy, AboutSparkl, AssigningSparkl, TeacherRequest, FAQTree, ManageTeacherCollections, SatchelInline, SatchelDemo },
	data() { return {
		app_mode: 'uninitialized',
		showing_portal: false,
		show_changelog: false,
		show_privacy: false,
		show_about: false,
		show_faq: false,
		show_assigning: false,
		teacher_collections_manager_showing: false,
		teacher_access_request_showing: false,

		// FOR SATCHEL DEMO
		show_satchel_demo: false,	// debug: change to false before moving to server
		satchel_small_frame: false,
	}},
	computed: {
		...mapState(['google_client_id', 'user_info', 'user_is_instructor', 'user_is_admin', 'site_config', 'student_access_policy']),
		...mapGetters(['signed_in', 'context_lti', 'dark_mode_available', 'dark_mode', 'app_noun']),
		signed_in_as() {
			// for open-door signins, show name; otherwise show email
			if (this.user_info.email.indexOf('@no-sign-in.edu') > -1) return this.user_info.displayname
			else return this.user_info.email
		},
		sparkl_version_string() { return this.app_noun + ' ' + window.SPARKL_VERSION_STRING },
		collections_view() { return this.$refs.collections_view },
		user_is_pepper() { return this.user_info.email == 'pepper.williams@gmail.com' },
		dark_mode_lst: {
			get() { return this.$store.getters.dark_mode },
			set(val) { 
				this.$store.commit('lst_set', ['dark_mode', val]) 
				this.$vuetify.theme.dark = val
			}
		},
		activity_id() {
			if (window.location.search.search(/\ba=(\d+)/) > -1) {
				// sparkl-ed.com?a=123
				return RegExp.$1
			} else if (window.location.pathname.search(/^\/(\d+)\b/) > -1) {
				// sparkl-ed.com/123
				return RegExp.$1
			}
			return 0
		}
	},
	watch: {
		app_mode() {
			if (this.app_mode == 'login') {
				setTimeout(x=>this.toggle_sparkler(true), 100)
			} else {
				setTimeout(x=>this.toggle_sparkler(false), 100)
			}

			if (this.app_mode != 'login' && !this.signed_in) {
				google.accounts.id.renderButton(
					document.getElementById("app_google_sign_in_btn"),
					{ 
						theme: "filled_blue", 
						size: "large",
					} 
				)
			}
		}
	},
	created() {
		// // as of 1/2024, we *only* use this portal app for launching individual activities (so where activity_id is filled in, *with a few exceptions...*
		// if (this.activity_id == 0) {
		// 	console.log('SPARKL HERE')
		// 	// if 'legacy-portal' is in the search string, allow the launch to continue -- show the user the legacy Sparkl portal
		// 	if (window.location.search.includes('legacy-portal')) {}
		// 	// if this is an LTI launch, allow the launch to continue -- we will ask the user to input an activity ID
		// 	else if (window.location.search.includes('lti_launch')) {}
		// 	// TODO: HenryConnects launch? (I don't think that should happen)
		// 	else {
		// 		// if we get to here, the user opened https://sparkl-ed.com with no activity_id, with no lti info.
		// 		// if we get to here, redirect to the appropriate sign-in location; default is portal.sparkl-ed.com
		// 		let url = 'https://portal.sparkl-ed.com'
		// 		// for velocity go to inspire
		// 		if (window.location.origin.includes('gadoe')) url = 'https://inspire.gadoe.org'
		// 		// more options here???
		// 		this.$alert('
		// 		window.location.replace(url)
		// 		return
		// 	}
		// }

		window.vapp = this
		
		// set activity_labels based on localstorage
		this.$store.commit('set', ['activity_labels', U.local_storage_get('sparkl_portal_activity_labels', ['featured'])])

		// figure out if we're showing the "portal" (i.e. a context, as opposed to showing a single activity)
		if (window.location.search.search(/\ba=(\d+)/) == -1) {
			this.showing_portal = true
		}
	},
	mounted() {
		// hash shortcuts to various places
		if (document.location.hash == '#privacy') vapp.show_privacy_dialog()
		else if (document.location.hash == '#about') vapp.show_about_dialog()
		else if (document.location.hash == '#changelog') vapp.show_changelog_dialog()
		else if (document.location.hash == '#request') vapp.request_teacher_access()
		else if (document.location.hash == '#assigning') vapp.show_assigning_dialog()

		this.initialize_app()
	},
	methods: {
		initialize_app(payload, show_loading) {
			// initialize local_storage settings
			this.$store.commit('lst_initialize')

			if (show_loading === true) U.loading_start()
			this.$store.dispatch('initialize_portal', payload).then((mode)=>{
				document.title = this.app_noun

				// if dark mode is available, set theme.dark based on lst; otherwise set to false
				if (this.dark_mode_available) this.$vuetify.theme.dark = this.$store.state.lst.dark_mode
				else this.$vuetify.theme.dark = false

				if (show_loading === true) U.loading_stop()

				// then after a tick for everything to get set up...
				this.$nextTick(()=>{
					this.app_mode = mode

					// if we're executing an lti_launch (this would happen after a login with Google), just return; the launch form will be shown and we'll redirect
					if (mode == 'launch_activity') {
						return
					}

					// since we're not launching an activity, remove the k-body-initialized class from body (until we do this, *nothing* appears in the browser)
					// we use this mechanism, and others, to prevent a "flashing" effect when activities are being loaded
					$('body').removeClass('k-body-uninitialized')

					// the other "standard" mode values are 'login' or 'collections'; anything else is an error code
					if (mode != 'login' && mode != 'collections') {
						this.app_mode = 'dialog'
						let msg = `An error occurred when launching the activity<br><br>Error Code: L-100-${mode}`

						// bad activity id
						if (mode.search(/activity_does_not_exist-(\d+)/) > -1) {
							// let activity_id = document.location.pathname.replace(/.*?(\d+).*/, '$1')
							let activity_id = RegExp.$1
							msg = `The ${this.app_noun} activity you’re trying to open (Activity ID <b>${activity_id}</b>) does not exist. If you’re a student, please ask your teacher for help.<br><br>Error Code: L-101`
						} else if (mode.search(/student_access_policy_NOT_no_sign_in-(\d+)/) > -1) {
							let activity_id = RegExp.$1
							msg = `The ${this.app_noun} activity you’re trying to open (Activity ID <b>${activity_id}</b>) is not enabled for open demo access.<br><br>Error Code: L-104`
						} else if (mode.search(/no_email_in_lti_launch-(\d+)/) > -1) {
							let organization_id = RegExp.$1
							msg = `No email address was received in the activity launch. If you were trying to launch from an LMS, the LTI connection from the LMS is probably not set up properly.<br><br>Error Code: L-102-${organization_id}`
						} else if (mode.search(/no_name_in_lti_launch-(\d+)/) > -1) {
							let organization_id = RegExp.$1
							msg = `Your user name was not properly received in the activity launch. If you were trying to launch from an LMS, the LTI connection from the LMS is probably not set up properly.<br><br>Error Code: L-103-${organization_id}`
						}
						this.$alert({
							text:msg, 
							opacity: '0', 
							hideAccept:true
						})
						return
					}

					// if we get to here mode is 'login' or 'collections'

					// if we didn't receive an activity_id to launch...
					// as of 1/2024, we *only* use this portal app for launching individual activities, so unless 'legacy-portal' is in the search string (this is a back door)...
					if (this.activity_id == 0 && !window.location.search.includes('legacy-portal')) {
						this.app_mode = 'dialog'

						// lti launch, but we didn't get an activity_id/launch form (if we had, mode would have been launch_activity and we would have returned above), so ask the user what to do
						if (this.context_lti) {
							this.ask_for_activity_id()
							return
						}

						// TODO: HenryConnects launch? (I don't think that should happen)

						// if we get to here, we will probably redirect to the appropriate sign-in location; default is portal.sparkl-ed.com
						let url = 'https://portal.sparkl-ed.com'
						// for velocity go to inspire
						if (window.location.origin.includes('gadoe')) url = 'https://inspire.gadoe.org'
						// more options here???

						// but the user might have been trying for an LTI launch where LTI wasn't set up properly, so ask about that
						this.$alert({
							text: `If you were attempting to launch ${this.app_noun} via LTI from an LMS, the LTI connection was not set up properly. Please contact your system administrator.<div class="mt-3">Otherwise, click the button below to redirect to ${url}.`,
							acceptText: `Redirect to <span class="k-nocaps-btn k-tight-btn">${url}</span>`,
							acceptIconAfter: `fas fa-arrow-right`,
							dialogMaxWidth: 600,
							opacity: '0',
						}).then(y=>{
							window.location.replace(url)
						})
						
						return
					}

					// if we get to here we're showing the login/collections page, initialize google
					if (!this.signed_in && !this.context_lti) setTimeout(x=>this.initialize_google())
				})
			})
		},

		ask_for_activity_id() {
			let sample_url = `${window.location.origin}/XXXXX`
			this.$prompt({
				title: `Welcome to ${this.app_noun}!`, 
				text: `<div style="font-size:16px; line-height:20px" class="mb-3">To configure this LMS assignment, please enter the ${this.app_noun} Activity URL you wish to use for the LMS assignment. This Activity URL will have the following format, where “XXXXX” is an integer between 100 and 9999999:<div class="my-3 text-center"><b>${sample_url}</b></div>NOTE: You only need to do this once. In the future, you can click the same assignment link from your LMS to edit, administer, or view student results for the activity.</div>`,
				acceptText: 'Configure with this Activity URL',
				acceptIconAfter: 'fas fa-arrow-right',
				disableForEmptyValue: true,
				hideCancel: true,
				dialogMaxWidth: 600,
				opacity: '0',
			}).then(activity_url => {
				console.log('HERE: ' + activity_url)
				let activity_id = $.trim(activity_url).replace(/.+\/(\d+).*/, '$1') * 1
				if (isNaN(activity_id)) {
					this.$alert({
						text: 'The Activity URL you entered is not valid. Try again.'
					}).then(x=>this.ask_for_activity_id())
					return
				}
				console.log('configure activity_id ' + activity_id)
				this.$store.dispatch('configure_lti_link', {
					activity_id: activity_id,
				}).catch(error=>{
					if (error == 'activity_does_not_exist') {
						this.$alert({
							text: 'The Activity URL you entered is not valid. Try again.'
						}).then(x=>this.ask_for_activity_id())
					} else {
						this.$alert('Error configuring activity: ' + error).then(x=>this.ask_for_activity_id())
					}
				})
				// configure_lti_link will redirect to the activity
			})
		},

		initialize_google() {
			U.loading_start()
			// window.gapi should be loaded via https://apis.google.com/js/platform.js in index.html
			// if it isn't loaded yet, try again in 100 ms
			if (typeof(google) == 'undefined' || typeof(google.accounts) == 'undefined') {
				setTimeout(()=>this.initialize_google(), 100);
				console.log('re-try google.accounts.id.initialize')
				return;
			}
			U.loading_stop()

			// console.log('id', this.google_client_id)

			// api https://developers.google.com/identity/gsi/web/reference/js-reference
			google.accounts.id.initialize({
				client_id: sr('$1.apps.googleusercontent.com', this.google_client_id),
				callback: this.handleCredentialResponse,

				// This field determines if an ID token is automatically returned without any user interaction when there's only one Google session that has approved your app before. The default value is false
				// PW: I don't think we want to do this, because we want the user to explicitly initiate a sign-in
				// auto_select: true,

				// This field sets whether or not to cancel the One Tap request if a user clicks outside the prompt. The default value is true. You can disable it if you set the value to false
				// cancel_on_tap_outside: false,	

				// This attribute sets the DOM ID of the container element. If it's not set, the One Tap prompt is displayed in the top-right corner of the window.
				// prompt_parent_id: 'google_one_tap_holder',
				
				// This field determines if the upgraded One Tap UX should be enabled on browsers that support Intelligent Tracking Prevention (ITP). The default value is false. MAY BE NEEDED FOR IPHONE SUPPORT
				// itp_support: true,
			})

			// render the btn, either as the big button in the middle of the screen on the login page, or as the smaller btn in the top-right of the screen
			if (!this.signed_in) {
				if (this.app_mode == 'login') {
					google.accounts.id.renderButton(document.getElementById('google_sign_in_btn'), { 
						theme: 'filled_blue', 
						size: 'large',
						width: 280,
					})
				} else {
					google.accounts.id.renderButton(document.getElementById("app_google_sign_in_btn"), { 
						theme: "filled_blue", 
						size: "large",
					})
				}
			}

			// display the One Tap dialog, unless student_access_policy is 'no_sign_in' (teachers can still sign in via google with the "show teacher sign-in" btn)
			if (this.student_access_policy != 'no_sign_in') {
				google.accounts.id.prompt((notification) => {
					if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
						$('.k-google-sign-in-outer').addClass('k-google-sign-in-outer-one-tap-hidden')
					}
				})
			}
		},

		handleCredentialResponse(response) {
			//console.log('Encoded JWT ID token: ' + response.credential);
			let id_token = response.credential
			if (id_token) {
				// pass id_token back to initialize_portal service, which will sign the user in
				vapp.initialize_app({id_token: id_token})
			} else {
				// TODO: what do we do here?
				alert('Google sign in handleCredentialResponse failed')				
			}
        },

		toggle_sparkler(val) {
			if (this.$refs.logo) this.$refs.logo.toggle_sparkler(val)
		},

		activity_url(activity_id) {
			let curr_url = window.location.href;
			let origin_domain = window.location.origin;
			let url
			// local dev with 'npm run serve'
			if (curr_url.includes('localhost')) {
				// url = 'http://localhost:8072?a=' + activity_id
				url = 'http://localhost:8072/' + activity_id
			// local testing with 'npm run build'
			} else if (curr_url.includes('local.pw-et.com')) {
				url = 'http://local.pw-et.com/' + activity_id
			// server
			} else {
				url = origin_domain + '/' + activity_id
			}
			return url
		},

		import_archive() {
			// prompt for file to import
			this.$prompt({
				text: 'Select the activity archive file you’d like to import:',
				promptType: 'file',
				acceptText: 'Import',
				cancelText: 'Cancel'
			}).then(file => {
				if (empty(file) || empty(file.name)) return
				// we receive a file from dialog-promise-pwet here; create a FileReader
				let reader = new FileReader()
				reader.onload = e => {
					// JSON has been loaded to the client...
					let fileJSON = e.target.result

					// see if the json includes student data
					let json = JSON.parse(fileJSON)
					let anonymize_results = false
					if (!empty(json.results) && !empty(json.users)) {
						this.$confirm({
							title: 'Handle Imported Results',
							text: 'This archive includes student data. When importing this data, would you like ' + this.app_noun + ' to associate the data with the actual student names and ' + this.app_noun + ' user_ids, or would you instead like ' + this.app_noun + ' to “anonymize” the students by generating new ' + this.app_noun + ' user_ids and new, randomly created names?',
							acceptText: '“Anonymize” student names',
						    cancelText: 'Use actual student names',
							dialogMaxWidth: 800
						}).then(y => {
							// "accept" btn = anonymize results
							this.import_archive_finish(fileJSON, true)

						}).catch(n=>{
							// "cancel" btn = use existing names
							this.import_archive_finish(fileJSON, false)

						}).finally(f=>{})

					} else {
						this.import_archive_finish(fileJSON, false)
					}
				}
				// trigger the FileReader to load the text of the file
				reader.readAsText(file)
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		import_archive_finish(fileJSON, anonymize_results) {
			// send the JSON up to the server (don't parse it, because that can lead to a ton of POST variables, which may exceed PHP's max_input_vars)
			U.ajax('create_activity_from_archive', {json: fileJSON, teacher_email: this.user_info.email, anonymize_results: anonymize_results?'yes':'no'}, result=>{
				if (result.status != 'ok') {
					alert('Error Uploading Activity!!')
					return
				}

				// we used to need to warn about this, but not anymore
				if (false && result.original_exercise_not_found) {
					this.$alert('Please note: at least one exercise in the imported activity was not found in the database. This means that if the activity includes constructed response questions, you will need to edit and resave those questions in order for them to be delivered properly to students.').then(x=>document.location.reload())
				} else {
					// reload to show the newly created activity
					document.location.reload()
				}
			})
		},

		toggle_sparkl_stats() {
			// if user was already in admin tool, go to activities
			if (this.app_mode == 'sparkl_stats') {
				this.app_mode = 'collections'
			} else {
				this.app_mode = 'sparkl_stats'
			}
		},

		create_teacher_account() {
			this.$prompt({
				text: 'Enter the Google email for the teacher:',
				acceptText: 'Create Account',
			}).then(email => {
				if (!empty(email)) {
					U.ajax('create_teacher_account', {user_id: this.user_info.user_id, new_account_email: email}, result=>{
						if (result.status != 'ok') {
							alert('Error creating account')
							return
						} else {
							if (result.action == 'created') {
								this.$alert('Account created.')
							} else if (result.action == 'upgraded') {
								this.$alert('Account upgraded to teacher rights.')
							} else {
								this.$alert(sr('An account for this email ($1) already existed.', email))
							}
						}
					})
				}
			}).catch(n=>{}).finally(f=>{});
		},

		mimic_user() {
			this.$prompt({
				text: 'Enter the email of the user you’d like to mimic',
				promptType: 'autocomplete',
				serviceName: 'email_search',
				acceptText: 'Mimic This User',
			}).then(email => {
				// return value should be e.g. 'pepper@gmail.com (Pepper Williams)'; extract the email
				email = $.trim(email).toLowerCase().replace(/^(\S+).*/, '$1')
				if (!empty(email)) {
					this.$store.dispatch('mimic_user', {email_to_mimic: email, email:this.user_info.email}).then(()=>{
						document.location.reload()
					}).catch(error=>{
						this.$alert(error).then(x=>this.mimic_user())
					})
				}
			}).catch(n=>{console.log(n)}).finally(f=>{});
		},

		show_about_dialog() {
			this.show_about = true
			document.location.hash = ''
		},

		show_privacy_dialog() {
			this.show_privacy = true
			document.location.hash = ''
		},

		show_assigning_dialog() {
			this.show_assigning = true
			document.location.hash = ''
		},

		show_changelog_dialog() {
			this.show_changelog = true
			document.location.hash = ''
		},

		// determine if the user's session is still active; if not, show a message and call the sign out process
		ping() {
			let sign_user_out = () => {
				let msg = 'You are not signed in. You may have been automatically signed out due to inactivity.'
				this.$alert({
				    text: msg,
				}).finally(f=>{
					this.$store.dispatch('sign_out')
				})
			}

			// use plain-vanilla XMLHttpRequest to call the ping service
			var xhr = new XMLHttpRequest()
			xhr.onreadystatechange = function() {
			    if (xhr.readyState === 4) {		// fetch operation is done
					// if we get a 'T' or an 'S', the user is logged in
					if (xhr.responseText == 'S' || xhr.responseText == 'T') {
						console.log('ping OK')

					} else {
						sign_user_out()
					}
				}
			}
			xhr.open('GET', '/src/portal_ping.php')
			xhr.send()
		},

		request_teacher_access() {
			this.teacher_access_request_showing = true
			// let msg = 'If you’re a teacher and you’d like to try Sparkl with your students, please send an email to <a href="mailto:pepper@commongoodlt.com?subject=Sparkl%20teacher%20access%20request&body=Hi,%20this%20is%20____%20at%20____%20school%20in%20____%20district.%20I%20teach%20____%20grade(s),%20and%20I’d%20like%20to%20try%20Sparkl%20with%20my%20students."><b>pepper@commongoodlt.com</b></a> including your name, school, district, and what grade(s) you teach. We’ll set you up with a teacher account, schedule a video call to answer questions and give you some training, and you’ll be ready to go!'
			// this.$alert({title:'Request Teacher Access', text: msg, dialogMaxWidth:540})
		},

		sign_out() {
			this.$store.dispatch('sign_out')
		},

		small_screen() {
			return $(window).width() < 760
		},
	}
}
</script>

<style lang="scss">
body {
	font-size:18px;
	background-color:#222;
}

.k-body-uninitialized {
	// background-color:#fff;
	.background-container {
		display:none;
	}
	.spinner-wrapper {
		display:none;
	}
}

.v-application {
	font-family: $sans-serif-font;
}

.theme--dark.v-application {
	background:transparent;
	// background:rgba(0, 0, 0, 0.5);
}

.background-container {
	opacity:0.3;
}

.k-portal-app-menu-vlist .v-list-item__title {
	font-size:14px!important;
}

.k-context-title {
	font-weight:bold;
	margin-left:25px;
}

.v-btn {
	text-transform: none;
	font-size:16px;
}

.k-sparkl-portal-page-title {
	// font-family: $display-font;
	font-size:34px;
	font-weight:bold;
	color:$gold;
	margin-left:20px;
	margin-top:-1px;
}

.k-app-google-sign-in-btn {
	margin-top:4px;
	margin-right:12px;
}

.k-top-bar {
	background-color:#111;
	width:100vw;
	height:70px;
	display:flex;
	padding:10px;
	position:relative;

	// .k-google_signin_btn {
	// 	height:48px;
	// 	margin-right:15px;
	// 	cursor:pointer;
	// }
}

.k-portal-logo-holder {
	position:relative;
	cursor:pointer;
}

.k-portal-app-title {
	position:relative;
	font-size:30px;
	// font-style: italic;
	border:1px solid #fff;
	border-radius:10px;
	line-height:32px;
	height:44px;
	padding:4px 8px;
	// font-weight:bold;
	// font-variant:small-caps
	margin-left:16px;
	margin-top:2px;
	display:flex;
	// align-items: baseline;
	// flex:0 0 270px;

	.k-portal-app-title-expandable {
		font-size:24px;
		padding-top:7px;
		width:0;
		overflow:hidden;
		@include transition(all 0.3s);
	}
}

.v-footer {
	position:absolute;
	bottom:0;
	left:0;
	width:100vw;
	z-index:1;
	font-size:14px;

	.k-copyright-notice {
		margin-left:30px;
	}

	.v-btn {
		font-weight:normal;
		font-size:12px;
	}
}

// smartphone screens, landscape or portrait
@media (max-width: 760px) {
	.v-footer {
		font-size:12px;
		justify-content: center;

		.k-copyright-notice {
			margin-left:0;
		}

		.v-btn {
			font-size:10px;
		}
	}

	// .k-top-bar {
	// 	.k-google_signin_btn {
	// 		height:30px;
	// 		margin-right:10px;
	// 	}
	// }
}

.k-cglt-footer-link {
	text-decoration:none;
	color:inherit!important;
}

///////////////////////////////
// default colors are for dark mode; set here for light mode
.theme--light {
	.k-top-bar {
		background-color:rgba(194,194,194,0.9);	// c2
	}

	.v-footer {
		background-color:#ddd;
	}

	.k-dark-dialog-card {
		background-color:#eee!important;
		border:3px solid $v-indigo!important;
		border-radius:8px!important;
		.v-card__text {
			color:#000!important;
		}
	}

	.k-dark-dialog-card-title, .k-dark-dialog-card-btns {
		// border-bottom:1px solid #999;
		background-color:#ddd;
		padding:12px 20px!important;
	}

	.k-portal-app-title {
		border:1px solid #000;
	}

	.k-portal-welcome-message {
	}

	.k-portal-collection {
		background-color:transparent;
		border-top-color:#ccc;
		border-radius:0;
	}

	.k-portal-collection.k-portal-collection-minimized {
		border-top-color:#ccc;
		// border:0;
	}

	.k-portal-collection:first-of-type {
		border-top-color:transparent;
	}

	.k-portal-collection-header-title {
		color:#000!important;
	}

}

.theme--light.v-application {
	// background:#e2e2e2!important;
	// background:#fff!important;
	background:#f8f8f8!important;
}
</style>
