<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="700" overlay-opacity="0.9" persistent scrollable>
		<v-card class="k-dark-dialog-card k-activity-settings-form">
			<v-card-title><b>Request Teacher Access to Sparkl</b></v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<!-- <div class="mb-3">Please submit the following information:</div> -->

				<div class="d-flex mb-4 pt-2">
					<v-text-field class="mx-1" label="First name" background-color="#2f2f2f" hide-details dense outlined v-model="name_given"></v-text-field>
					<v-text-field class="mx-1" label="Last name" background-color="#2f2f2f" hide-details dense outlined v-model="name_family"></v-text-field>
				</div>

				<div class="d-flex mb-4">
					<v-text-field class="mx-1" label="School district" background-color="#2f2f2f" hide-details dense outlined v-model="district"></v-text-field>
					<div style="width:150px" class="mx-1"><v-select background-color="#2f2f2f" v-model="state" :items="state_arr" label="State" hide-details dense outlined single-line></v-select></div>
				</div>

				<div class="d-flex mb-4 align-center">
					<v-text-field class="mx-1" label="District email address" background-color="#2f2f2f" hide-details dense outlined v-model="school_email"></v-text-field>
					<v-text-field class="mx-1" label="Google email (if different)" background-color="#2f2f2f" hide-details dense outlined v-model="google_email"></v-text-field>
					<v-btn class="ml-1" small icon color="primary" @click="show_email_help"><v-icon class="mr-1">fas fa-info-circle</v-icon></v-btn>
				</div>

				<div class="d-flex mb-4">
					<div style="width:66%"><v-select class="mx-1" background-color="#2f2f2f" v-model="disciplines" :items="discipline_arr" label="I teach…" hide-details dense outlined multiple></v-select></div>
					<div style="width:34%"><v-select class="mx-1" background-color="#2f2f2f" v-model="grades" :items="grade_arr" label="…for grade(s)…" hide-details dense outlined multiple></v-select></div>
				</div>

				<div class="d-flex">
					<v-textarea rows="2" class="mx-1" label="Additional information (optional)" background-color="#2f2f2f" hide-details dense outlined v-model="extra_info"></v-textarea>
				</div>

			</v-card-text>
			<v-card-actions class="px-3 pb-4">
				<v-btn color="secondary" @click="$emit('dialog_cancel')">Cancel</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="submit_request">Submit Request</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		name_given: '',
		name_family: '',
		district: '',
		state: '',
		school_email: '',
		google_email: '',
		disciplines: [],
		grades: [],
		extra_info: '',

		state_arr: [
          'Alabama', 'Alaska', 'Arizona',
          'Arkansas', 'California', 'Colorado', 'Connecticut',
          'Delaware', 'District of Columbia',
          'Florida', 'Georgia', 'Hawaii', 'Idaho',
          'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
          'Louisiana', 'Maine', 'Maryland',
          'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
          'Missouri', 'Montana', 'Nebraska', 'Nevada',
          'New Hampshire', 'New Jersey', 'New Mexico', 'New York',
          'North Carolina', 'North Dakota', 'Ohio',
          'Oklahoma', 'Oregon', 'Pennsylvania',
          'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee',
          'Texas', 'Utah', 'Vermont', 'Virginia',
          'Washington', 'West Virginia', 'Wisconsin', 'Wyoming',
	  ],
	  discipline_arr: [ 'School/district administrator', 'Language Arts', 'Mathematics', 'Science', 'Social Studies', 'Fine Arts', 'Physical Education', 'Health', 'Computer Science', 'World Languages', 'CTAE', 'Other'],
	  grade_arr: [	'PK', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'],
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		show_email_help() {
			this.$alert('To get started with Sparkl, you will need to sign in with an email address that has an associated Google account. If you will be using Sparkl with an LMS such as Canvas, you will be later able to set things up so that you and your students can access Sparkl through the LMS.<br><br>If your school district uses Google for district email, you don’t need to enter a separate Google email address.')
		},

		submit_request() {
			let request = {
				name_given: $.trim(this.name_given),
				name_family: $.trim(this.name_family),
				district: $.trim(this.district),
				state: $.trim(this.state),
				school_email: $.trim(this.school_email),
				google_email: $.trim(this.google_email),
				disciplines: this.disciplines.join(','),
				grades: this.grades.join(','),
				extra_info: $.trim(this.extra_info),
			}

			// school_email and name are required fields
			if (!request.name_given || !request.name_family) {
				this.$alert('Please enter your full name.')
				return
			}
			if (!request.school_email) {
				this.$alert('Please enter your district email address.')
				return
			}

			if (!request.google_email) request.google_email = request.school_email

			U.loading_start()
			U.ajax('submit_teacher_access_request', {request: request}, result=>{
				U.loading_stop()
				if (result.status == 'teacher_account_exists') {
					this.$alert('The google email you entered already has a Sparkl account with teacher access enabled!')
				} else if (result.status != 'ok') {
					this.$alert('Error submitting request')
				} else {
					this.$alert('Your request has been submitted, and you should be hearing back from someone soon. Thanks for your interest in Sparkl!').then(x=>{
						this.$emit('dialog_cancel')
					})
				}
			});

		}
	}
}
</script>

<style lang="scss">
</style>
