<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><div>
	<vue-draggable-resizable :drag-handle="'.k-faq-top-title'" :resizable="true" class-name="k-faq-outer-wrapper" :h="620" :w="720" :minWidth="350" :minHeight="250">
		<div class="k-faq-top">
			<div class="k-faq-top-title">
				<v-icon color="#fff" class="mr-2" style="margin-left:-5px">fas fa-arrows-alt</v-icon>
				<div>Sparkl Frequently-Asked Questions and Answers…</div>
				<v-spacer/>
				<v-btn icon color="#fff" @click="$emit('hide_tree')"><v-icon>fas fa-times-circle</v-icon></v-btn>
			</div>
			<v-text-field light background-color="#fff" class="ma-1" solo hide-details clearable clear-icon="fas fa-circle-xmark" dense
				placeholder="Search"
				v-model="search_terms"
				prepend-inner-icon="fas fa-search" @click:prepend-inner="execute_search_start"
				append-outer-icon="fa fa-info-circle" @click:append-outer="show_search_help=true"
				@click:clear="execute_search_clear"
				@keyup="search_field_keyup"
				autocomplete="new-password"
			></v-text-field>
		</div>
		<div class="k-faq-main">
			<div class="k-faq-inner-wrapper">
				<div class="text-right" style="margin-top:-2px">
					<span style="color:#000;font-size:12px;float:left; margin-left:16px; margin-top:3px;"><i><b>Note:</b> This FAQ is still a work in progress.</i></span>
					<v-btn :style="open_items.length>0?'visibility:visible':'visibility:hidden'" @click="collapse_all" x-small text color="light-blue darken-2"><b>Collapse all</b></v-btn>
				</div>
				<v-treeview open-on-click dense light
					:open="open_items"
					:items="faq_tree"
					active-class="k-faq-item--active"
					item-key="faq_id"
					item-children="children"
					@update:open="open_updated"
				>
					<template v-slot:label="{ item }"><div :data-faq_id="item.faq_id">
						<div v-if="item.type=='node'" class="k-faq-tree-node" :class="node_css(item.faq_id)" v-html="highlight_search_terms(item.text)"></div>
						<div v-if="item.type=='question'" class="k-faq-tree-question" :class="node_css(item.faq_id)" v-html="highlight_search_terms(item.text)"></div>
						<div v-if="item.type=='answer'" class="k-faq-tree-answer" :class="node_css(item.faq_id)" v-html="faq_text(item.text)"></div>
					</div></template>
				</v-treeview>
			</div>
		</div>
	</vue-draggable-resizable>
	<FAQSearchHelp v-if="show_search_help" @dialog_cancel="show_search_help=false" />
	<FAQText />
</div></template>

<script>
// import Logo from '@/../../app-common/components/Logo'
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import FAQText from './FAQText.vue'
import FAQSearchHelp from './FAQSearchHelp.vue'

/*

***bold/italic***
**bold**
*italic*
!star! -- icon (fas fa-star)
[quiz exercise]($ee_quiz_use) -- link to another question
[Activity Editor](@activity_editor_screenshot) -- popup the named image (e.g. a screenshot) upon clicking the link

*/

export default {
	components: { FAQText, FAQSearchHelp },
	data() { return {
		open_items: [],
		search_terms: '',
		search_term_res: [],
		stop_words: [],
		search_results: [],
		show_search_help: false,
		faq_tree: [],
		faq_item_hash: {},
		show_search_help: false,
		linked_question: null,
		linked_answer: null,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	created() {
	},
	mounted() {
		// get and parse FAQ hierarchy and text from k-faq-tree-text
		let lines = $('.k-faq-tree-text').html().split(/\n/)
		this.faq_tree = []
		let last_items = []
		let most_recent_item
		for (let line of lines) {
			// console.log(line)
			// skip empty lines and lines that start with any number of spaces + //
			if (empty(line) || line.search(/^\s*\/\//) > -1) continue

			// item
			if (line.search(/^(\t*)\$(.*?):\s*(.*)/) > -1) {
				let level = RegExp.$1.length
				let item = {
					faq_id: RegExp.$2,
					type: 'node',
					text: RegExp.$3,
					children: [],
					parent: (level == 0) ? null : last_items[level - 1],
				}
				this.faq_item_hash[item.faq_id] = item
				// console.log(sr('$1 ($2): $3', level, item.faq_id, item.text))

				if (level == 0) {
					this.faq_tree.push(item)
				} else {
					last_items[level - 1].children.push(item)
				}
				last_items[level] = item
				most_recent_item = item

			// text for the most-recently-created item
			} else {
				if (most_recent_item.children.length == 0) {
					most_recent_item.type = 'question'

					let item = {
						faq_id: most_recent_item.faq_id + '_ANSWER',
						type: 'answer',
						text: '',
						parent: most_recent_item,
					}
					most_recent_item.children.push(item)
					this.faq_item_hash[item.faq_id] = item
				}
				most_recent_item.children[0].text += line + '\n'
			}
		}

		// store a reference to the component in vapp so we can target it via javascript links (see below)
		vapp.faq_tree = this
	},
	watch: {
	},
	methods: {
		open_updated(arr) {
			this.open_items = arr
		},

		// sample searches:
		//    energy ask questions: string contains "energy" AND "ask" AND "questions"
		//    energy "ask questions": string contains "energy" AND "ask questions"
		//    energy OR "ask questions": string contains "energy" OR "ask questions"
		//    energy OR ask questions: string contains "energy" OR ("ask" AND "questions")
		//    energ*: string contains "energy" or "energize" or "energetic", etc.
		create_search_re() {
			this.search_term_res = []
			this.stop_words = []

			if (empty(this.search_terms)) {
				this.search_terms = ''
				return
			}

			// remove all punctuation except a few things, to make sure regexps work
			this.search_terms = this.search_terms.replace(/[^0-9a-zA-Z+\-,;'" .*]/g, '')

			// replace . with \.
			let st = this.search_terms.replace(/\./g, '\\.')

			// wildcards: replace * with .*? (eliminating any surrounding spaces)
			st = st.replace(/\s*\*\s*/g, '\.*?')

			// pull out stop words
			st = st.replace(/-\s*"([^"]+)"/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = st.replace(/-\s*(\w+)/g, ($0, $1) => {
				this.stop_words.push(new RegExp('\\b' + $1 + '\\b', 'gi'))
				return ''
			})
			st = $.trim(st)

			if (!empty(st)) {
				// first split by ' OR 's into or_re_strings: “energy OR ask questions” => ['energy', 'ask questions']
				let or_arr = st.split(/\s+OR\s+/)
				for (let or_re_string of or_arr) {
					// trim each or_re_string
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// "hard-code" spaces within double-quotes: "foo bar" > foo\sbar
					or_re_string = or_re_string.replace(/"([^"]+)"/g, function($0, $1) {
						return "\\b" + $1.replace(/ +/g, "\\s") + "\\b";
					})
					or_re_string = $.trim(or_re_string)
					if (empty(or_re_string)) continue

					// then split the or_re_string into individual and_re_strings on spaces: “ask questions” => ['ask', 'questions']
					let or_res = []
					let and_re_strings = or_re_string.split(/\s+/)
					for (let and_re_string of and_re_strings) {
						and_re_string = $.trim(and_re_string)
						if (!empty(and_re_string)) {
							// create a regexp for this and_re_string
							or_res.push(new RegExp('(' + and_re_string + ')', 'gi'))
						}
					}

					// if we actually created an regexps, push onto search_term_re
					if (or_res.length > 0) {
						this.search_term_res.push(or_res)
					}
				}
			}
		},

		strings_match_search_term_res(string_arr) {
			// at least one of the top-level search_term_res arrays must match at least one of the strings of string_arr
			// (note that usually, the user won't use the 'OR' keyword, so there will be only one sub-array of search_term_res)

			// go through each set of "and_res" in the search_term_res array
			for (let and_res of this.search_term_res) {
				// now go through each string
				for (let string of string_arr) {
					string = $.trim(string)
					if (empty(string)) continue

					// now go through each "re" in the "and_res" array; each re must be in the string for it to match
					let match = true
					for (let re of and_res) {
						// if this re isn't in the string, it's not a match!
						if (string.search(re) == -1) {
							match = false
							break
						}
					}

					// if match is true, this string includes all the re's from this set of and_res, so we can return true overall
					if (match == true) return true

					// otherwise we keep looking for matches in other strings and/or with other sets of and_res
				}
			}

			// if we haven't returned true somewhere above, return false -- no match
			return false
		},

		string_includes_stop_word(s) {
			if (empty(s)) return false
			for (let sw of this.stop_words) {
				if (s.search(sw) > -1) return true
			}
		},

		execute_search(node) {
			if (empty(node)) return false

			// by default return false (item doesn't meet criteria)
			let rv = false

			// if the node has children, search the children
			if (!empty(node.children) && node.children.length > 0) {
				for (let child of node.children) {
					if (this.execute_search(child)) {
						if (!this.open_items.find(x=>x==node.faq_id)) this.open_items.push(node.faq_id)
						rv = true
					}
				}
			}

			// assuming the node has a text value, determine if it should be highlighted as a search result
			if (!empty(node.text)) {
				// if the description includes a stop word, no
				if (!this.string_includes_stop_word(node.text)) {
					let arr = [node.text]

					if (this.strings_match_search_term_res(arr)) {
						this.search_results.push(node.faq_id)
						if (!this.open_items.find(x=>x==node.faq_id)) this.open_items.push(node.faq_id)
						rv = true
					}
				}
			}

			return rv
		},

		execute_search_start() {
			this.collapse_all()
			U.loading_start()
			setTimeout(()=>U.loading_stop(), 100)
			this.execute_search_clear()
			this.create_search_re()
			for (let node of this.faq_tree) {
				this.execute_search(node)
			}
			if (this.search_results.length == 0) {
				this.$inform('No resources or resource categories matched your search terms.')
			}
		},

		execute_search_clear() {
			// uncomment this to close everything when search is cleared
			// this.open_items = []
			this.search_results = []
			this.search_term_res = []
		},

		search_field_keyup(evt) {
			if (evt.key == 'Enter' || evt.keyCode == 13) {
				this.execute_search_start()
			}
		},

		node_found_by_search(tree_key) {
			return (!empty(this.search_terms) && !empty(this.search_results.find(o=>o==tree_key)))
		},

		node_css(tree_key) {
			let s = ''
			if (this.open_items.find(x=>x==tree_key)) s += ' k-faq-item-open'
			if (this.node_found_by_search(tree_key)) s += ' k-faq-search-match'
			if (this.linked_question == tree_key) s += ' k-faq-linked-question'
			if (this.linked_answer == tree_key) s += ' k-faq-linked-answer'
			return s
		},

		highlight_search_terms(s) {
			// highlight search terms if we have them
			if (!empty(this.search_term_res)) {
				for (let res of this.search_term_res) {
					for (let re of res) {
						s = s.replace(re, '<span class="k-faq-searched-term">$1</span>')
						// fix replaces that break references
						s = s.replace(/(\$\S+)<span class="k-faq-searched-term">(.*?)<\/span>/g, '$1$2')
					}
				}
			}
			return s
		},

		faq_text(text) {
			text = this.highlight_search_terms(text)
			let html = ''
			let cur_level = 0
			let lines = text.split('\n')
			for (let line of lines) {
				let text = line

				let level = 0
				if (line.search(/^(\t*)-\s+(.*)/) > -1) {
					text = RegExp.$2
					level = 1 + RegExp.$1.length
				}

				// some markup stuff
				text = text.replace(/\*\*\*(\S.*?)\*\*\*/g, '<b><i>$1</i></b>')
				text = text.replace(/\*\*(\S.*?)\*\*/g, '<b>$1</b>')
				text = text.replace(/\*(\S.*?)\*/g, '<i>$1</i>')
				text = text.replace(/!(\S.*?)!/g, '<i class="fas fa-$1"></i>')
				text = text.replace(/\[(.*?)\]\(\$(\w+)\)/g, '<a class="k-faq-question-link" href="javascript:vapp.faq_tree.open_question(\'$2\')">$1</a>')
				text = text.replace(/\[(.*?)\]\(\@(\w+)\)/g, '<a class="k-faq-image-link" href="javascript:vapp.faq_tree.open_image(\'$2\')">$1</a>')

				// close open lists if necessary
				if (level < cur_level) {
					for (let i = cur_level; i > level; --i) {
						html += '</ul>'
					}

				// open lists if necessary
				} else if (level > cur_level) {
					for (let i = cur_level; i < level; ++i) {
						html += '<ul>'
					}
				}

				if (level > 0) {
					html += '<li>' + text + '</li>'
				} else {
					html += '<p>' + text + '</p>'
				}

				cur_level = level
			}

			for (let i = 0; i < cur_level; ++i) {
				html += '</ul>'
			}

			return html
		},

		collapse_all() {
			this.open_items = []
		},

		open_question(faq_id) {
			let item = this.faq_item_hash[faq_id]
			while (item != null) {
				this.open_items.push(item.faq_id)
				item = item.parent
			}

			this.linked_question = faq_id
			this.linked_answer = faq_id + '_ANSWER'

			setTimeout(()=>{
				this.linked_question = null
				this.linked_answer = null
			}, 5000)

			this.$nextTick(()=>{
				let target = $(sr('[data-faq_id=$1]', faq_id))[0]
				let container = $('.k-faq-inner-wrapper')[0]
				goTo(target, {container:container, offset:40})
			})
		},

		open_image(image_id) {
			console.log('Open image ' + image_id)
		},
	}
}
</script>

<style lang="scss">
.k-faq-outer-wrapper {
	position:fixed;
	z-index:200;
	top:65px;
	left:5px;
	border:5px solid $v-light-blue-lighten-1;
	background-color:#fff;
	border-radius:10px;
	display:flex;
	flex-direction:column;

	.v-treeview-node__root:hover::before {
		// display:none!important;
		opacity:0!important;
		// this kills the light grey background when you hover over tree items
	}

	.k-faq-top {
		flex:0 1 auto;
		background-color:$v-light-blue-lighten-1;
		padding-bottom:5px;
		height:90px;

		.k-faq-top-title {
			padding-left:10px;
			font-size:18px;
			font-weight:900;
			display:flex;
			align-items:center;
			cursor:move;
		}
	}

	.k-faq-main {
		flex:1 1 auto;
		height:calc(100% - 90px);
		padding:5px 0;
		background-color:$v-light-blue-lighten-5;

		.k-faq-inner-wrapper {
			height:100%;
			overflow:auto;
		}

		.v-treeview-node__root {
			min-height:36px;
			align-items:flex-start;
		}

		.v-treeview-node--leaf > .v-treeview-node__root {
			padding-left:0px;
		}

		.v-treeview-node__label {
			overflow: visible!important;
			white-space: normal!important;
		}

		.k-faq-tree-node {
			font-size:16px;
		}
		.k-faq-tree-question {
			font-size:16px;
		}
		.k-faq-tree-question.k-faq-item-open {
			font-weight:bold;
		}
		.k-faq-tree-answer {
			margin-top:-10px;
			margin-bottom:8px;
			font-size:14px;
			background-color:#fff;
			padding:12px 8px 12px 8px;
			border-radius:6px;
			cursor:default;
			p {
				margin:5px 0;
			}

			.k-faq-question-link {
				font-weight:bold;
			}

			.k-faq-image-link {
				text-decoration:none;
				color:inherit;
			}
		}

		.k-faq_item--active .v-treeview-node__label {
			color:$v-light-blue-darken-2!important;
			font-weight:bold;
		}

		.v-treeview-node__content {
			margin-left:0;
		}

		.k-faq_item {
		}

		.k-faq-tree-question.k-faq-search-match {
			background-color:$v-light-blue-lighten-3;
			border-radius:4px;
			padding:0 4px;
		}

		.k-faq-tree-answer.k-faq-search-match {
			background-color:$v-light-blue-lighten-4;
		}

		.k-faq-searched-term {
			background-color:$v-light-blue-lighten-2;
			border-radius:4px;
			padding:0 4px;
			// text-decoration:underline;
		}

		.k-faq-linked-question {
			background-color:$v-green-lighten-3;
			border-radius:4px;
			padding:0 4px;
		}

		.k-faq-linked-answer {
			background-color:$v-green-lighten-5;
		}
	}
}
</style>
