// Best viewed in Chrome

window.Sparkler = function(){
	//=============================
	// Consts
	//=============================
	let FPS = 11;	// 20;	// 10;
	const MASTER_SPARK_LENGTH = 50;	// 120;
	const SPARK_SPEED = 1;	// 14;
	const GRAVITY = 0;	// 0.6;
	const CHANCE_TO_SPARK = 0;	// 0.3;

	// added by PW
	const CENTRAL_SPARK_COUNT = 0;	// 4
	const CENTRAL_SPARK_RADIUS = 1;	// 5
	const STARTING_GLOW_SIZE = 5;	// 50
	const MAX_GLOW_SIZE = 10;		// 90
	const FLARE_UP_THRESHOLD = 0;	// 0.2

	let SPARKS = 5;	// 160;
	let SPARK_WIDTH = 1;	// 6;

	//=============================
	// Helpers
	//=============================
	const getTimestamp = () => {
		return (new Date()).getTime();
	};

	const random = (max = 1, signed = false) => {
		return signed ? ((Math.random() - 0.5) * 2) * max : Math.random() * max;
	};

	const getRGBA = (red = 0, green = 0, blue = 0, alpha = 0) => {
		return `rgba(${~~red}, ${~~green}, ${~~blue}, ${alpha})`;
	}


	//=============================
	// Main
	//=============================
	const stage = document.getElementById('sparkler-stage');
	const ctx = stage.getContext('2d');

	let SPARK_LENGTH = MASTER_SPARK_LENGTH;

	let targetDelta = 1000 / FPS;
	let previousTimestamp = getTimestamp();
	let animations = [];

	let stageWidth = $(stage).width();
	let stageHeight = $(stage).height();
	stage.width = stageWidth;
	stage.height = stageHeight;
	let stageCenterX = stageWidth / 2;
	let stageCenterY = stageHeight / 2;
	let isAwesome = false;
	let sparkler;

	let looping = true

	this.startLoop = () => {
		looping = true
		loop()
	}

	this.stopLoop = () => {
		looping = false
	}

	const loop = () => {

		if (getTimestamp() - previousTimestamp < targetDelta) {
			if (looping) requestAnimationFrame(loop);
			return;
		}

		ctx.globalCompositeOperation = 'lighten';
		ctx.clearRect(0, 0, stageWidth, stageHeight);

		// randomise flare ups
		SPARK_LENGTH = MASTER_SPARK_LENGTH * (random() + 0.1);

		// animate
		animations.forEach((animation) => {
			animation.draw();
		});

		// remove out of bounds particles
		animations = animations.filter(animation => {
			return (
				animation instanceof Sparkler ||
				(
					!animation.canRemove &&
					animation.y > 0 &&
					animation.y < stageHeight &&
					animation.x > 0 - animation.width &&
					animation.x < stageWidth + animation.width &&
					animation.alpha > 0
				)
			);
		});

		previousTimestamp = getTimestamp();
		requestAnimationFrame(loop);
	};

	class Sparkler {

		constructor(x, y) {
			this.x = x || stageCenterX;
			this.y = y || stageCenterY;
			this.glowSize = STARTING_GLOW_SIZE;

			animations.push(this);
		}

		generateSparks() {
			for (let i = 0; i < random(SPARKS) + 4; i++) {
				animations.push(new Spark({
					x: this.x,
					y: this.y
				}));
			}
		}

		renderCentralSpark() {
			ctx.beginPath();
			ctx.arc(this.x + random(5, true), this.y + random(2, true), random(1) + CENTRAL_SPARK_RADIUS, 0, Math.PI * 2, true);
			ctx.lineWidth = 5;
			ctx.fillStyle = '#ffee00';
			ctx.fill();
			ctx.closePath();
		}

		renderBackgroundGlow() {
			this.glowSize--;

			if (this.glowSize < MAX_GLOW_SIZE) {
				this.glowSize += random(15);
			}

			const gradient = ctx.createRadialGradient(this.x, this.y, this.glowSize, this.x, this.y, 0);
			const colour = getRGBA(150 + random(30), 90, 5, random(0.6) + 0.3);

			gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
			gradient.addColorStop(1, colour);
			ctx.fillStyle = gradient;
			ctx.fillRect(0, 0, stageWidth, stageHeight);
		}

		animate() {
			// normal sparks
			this.generateSparks();

			// flare up
			if (random() < FLARE_UP_THRESHOLD) {
				this.generateSparks();
				this.generateSparks();
				this.generateSparks();
			}
		}

		render() {
			// center glow
			for (var i = 0; i < CENTRAL_SPARK_COUNT; ++i) {
				this.renderCentralSpark();
				this.renderCentralSpark();
				this.renderCentralSpark();
				this.renderCentralSpark();
			}

			this.renderBackgroundGlow();
		}

		draw() {
			this.animate();
			this.render();
		}

	}

	class Spark {

		constructor({ x, y, isPop = false }) {
			this.canRemove = false;
			this.width = SPARK_WIDTH;
			this.length = isPop ? SPARK_LENGTH * (random(0.2) + 0.1) : 20 + random(SPARK_LENGTH);
			this.isPop = isPop;

			this.red = 230 + random(20);
			this.green = 100 + random(60);
			this.blue = 10;
			this.alpha = this.isPop ? 0.7 : 1;

			this.angle = random(360);
			this.speed = SPARK_SPEED + random(4);
			this.velocityX = Math.cos(this.angle) * this.speed;
			this.velocityY = Math.sin(this.angle) * this.speed;

			this.originX = x;
			this.originY = y;

			if (!isPop) {
				this.x = x - (Math.cos(this.angle) * (this.length / 2));
				this.y = y - (Math.sin(this.angle) * (this.length / 2));
			} else {
				this.x = x + random(20, true);
				this.y = y + random(14, true);
			}

			this.previousX = x;
			this.previousY = y;
			this.lineToX = this.previousX + (Math.cos(this.angle) * this.length);
			this.lineToY = this.previousY + (Math.sin(this.angle) * this.length);
		}

		animate() {
			const chanceToEnd = this.isPop ? 0.7 : 0.44;


			this.previousX = this.x;
			this.previousY = this.y;

			this.lineToX = this.previousX + (Math.cos(this.angle) * this.length);
			this.lineToY = this.previousY + (Math.sin(this.angle) * this.length) - 5 - random(10);

			//this.previousX = this.originX;
			//this.previousY = this.originY;

			this.length = this.length > 1 ? this.length * 0.97 : 1;
			this.width = random() < 0.3 ? this.width * 0.9 : this.width;

			if (!this.isPop) {
				this.velocityY += GRAVITY;
				this.x += this.velocityX;
				this.y += this.velocityY;
			} else {
				this.canRemove = true;
			}

			// end spark
			if (random() < chanceToEnd) {
				this.canRemove = true;

				if (!this.isPop && random() < CHANCE_TO_SPARK) {
					for (let i = 0; i < random(6) + 2; i++) {
						animations.push(new Spark({
							x: this.lineToX,
							y: this.lineToY,
							isPop: true
						}));
					}
				}
			}

		}

		render() {
			const colour = getRGBA(this.red, this.green, this.blue, this.alpha);

			ctx.beginPath();
			ctx.moveTo(this.previousX, this.previousY);
			ctx.lineTo(this.lineToX, this.lineToY);
			ctx.lineWidth = this.width;
			ctx.lineCap = 'round';
			ctx.strokeStyle = colour;
			ctx.stroke();

			ctx.lineWidth = this.width / 3;
			ctx.strokeStyle = getRGBA(255, 255, 255, this.alpha);
			ctx.stroke();
			ctx.closePath();
		}

		draw() {
			this.animate();
			this.render();
		}

	}

	//=============================
	// Setup
	//=============================

	const updateCanvasSize = () => {
		stageWidth = window.innerWidth;
		stageHeight = window.innerHeight;

		stage.width = stageWidth;
		stage.height = stageHeight;

		stageCenterX = stageWidth / 2;
		stageCenterY = stageHeight / 2;
	};

	// updateCanvasSize();

	$(() => {
		sparkler = new Sparkler();
	})

	//=============================
	// Run it!
	//=============================

	loop();
}
