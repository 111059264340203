<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="800" overlay-opacity="0.9" persistent scrollable>
		<v-card class="k-dark-dialog-card">
			<v-card-title style="border-bottom:1px solid #999; font-size:24px;" class="py-4"><b>Using Sparkl Activities With Your Students</b></v-card-title>
			<v-card-text class="mt-2" style="font-size:16px; line-height:21px">

				<div class="k-wizard-step" v-if="step==0">
					<div>Sparkl is designed to give teachers a great deal of flexibility in how they use activities with students. Explore your options here.</div>

					<div class="k-wizard-question">
						<v-icon color="pink accent-2" class="mr-2">fas fa-question-circle</v-icon>
						<div>Would you like to use Sparkl with another assignment system, or use Sparkl on its own?</div>
					</div>

					<div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=1">Use Sparkl with Google Classroom</v-btn></div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=2">Use Sparkl with an LMS (e.g. Canvas or Schoology)</v-btn></div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=3">Use Sparkl without a separate assignment system</v-btn></div>
					</div>
				</div>

				<div class="k-wizard-step" v-if="step==1">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=0"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Using Sparkl with Google Classroom</div>
					</div>

					<p>Each Sparkl activity is accessible from its own individual web link. </p>
					<ul class="mb-4">
						<li>Teachers open the activity link to edit the activity and alter settings for their classes.</li>
						<li>Students open the same link to work through and complete the activity.</li>
						<li>Teachers can then re-open the link to monitor and review student results.</li>
					</ul>
					<p>To add an activity to Google Classroom, all you have to do is add the activity’s link to your Google Classroom Class.</p>
					<p>Click “DONE” to close this dialog, then click the CREATE A NEW ACTIVITY button, or click an existing activity’s “tile”. This will open the activity in “teacher mode,” and you’ll see a green “Use This Activity With Your Students” button at the top of the window. Click that button for further instructions on adding the activity’s link to Google Classroom.</p>
				</div>

				<div class="k-wizard-step" v-if="step==2">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=0"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Using Sparkl with an LMS (e.g. Canvas or Schoology)</div>
					</div>

					<p>Sparkl can be set up as an LTI tool with most LMSs (including Canvas and Schoology). This allows students and teachers to launch Sparkl activities from the LMS without requiring a separate <b>sign-in</b>, and also allows for <b>automated grade pass-back</b> from Sparkl to the LMS. But Sparkl can also be used without LTI if you’re not able to set this up.</p>

					<div class="k-wizard-question">
						<v-icon color="pink accent-1" class="mr-2">fas fa-question-circle</v-icon>
						<div>Would you like to try to set Sparkl up as an LTI tool with your LMS?</div>
					</div>

					<div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=5">Use Sparkl in my LMS <b class="px-1">WITH</b> LTI</v-btn></div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=4">Use Sparkl in my LMS <b class="px-1">WITHOUT</b> LTI</v-btn></div>
					</div>
				</div>

				<div class="k-wizard-step" v-if="step==3">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=0"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Using Sparkl without a separate assignment system</div>
					</div>

					<p>Each Sparkl activity is accessible from its own individual web link.</p>
					<ul class="mb-4">
						<li>Teachers open the activity link to edit the activity and alter settings for their classes.</li>
						<li>Students open the same link to work through and complete the activity.</li>
						<li>Teachers can then re-open the link to monitor and review student results.</li>
					</ul>
					<p>To use an activity with your class, all you have to do is distribute the link to students.</p>
					<p>Click “DONE” to close this dialog, then click the CREATE A NEW ACTIVITY button, or click an existing activity’s “tile”. This will open the activity in “teacher mode,” and you’ll see a green “Use This Activity With Your Students” button at the top of the window. Click that button for further instructions on distributing the link.</p>
				</div>

				<div class="k-wizard-step" v-if="step==4">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=2"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Using Sparkl in an LMS WITHOUT LTI</div>
					</div>

					<p>Each Sparkl activity is accessible from its own individual web link.</p>
					<ul class="mb-4">
						<li>Teachers open the activity link to edit the activity and alter settings for their classes.</li>
						<li>Students open the same link to work through and complete the activity.</li>
						<li>Teachers can then re-open the link to monitor and review student results.</li>
					</ul>
					<p>If you can’t set Sparkl up as an LTI tool, you can simply create an assignment in your LMS and add the Sparkl activity link to the assignment.</p>
					<p>Click “DONE” to close this dialog, then click the CREATE A NEW ACTIVITY button, or click an existing activity’s “tile”. This will open the activity in “teacher mode,” and you’ll see a green “Use This Activity With Your Students” button at the top of the window. Click that button for further instructions on adding the activity’s link to your LMS.</p>
				</div>

				<div class="k-wizard-step" v-if="step==5">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=2"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Using Sparkl in an LMS WITH LTI</div>
					</div>

					<p>To use Sparkl as an LTI tool, you first need to set up the LTI connection from your LMS to Sparkl (this only needs to be done once). You can then you add one or more Sparkl activities as assignments in your LMS course.</p>

					<p>Each Sparkl activity is then accessible from its assignment link in your LMS:</p>
					<ul class="mb-4">
						<li>Teachers open the activity link to edit the activity and alter settings for their classes.</li>
						<li>Students open the same link to work through and complete the activity. If you set up the assignment with a column in the LMS gradebook, students’ grades will automatically flow into the gradebook as they work on and complete the assignment.</li>
						<li>Teachers can then re-open the link to monitor and review student results.</li>
					</ul>

					<p>If you use the Canvas LMS, click the button below for detailed instructions on setting Sparkl up as an LTI tool and adding Sparkl assignments to your Canvas course. If you use another LMS, please send an email to <a href="mailto:contact@commongoodlt.com?subject=Sparkl%20LTI%20request">contact@commongoodlt.com</a> for assistance.</p>

					<div>
						<div class="my-3 mx-auto" style="width:450px"><v-btn class="k-tight-btn k-nocaps-btn k-nobold-btn" block dark color="pink darken-4" @click="step=6">Configure Canvas to use Sparkl as an LTI Tool</v-btn></div>
					</div>
				</div>

				<div class="k-wizard-step" v-if="step==6">
					<div class="k-wizard-step-title">
						<v-btn icon @click="step=2"><v-icon large>fas fa-circle-arrow-left</v-icon></v-btn>
						<div class="ml-3">Configure Canvas to use Sparkl as an LTI Tool</div>
					</div>

					<p>To install Sparkl as an LTI tool in your Canvas course, follow these instructions. You only have to do this once for each Canvas course you’re using (and if your district administrator is willing to install the Sparkl tool at the district level, teachers won’t have to do it at all!).</p>
					<ol>
						<li>Log into your Canvas course and select <b>Settings</b> in the left sidebar menu.</li>
						<li>In the Settings window, click the <b>Apps</b> tab.</li>
						<li>Click the <b>View App Configurations</b> button</li>
						<li>Click <b><i class="fas fa-plus"></i> App</b></li>
						<li>You should now see an “Add App” form. Enter the following information in this form:
							<ul>
							<li><b>Configuration Type:</b> Manual Entry</li>
							<li><b>Name:</b> Sparkl</li>
							<li><b>Consumer Key:</b> *Specific to your district—send an email to <a href="mailto:contact@commongoodlt.com?subject=Canvas%20key%20and%20secret%20request"><b>contact@commongoodlt.com</b></a> for the value to use for the key and secret</li>
							<li><b>Consumer Secret:</b> *Specific to your district</li>
							<li><b>Launch Url:</b> <a href="https://sparkl-ed.com" target="_blank" rel="nofollow noopener noreferrer">https://sparkl-ed.com</a></li>
							<li><b>Domain:</b> sparkl-ed.com</li>
							<li><b>Privacy:</b> Public</li>
							</ul>
						</li>
						<li>Click the <b>Submit</b> button at the bottom of the form and you should be all set.</li>
					</ol>
					<p class="mt-3">Now that the tool is installed, follow these instructions to create Sparkl assignments for your students:</p>
					<ol>
						<li>Click the <b>Assignments</b> tab. (You can also add Sparkl activities to Canvas “modules”.)</li>
						<li>Click the <b><i class="fas fa-plus"></i> Assignment</b> button.</li>
						<li>Enter an <b>Assignment Name</b>.</li>
						<li>If you want student grades for the Sparkl activity to be sent to your Canvas gradebook, enter a value in the <b>Points</b> field, and set any other gradebook settings you wish.</li>
						<li>In the <b>Submission Type</b> drop-down menu, click the <b>External Tool</b> option.</li>
						<li>In the <b>Submission Type</b> box, click the <b>Find</b> button.</li>
						<li>In the <b>Submission Type</b> box, we recommend you choose the <b>Load This Tool In A New Tab</b> option.</li>
						<li>In the “Configure External Tool” interface, click the <b>Sparkl</b> option and then click the <b>Select</b> button.</li>
						<li>Scroll to the bottom of the Create New Assignment page and click the <b>Save</b> or <b>Save &amp; Publish</b> button.</li>
						<li>Canvas should now process and save your new assignment, after which you should see a Canvas screen with the assignment title at the top and a button labeled <b>Load [Assignment Name] in a new window</b>. Click this button, then you should then see the Sparkl Portal interface (the same interface you’re currently viewing), from which you can create a new Sparkl activity or choose an existing activity to use with your students.</li>
					</ol>
					<p class="mt-3">Once your Sparkl assignment is set up, students access it from Canvas the same way they access any other assignment. As each student completes a Sparkl activity, their grade (calculated as the number of stars earned divided by the total number of stars available for the activity) will be constantly updated in the Canvas gradebook.</p>
				</div>
			</v-card-text>
			<v-card-actions class="pa-3 pt-0">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		step:0,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
}
</script>

<style lang="scss">
.k-wizard-step {
	// font-size:20px;
	// line-height:26px;
}

.k-wizard-step-title {
	font-weight:bold;
	font-size:20px;
	margin-bottom:12px;
	margin-top:4px;
	display:flex;
	align-items:center;
}

.k-wizard-question {
	display:flex;
	align-items:flex-start;
	color:$v-pink-accent-1;
	padding-top:8px;
	margin-top:16px;
	border-top:$v-pink-accent-1 1px solid;
}

///////////////////////////////
// default colors are for dark mode; set here for light mode
.theme--light {
	.k-wizard-question {
		color:$v-pink-accent-4;
		border-top-color:$v-pink-accent-4;
	}
}
</style>
