<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container fill-height>
		<!-- <div class="k-background-stars-holder" style="background-color:#000">
			<div class="k-background-stars"></div>
			<div class="k-background-stars2"></div>
			<div class="k-background-stars3"></div>
			<div class="k-background-stars4"></div>
			<div class="k-background-stars5"></div>
		</div> -->
		<!-- <div style="position:absolute; left:0; top:0; width:100%; height:100%;"><canvas style="width:100%; height:100%;" id="warpspeed_canvas"/></div> -->
		<div class="k-login_inner">

			<div v-if="activity_title">
				<!-- <div class="k-login-activity-header">{{app_noun}} Activity</div> -->
				<div class="k-login-activity-title" v-html="activity_title"></div>
			</div>
			<div v-if="featured_collection_title">
				<div class="k-login-featured-collection-title" v-html="featured_collection_title"></div>
			</div>

			<div v-show="is_portal_link && !activity_id" class="k-login-intro-to-sparkl">
				<div v-if="!activity_title">
					<div class="k-login-welcome">Welcome to <div @click="toggle_sparkler" class="k-login-welcome-logo-holder"><Logo ref="logo"></Logo></div></div>
				</div>

				<div class="text-center my-2">
					<v-btn large color="primary" class="ma-2" style="width:300px" @click="show_about_dialog"><v-icon class="mr-2">fas fa-info-circle</v-icon>Learn More About {{app_noun}}</v-btn>
					<v-btn large color="primary" class="ma-2" style="width:300px" @click="show_demo_activities">View Demo Activities <v-icon class="ml-2">fas fa-arrow-alt-circle-right</v-icon></v-btn>
				</div>
			</div>

			<!-- allow one-tap signin if the activity is not in no_sign_in mode -->
			<div v-if="student_access_policy=='no_sign_in'&&no_sign_in_mode_role=='teacher'">
				<v-icon class="mr-2" style="margin-top:-8px">fas fa-door-closed</v-icon><i>TEACHERS, please sign in with Google</i>
				<div v-if="site_config.flavor=='velocity'" class="mt-2"><i>Or access the activity from <v-btn small class="ml-1" color="primary" href="https://inspire.gadoe.org">Inspire <v-icon small class="ml-2">fas fa-circle-arrow-right</v-icon></v-btn></i></div>
			</div>
			<div v-if="student_access_policy!='no_sign_in'" class="k-google-one-tap-holder" id="google_one_tap_holder"></div>
			<div v-show="student_access_policy!='no_sign_in'||no_sign_in_mode_role=='teacher'" class="k-google-sign-in-btn" id="google_sign_in_btn"></div>
			<div class="flex-column" v-show="student_access_policy!='no_sign_in'||no_sign_in_mode_role=='teacher'">
				<div class="k-google-sign-in-btn" id="google_sign_in_btn"></div>
				<v-btn v-if="site_config.allow_msft_login=='yes'" class="mt-2 k-nocaps-btn k-tight-btn" large color="#555" dark @click="msft_sign_in" style="width:364px; height:50px; border-radius: 4px; position:relative;">
					<img src="../../../images/msftlogo.png" alt="Microsoft Logo" style="width:42px; height:42px; border-radius:3px; left:-16px; top:-11px; position:absolute;"/>
					<div style="padding-left:46px; font-size:18px;">Sign in with Microsoft (BETA)</div>
				</v-btn>
			</div>
			<div v-if="student_access_policy=='demo_available'" class="k-login-demo-mode-no-sign-in">Or <v-btn small color="primary" class="mx-2" @click="launch_without_signing_in">Click Here</v-btn> to view the activity without signing in.</div><!-- this student_access_policy is no longer supported -->

			<div v-if="student_access_policy=='no_sign_in'" class="k-name-only-sign-in">
				<div v-if="no_sign_in_mode_role=='student'">
					<div><v-icon class="mr-2" style="margin-top:-8px">fas fa-door-open</v-icon><i>STUDENTS, sign in with your <nobr>first and last name:</nobr></i></div>
					<div class="mx-auto d-flex flex-wrap align-center" style="max-width:500px">
						<v-text-field class="mt-4" ref="sign_in_name_input" outlined rounded hide-details @keypress="sign_in_name_check_enter" v-model="sign_in_name" placeholder="First and last name"></v-text-field>
						<div class="mx-3 mt-4 text-center" :style="$vuetify.breakpoint.xsOnly?'width:100%':''"><v-btn large color="primary" @click="sign_in_with_name">Sign In <v-icon class="ml-2">fas fa-right-to-bracket</v-icon></v-btn></div>
					</div>
				</div>

				<div class="mt-10">
					<v-btn small color="secondary" @click="no_sign_in_mode_role=(no_sign_in_mode_role=='teacher')?'student':'teacher'"><v-icon small class="mr-2" :style="(no_sign_in_mode_role=='student')?'transform:rotate(360deg)':'transform:rotate(0deg)'">fas fa-shuffle</v-icon>Show {{no_sign_in_mode_role=='teacher'?'Student':'Teacher'}} Sign-In</v-btn>
				</div>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import Logo from '@/../../app-common/components/Logo'
// import '@/../../app-common/js/warpspeed.js'

export default {
	components: { Logo },
	data() { return {
	}},
	computed: {
		...mapState(['activity_title', 'student_access_policy', 'featured_collection_title', 'site_config']),
		...mapGetters(['app_noun']),
		is_portal_link() {
			// these conditionals are pulled from store.js
			if (window.location.search.search(/\ba=(\d+)/) > -1) {
				return false
			} else if (window.location.pathname.search(/^\/(\d+)\b/) > -1) {
				return false
			}
			return true
		},
		sign_in_name: {
			get() { return this.$store.state.lst.sign_in_name },
			set(val) { this.$store.commit('lst_set', ['sign_in_name', val]) }
		},
		no_sign_in_mode_role: {
			get() { return this.$store.state.lst.no_sign_in_mode_role },
			set(val) { this.$store.commit('lst_set', ['no_sign_in_mode_role', val]) }
		},
		activity_id() { return vapp.activity_id },
		// sign_in_mode() {
		// 	// allow the user to launch *without* signing in if student_access_policy == 'no_sign_in'...
		// 	if (this.student_access_policy == 'no_sign_in') {
		// 		// AND we're launching in demo mode. If we're not in demo mode, we don't want to encourage students to open without signing in
		// 		if (window.location.search.search(/\bdemo\b/) > -1 || window.location.pathname.search(/\bdemo\b/) > -1) {
		// 			return true
		// 		}
		// 	}
		// 	return false
		// }
		// allow_demo_launch() {
		// 	// allow the user to launch *without* signing in if student_access_policy == 'no_sign_in'
		// 	return (this.student_access_policy == 'no_sign_in')
		// }
	},
	created() {

	},
	mounted() {
		// new window.WarpSpeed('warpspeed_canvas', {"speed":0.7,"speedAdjFactor":0.03,"density":0.7,"shape":"circle","warpEffect":false,"depthFade":false,"starSize":5,"backgroundColor":"#000","starColor":"#FFFFFF"})

		// if we're showing the logo on this page, toggle on the sparkler
		if (this.$refs.logo) this.$refs.logo.toggle_sparkler(true)

		// focus in the name text input if this is an open-door activity
		if (this.student_access_policy == 'no_sign_in' && this.$refs.sign_in_name_input) $(this.$refs.sign_in_name_input.$el).find('input').focus().select()

		// this.initialize()
	},
	methods: {
		toggle_sparkler() {
			this.$refs.logo.toggle_sparkler()
		},

		sign_in_with_name() {
			if (empty(this.sign_in_name) || !this.sign_in_name.includes(' ')) {
				this.$alert('Please enter your first and last name to sign in.')
				return
			}

			// replace multiple spaces with single spaces
			this.sign_in_name = this.sign_in_name.replace(/\s+/g, ' ')

			// make sure the name includes at least 4 letters, because we use just the letters to create the fake email for the account
			let condensed_sign_in_name = this.sign_in_name.toLowerCase().replace(/\W/g, '')
			if (condensed_sign_in_name.length < 4) {
				this.$alert('Please enter a longer name.')
				return
			}

			// pass sign_in_name back to initialize_portal service, which will sign the user in
			vapp.initialize_app({sign_in_name: this.sign_in_name})
		},

		sign_in_name_check_enter(evt) {
			if (evt.keyCode == 13) this.sign_in_with_name()
		},

		show_demo_activities() {
			vapp.initialize_app({show_demo_activities:'true'}, true)
		},

		show_about_dialog() {
			vapp.show_about_dialog()
		},

		launch_without_signing_in() {
			vapp.initialize_app({launch_without_signing_in: 'yes'})
		},
		msft_sign_in() {
			const original_url = window.location.href;
			window.document.location.replace('/src/msftoidclogin.php?original_url=' + encodeURIComponent(original_url));
		}
	}
}
</script>

<style lang="scss">
.k-login_inner {
	flex:1 1 100%;
	margin:0 auto 120px auto;
	// max-width:90%;
	text-align:center;
	position:relative;
	/*.k-google-sign-in-btn {
		cursor:pointer;
		margin:auto;
		width:350px;
		max-width:calc(100vw - 60px);
	}*/
}

.k-google-sign-in-btn {
	width:280px;
	margin: auto;
	padding-top:20px;
	transform: scale(1.3);
}

.k-google-one-tap-holder {
	position:absolute;
	margin-top:-16px;
	left:calc(50% - 196px);
}

.k-name-only-sign-in {
	input {
		font-size:22px;
		text-align:center;
		font-weight:900;
	}
}

.k-login-demo-mode-no-sign-in {
	max-width:500px;
	margin:54px auto 0px auto;
	border-top:1px solid #666;
	padding-top:20px;
}

@keyframes login-bgdcolorchange {
	0%   {border-color: $v-green-lighten-1;}
	20%  {border-color: $v-blue-lighten-1;}
	40%  {border-color: $v-purple-lighten-1;}
	60%  {border-color: $v-orange-lighten-1;}
	80%  {border-color: $v-red-lighten-1;}
	100% {border-color: $v-green-lighten-1;}
}
$login-background-animation: login-bgdcolorchange 20s infinite;

@keyframes login-welcomecolorchange {
	0%   {color: $v-green-lighten-1;}
	20%  {color: $v-blue-lighten-1;}
	40%  {color: $v-purple-lighten-1;}
	60%  {color: $v-orange-lighten-1;}
	80%  {color: $v-red-lighten-1;}
	100% {color: $v-green-lighten-1;}
}
$login-welcome-color-animation: login-welcomecolorchange 20s infinite;

.k-login-activity-title {
	font-size:36px;
	line-height:48px;
	font-weight:900;
	margin-bottom:30px;
	margin-top:12px;
	// color:$v-light-blue-lighten-2;
	// animation:$login-welcome-color-animation;
}

@media (max-width: 760px) {
	.k-login-activity-title {
		font-size:24px;
		line-height:32px;
	}
	.k-name-only-sign-in {
		font-size:16px;
	}
}

.k-login-featured-collection-title {
	font-size:44px;
	font-weight:900;
	margin-bottom:24px;
}

.k-login-welcome {
	font-size:36px;
	font-weight:900;
	margin-bottom:12px;
	// animation:$login-welcome-color-animation;
}

.k-login-welcome-logo-holder {
	display:inline-block;
	position:relative;
	top:1px;
}

.k-login-welcome-subhead {
	font-size:20px;
	font-weight:900;
	color:#ddd;
	margin-bottom:30px;
}

.k-login-intro-to-sparkl {
	// animation: $login-background-animation;
	background:#111;
	color:#fff;
	border:1px solid $v-blue-lighten-1;
	max-width:740px;
	margin:0px auto 30px auto;
	text-align:center;
	font-size:20px;
	padding:16px;
	border-radius:15px;
}

// the animated border takes significant processor resources
.k-login-intro-to-sparkl-no-animation {
	animation:none;
}

///////////////////////////////
// default colors are for dark mode; set here for light mode
.theme--light {
	.k-login-intro-to-sparkl {
		background:#ddd;
		color:#000;
	}
}
</style>
