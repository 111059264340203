// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams
class Activity {
	constructor(data) {
		if (empty(data)) data = {}

		sdp(this, data, 'activity_id', 0)
		sdp(this, data, 'login_count', 0)
		sdp(this, data, 'login_at', '')
		sdp(this, data, 'created_at', '')
		sdp(this, data, 'updated_at', '')

		// we need to re-save activities to get their stars_available to come up properly...
		this.stars_available_computed = !empty(data.stars_available)
		sdp(this, data, 'stars_available', 0)

		sdp(this, data, 'title', 'Untitled Activity')
		sdp(this, data, 'activity_instructions', '')
		if (data.activity_description && !data.activity_instructions) this.activity_instructions = data.activity_description	// translate from old activity_description to activity_instructions
		sdp(this, data, 'mappings', [])
		sdp(this, data, 'editors', ',')
		sdp(this, data, 'student_access_policy', 'signed_in', ['signed_in', 'no_sign_in', 'demo_available'])
		sdp(this, data, 'duplicate_of', '')

		// teacher data...
		// initialize_portal will send in 1 for 'mine' if the user is an editor for the activity
		sdp(this, data, 'mine', 0)
		// result count could be empty if we didn't try to look up a result, or a number if we did look it up; if it's empty, set to -1
		if (empty(data.result_count)) this.result_count = -1
		else sdp(this, data, 'result_count', 0)

		// student data
		this.result_data = {}
		if (data.result_data) {
			sdp(this.result_data, data.result_data, 'result_id', 0)
			sdp(this.result_data, data.result_data, 'grade', 0)
			sdp(this.result_data, data.result_data, 'created_at', '')
			sdp(this.result_data, data.result_data, 'updated_at', '')
			sdp(this.result_data, data.result_data, 'login_at', '')
			sdp(this.result_data, data.result_data, 'login_count', 0)
		}
	}

	user_is_editor() {
		return this.editors.toLowerCase().indexOf(',' + vapp.user_info.email.toLowerCase() + ',') > -1
	}

	has_result_data() {
		return this.result_data.result_id > 0
	}

	record_for_save() {
		return {
			activity_id: this.activity_id,
			title: this.title
		}
	}
}
window.Activity = Activity
