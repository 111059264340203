// Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams

// https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Objects/Inheritance

class Collection {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'archived', 0)
		sdp(this, data, 'collection_id', -1)
		sdp(this, data, 'access_code', '')
		sdp(this, data, 'title', '')
		sdp(this, data, 'organization_id', '')
		sdp(this, data, 'lti_context_id', '')	// if this is non-empty, it means the group is connected to an LTI context
		sdp(this, data, 'lti_section_sourcedId', 0)	// groups created from sis sections will have this set to the sis sourcedId
		sdp(this, data, 'type', 'student', ['teacher', 'student', 'allaccess-s'])
		sdp(this, data, 'creator_displayname', '')	// may be empty for LTI collections

		// for teacher collections, restricted-true means other teachers must enter the access code to subscribe;
		// for student collections, restricted-true means if it's an LTI collection, students must enter a *different* collection's access code to access the activity
		// set as yes/no in the db; translate these values to true/false
		if (data.restricted == 'yes') this.restricted = true
		else if (data.restricted == 'no') this.restricted = false
		else sdp(this, data, 'restricted', false)

		this.users = []
		if (data.users) {
			for (let user_id in data.users) {
				this.add_user(data.users[user_id])
			}
		}
		this.sort_users()

		this.activities = []
		if (data.activities) {
			let has_non_zero_sequences = false
			for (let activity_id in data.activities) {
				let activity = data.activities[activity_id]
				let o = {}
				sdp(o, activity, 'activity_id', 0),
				sdp(o, activity, 'title', ''),
				sdp(o, activity, 'activity_settings', {})
				sdp(o, activity, 'collection_sequence', 0)
				this.activities.push(o)
				if (o.collection_sequence != 0) has_non_zero_sequences = true
			}

			// if collection sequences are all 0, put them in alphabetical order and assign new sequences
			if (!has_non_zero_sequences) {
				this.activities.sort((a,b) => {
					if (a.title < b.title) return -1
					if (a.title > b.title) return 1
					return 0
				})

				for (let i = 0; i < this.activities.length; ++i) {
					this.activities[i].collection_sequence = i
				}
			} else {
				// else sort by collection_sequence
				this.activities.sort((a,b) => (a.collection_sequence-b.collection_sequence))
			}
		}
	}

	add_user(data) {
		let o = {}
		sdp(o, data, 'user_id', ''),
		sdp(o, data, 'email', ''),
		sdp(o, data, 'name_given', ''),
		sdp(o, data, 'name_family', ''),
		sdp(o, data, 'sparkl_role', 0),
		sdp(o, data, 'collection_role', 'subscriber', ['editor', 'subscriber']),
		this.users.push(o)
	}

	sort_users() {
		this.users.sort((a,b)=>{
			// signed-in user goes first
			if (a.user_id == vapp.user_info.user_id) return -1
			if (b.user_id == vapp.user_info.user_id) return 1

			// editors come before subscribers
			if (a.collection_role < b.collection_role) return -1
			if (b.collection_role < a.collection_role) return 1

			// then sort by name_given and name_family
			let gs = U.natural_sort(a.name_given, b.name_given)
			if (gs != 0) return gs

			let fs = U.natural_sort(a.name_family, b.name_family)
			if (fs != 0) return gs

			return 0
		})
	}

	is_lti_context_group() {
		return !empty(this.organization_id) && !empty(this.lti_context_id)
	}

	is_default_group() {
		// archived default classes start with 'A'
		return (this.access_code.search(/^(A?)T\d+$/) > -1)
	}

	is_archived_default_group() {
		return (this.access_code.search(/^AT\d+$/) > -1)
	}

	subscriber_count() {
		// count the number of subscribers (so not including editors) to the collection
		let ct = 0
		for (let i = 0; i < this.users.length; ++i) {
			if (this.users[i].collection_role == 'subscriber') ++ ct
		}
		return ct
	}

	includes_activity(activity_id) {
		// if activity_id isn't provided, look at vapp.ad.activity_id
		if (empty(activity_id)) activity_id = vapp.ad.activity_id

		return this.activities.findIndex(x=>x.activity_id == activity_id) > -1
	}

	includes_user(user_id) {
		// if user_id isn't provided, look at vapp.user_info.user_id
		if (empty(user_id)) user_id = vapp.user_info.user_id

		return this.users.findIndex(x=>x.user_id == user_id) > -1
	}

	user_is_editor(user_id) {
		// if user_id isn't provided, look at vapp.user_info.user_id
		if (empty(user_id)) user_id = vapp.user_info.user_id

		let user = this.users.find(x=>x.user_id == user_id)
		return user && user.collection_role == 'editor'
	}
}
window.Collection = Collection

// this is for the studentapp, where we receive a limited amount of data for each of the student's teacher's collections
class Student_Group {
	constructor(data) {
		if (empty(data)) data = {}
		sdp(this, data, 'collection_id', -1)
		sdp(this, data, 'access_code', '')
		sdp(this, data, 'title', '')
		sdp(this, data, 'organization_id', '')
		sdp(this, data, 'lti_context_id', '')	// if this is non-empty, it means the group is connected to an LTI context
		sdp(this, data, 'activity_included', false)
		sdp(this, data, 'student_subscribed', false)
		if (data.student_group_activity_settings && typeof(data.student_group_activity_settings) == 'string') {
			this.student_group_activity_settings = JSON.parse(data.student_group_activity_settings)
		} else {
			sdp(this, data, 'student_group_activity_settings', null)
		}
	}

	is_teacher_default_group() {
		return (this.access_code.search(/^T\d+/) > -1)
	}

	is_lti_context_group() {
		return !empty(this.organization_id) && !empty(this.lti_context_id)
	}
}
window.Student_Group = Student_Group
