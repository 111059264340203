<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template>
	<v-dialog v-model="dialog_open" max-width="800" persistent scrollable>
		<v-card style="border:1px solid #999">
			<v-card-title style="border-bottom:1px solid #999">
				<b>Satchel Chooser Demo</b>
				<v-spacer/>
				<v-btn small color="primary" @click="satchel_small_frame=!satchel_small_frame">Frame Size: {{satchel_small_frame?'SMALL':'LARGE'}}</v-btn>
			</v-card-title>
			<v-card-text class="mt-3" style="font-size:16px">
				<!-- <v-select v-model="framework_identifier" :items="frameworks_list" label="Choose a framework" dense outlined hide-details></v-select> -->
				<ul>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go(null)">Browse standards; no starting framework specified</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'03e26f3e-b2f6-11e9-b654-0242ac150005'})">Browse NGSS standards</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'03e26f3e-b2f6-11e9-b654-0242ac150005',item_identifier:'03e2a7ce-b2f6-11e9-8348-0242ac150005'})">Browse NGSS Grade 5 standards</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'03e26f3e-b2f6-11e9-b654-0242ac150005',item_identifier:'03e2ac10-b2f6-11e9-9392-0242ac150005'})">Browse NGSS; jump right to standard 5-PS3-1</v-btn></li>
					<li class="mt-4"><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({chooser_mode:'multiple'})">Align to standard; no starting framework specified</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'e9dd7229-3558-4df2-85c6-57b8938f6180',chooser_mode:'multiple'})">Align to GA Math</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'e9dd7229-3558-4df2-85c6-57b8938f6180',item_identifier:'799d746d-c2a9-44e2-a4ee-2cddf9751ac3',chooser_mode:'multiple'})">Align to GA Math / Calculus</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'a446e74c-463e-11e7-94f5-b49cee8b2d8c',chooser_mode:'multiple',search_terms:'The Fall of the Soviet Union'})">Align to GA Social Studies; send resource title</v-btn></li>
					<li><v-btn text class="k-tight-btn k-nocaps-btn" color="indigo accent-1" @click="go({framework_identifier:'a446e74c-463e-11e7-94f5-b49cee8b2d8c',item_identifier:'32e00df2-4640-11e7-b2af-99cb6f061ee1',chooser_mode:'multiple',search_terms:'The Fall of the Soviet Union'})">Align GA SS 5th grade; send resource title</v-btn></li>
				</ul>
				<div v-if="aligned_item" class="mt-3 pa-2" :class="aligned_item_flashing?'indigo darken-4':''" style="border-radius:8px"><b v-if="aligned_item.cfitem.humanCodingScheme" v-html="aligned_item.cfitem.humanCodingScheme" class="mr-2"></b><span v-html="aligned_item.cfitem.fullStatement"></span><br><v-btn small class="mt-2" light color="indigo accent-1" @click="go({framework_identifier:aligned_item.framework_identifier,item_identifier:aligned_item.cfitem.identifier})">Open aligned standard</v-btn></div>
			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #999">
				<v-spacer></v-spacer>
				<v-btn color="primary" @click="$emit('dialog_cancel')">Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	props: {
		// req: { type: String, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		dialog_open: true,
		aligned_item: null,
		aligned_item_flashing: false,
	}},
	computed: {
		...mapState([]),
		...mapGetters([]),
		satchel_small_frame: {
			get() { return vapp.satchel_small_frame },
			set(val) { vapp.satchel_small_frame = val },
		}
	},
	watch: {
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_frame_size() {
			this.$parent.satchel_small_frame = !this.$parent.satchel_small_frame
		},

		go(params) {
			// if all we have to do is show Satchel, we just have to execute the show cmd; 
			// the last-loaded framework will be re-loaded, or the user will be prompted to choose a framework
			if (!params) {
				vapp.$refs.satchel.execute('show')
				return
			}

			if (!params) params = {}

			// always start by showing the framework
			vapp.$refs.satchel.execute('show').then(()=>{
				// then call load_framework, sending in framework and item identifiers (which could be null)
				vapp.$refs.satchel.execute('load_framework', {framework_identifier: params.framework_identifier, item_identifier: params.item_identifier}).then(()=>{
					// then call chooser, sending in chooser_mode, which could be 'single', 'multiple', or falsey
					// note that we *should* always execute this with chooser_mode when we open a framework, 
					// in case the user previously opened to align something, didn't complete the alignment (and closed the window), then re-opened later
					vapp.$refs.satchel.execute('chooser', {chooser_mode: params.chooser_mode}).then((aligned_item) => {
						// this is the callback fn for chooser mode
						// return value (aligned_item) should include framework_identifier and cfitem

						// set aligned_item, then hide the chooser
						this.aligned_item = aligned_item
						vapp.$refs.satchel.execute('hide')

						// flash the aligned item
						setTimeout(()=>this.aligned_item_flashing = true, 100)
						setTimeout(()=>this.aligned_item_flashing = false, 600)
						setTimeout(()=>this.aligned_item_flashing = true, 1100)
						setTimeout(()=>this.aligned_item_flashing = false, 1600)
					})

					if (params.search_terms) {
						vapp.$refs.satchel.execute('search', {search_terms: params.search_terms, limit_to: params.item_identifier})
					}
				})
			})
		}
	}
}
</script>

<style lang="scss">
</style>