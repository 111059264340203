<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
<div class="k-portal-collection" :class="ac_show_all?'k-portal-collection-maximized':'k-portal-collection-minimized'">
	<v-hover v-slot:default="{hover}"><div>
		<div class="k-portal-collection-activities-outer-wrapper">
			<div class="k-portal-collection-header">
				<v-btn fab x-small :color="collection_type_color" class="elevation-0" light @click="toggle_all_activities()"><v-icon>fas {{ac_show_all?'fa-angle-down':'fa-angle-right'}}</v-icon></v-btn>
				<v-btn v-visible="hover&&collection_editable" class="k-portal-collection-header-editor-btn" small icon color="#999" style="margin:0 2px" @click="$emit('edit_collection')"><v-icon small>fas fa-edit</v-icon></v-btn>
				<a class="k-portal-collection-header-title" @click="toggle_all_activities()"><b>{{collection.title}}</b> <nobr v-if="collection.type=='student'&&collection.creator_displayname" style="font-size:0.9em">({{collection.creator_displayname}})</nobr></a>
			</div>
			<div class="k-portal-activities-activities-wrapper-outer">
				<div class="k-portal-collection--left" v-show="!ac_show_all" v-visible="hover&&show_scroll_activities.left"><v-spacer/><v-btn @click="scroll_activities('left',collection_obj.uuid)" fab x-small color="primary" dark><v-icon>fas fa-angle-left</v-icon></v-btn><v-spacer/></div>
				<div class="k-portal-activities-activities-wrapper" :id="'activities_wrapper_'+collection_obj.uuid" @scroll="++scroll_updater" :class="ac_show_all?'k-portal-activities-activities-wrapper--show-all':''">
					<div v-if="collection_obj.activities.length==0" class="ma-2"><i>{{collection.activities.length>0?'No activities in this collection match your search criteria':'This collection does not include any activities'}}</i></div>
					<div v-for="activity in collection_obj.activities" :key="collection.collection_id+'-'+activity.activity_id" class="k-portal-activities-activity-wrapper">
						<Activity :activity="activity" :ac_show_all="ac_show_all" />
					</div>
					<div v-if="collection_obj.has_hidden_activities" style="flex:0 0 140px; font-size:12px; line-height:15px; text-align:center; margin-left:12px"><i>Showing {{collection_obj.activities.length}} of {{collection_obj.total_activity_count}} activities; {{collection_obj.filtering_by_search_terms?'modify':'enter'}} search terms to reveal other activities.</i></div>
					<v-spacer/>
				</div>
				<div class="k-portal-collection--right" v-show="!ac_show_all" v-visible="hover&&show_scroll_activities.right"><v-spacer/><v-btn @click="scroll_activities('right',collection_obj.uuid)" fab x-small color="primary" dark><v-icon>fas fa-angle-right</v-icon></v-btn><v-spacer/></div>
			</div>
		</div>
	</div></v-hover>

</div>
</template>

<script>
import goTo from 'vuetify/lib/services/goto'
import { mapState, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import Activity from './Activity'

export default {
	components: { draggable, Activity},
	props: {
		collection_obj: { type: Object, required: true },
		// nreq: { type: String, required: false, default() { return ''} },
	},
	data() { return {
		scroll_updater: 0,
		ac_show_all: false,
	}},
	computed: {
		...mapState(['user_is_instructor', 'featured_collection_id', 'user_info']),
		...mapGetters(['signed_in']),
		collection() { return this.collection_obj.collection },
		collection_type_color() {
			if (!this.user_is_instructor) {
				return '#ccc'
			} else if (this.collection.type == 'student') {
				if (this.collection.is_lti_context_group()) return 'pink'
				else if (this.collection.collection_id == -2) return '#999'
				else return 'purple'
			} else {
				return 'orange'
			}
		},
		show_scroll_activities() {
			if (this.scroll_updater < 0) return {}

			let jq = $('#activities_wrapper_' + this.collection_obj.uuid)
			let val = jq.scrollLeft()
			let w = jq.width()
			let length = jq.find('.k-portal-activities-activity-wrapper').length * jq.find('.k-portal-activities-activity-wrapper').outerWidth()
			return {
				left: val > 0,
				right: val + w < length
			}
		},
		collection_editable() {
			if (this.collection.collection_id == -2) return false
			if (!this.user_is_instructor) return false
			if (!this.collection.user_is_editor()) return false
			return true
		},
	},
	watch: {
		'collection_obj.expand_trigger': {
			immediate: true, handler(val) {
				this.toggle_all_activities(this.collection_obj.initially_expanded)
			}
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		toggle_all_activities(new_val) {
			if (new_val !== true && new_val !== false) new_val = !this.ac_show_all
			this.ac_show_all = new_val
		},

		scroll_activities(direction) {
			let jq = $('#activities_wrapper_' + this.collection_obj.uuid)
			let val = jq.scrollLeft()
			let w = jq.width() - 200

			if (direction == 'left') val -= w
			else val += w

			jq.animate({scrollLeft: val}, 400)
		},
	}
}
</script>

<style lang="scss">
.k-portal-collection {
	// margin-top:12px;
	// position:relative;
	border:1px solid transparent;
	background-color:#111;
	border-radius:16px;
	padding:6px 4px 6px 6px;
	margin:6px 0;
}

.k-portal-collection.k-portal-collection-maximized + .k-portal-collection.k-portal-collection-minimized {
	border-color:transparent;
	padding-top:0;
}


.k-portal-collection-activities-outer-wrapper {
	display:flex;
	align-items: flex-start;
}

.k-portal-collection-header {
	// padding-bottom:2px;
	// padding-left:4px;
	flex:0 0 250px;
	margin-top:16px;
	padding-bottom:8px;
	font-size:18px;
	line-height:24px;
	color:#fff;
	display:flex;
	align-items: center;
}

.k-portal-activities-activities-wrapper-outer {
	flex:1 0 auto;
	display:flex;
	align-items: center;
}

.k-portal-activities-activities-wrapper {
	display:flex;
	flex-wrap:wrap;
	overflow-x: auto;
	align-items:center;
	padding-left:40px;	// width of left btn for non-wrapped categories
	flex: 0 0 calc(100vw - 308px);
	// background-color:#999;
}
.k-portal-activities-activities-wrapper::-webkit-scrollbar {
	display: none;
}

.k-portal-activities-activity-wrapper {
	flex:0 1 auto;
	min-width:208px;
	max-width:208px;
}

// updated css when the collection is minimized
.k-portal-collection.k-portal-collection-minimized {
	margin-top:0;
	margin-bottom:0;
	// padding-top:12px;
	// padding-bottom:12px;
	border-top-color:#333;
	border-radius:0;
	background-color:transparent;

	.k-portal-collection-activities-outer-wrapper {
		align-items: center;
	}

	.k-portal-collection-header {
		font-size: 14px;
		line-height:18px;
		margin-top:0;
		padding-bottom:0;
	}

	.k-portal-activities-activities-wrapper {
		padding:0;
		flex-wrap: nowrap;
		flex: 0 0 calc(100vw - 360px);
	}

	.k-portal-activities-activity-wrapper {
		min-width:132px;
	}

	.k-portal-collection--left, .k-portal-collection--right {
		margin:0 4px;
		.v-btn {
			opacity:0.9;
			.fas { font-size:24px!important; }
		}
	}
}

.k-portal-collection:first-of-type {
	border-top-color:transparent;
}

.k-portal-collection-header-title {
	color:#fff!important;
}

// this is set in Collections to wrap the featured collections, where it's always "dropped down"
.k-portal-collection-featured-collection-wrapper {
	.k-portal-collection-header {
		flex:0 0 36px;
	}
	.k-portal-collection-header-editor-btn, .k-portal-collection-header-title {
		display:none;
	}
	.k-portal-activities-activities-wrapper {
		padding:0;
		// the activities should take up the whole screen in this case
		flex: 0 0 calc(100vw - 92px);
	}
	.k-portal-collection.k-portal-collection-minimized {
		.k-portal-activities-activities-wrapper {
			flex: 0 0 calc(100vw - 146px);
		}
	}
}

// smartphone screens, landscape or portrait
@media (max-width: 760px) {
	.k-teacher-start-view {
		.k-portal-collection {
			margin-top:15px;
			border-radius:0px;

			.k-portal-collection--left, .k-portal-collection--right {
				display:none;
			}

			.k-portal-collection-header {
				font-size:16px;
			}

			.k-portal-activities-activities-wrapper {
				.k-portal-activities-activity-wrapper {
					min-width:170px;
					max-width:170px;
				}
			}
		}
	}
}
</style>
