<template><div>
	<v-dialog v-model="dialog_open" scrollable :fullscreen="$vuetify.breakpoint.xsOnly" max-width="800" overlay-opacity="0.9" light persistent>
		<v-card class="k-dark-dialog-card k-activity-settings-form" :style="$vuetify.breakpoint.xsOnly?'border:0!important':''">
			<v-card-text style="color:#222; font-size:18px;" class="pt-2">

				<div class="mb-3 mt-4">
					<span v-html="$store.state.site_config.app_name_html"></span>
					<span class="pl-1">is a digital learning tool for K-12 teachers and students.</span>
					<!-- <span class="pl-2">is a digital classroom teaching tool that promotes active learning.</span> -->
				</div>

				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="1" @click="more(1)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>Each {{app_noun}} activity can include any number of student-facing digital exercises <v-btn x-small color="primary" class="k-tight-btn" text @click="more(1)">More…</v-btn>
					<div class="k-about-more-info" data-more-info-section="1">
						Many digital applications for K-12 education are aimed at finding out what students already know, or helping them review topics they’ve already tried to learn.
						<div class="mt-2">{{app_noun}} is designed to <i><b>help students learn more effectively in the first place.</b></i></div>  
						<div class="mt-2">And the {{app_noun}} system has been built in a way that <i><b>complements what teachers are already doing in K-12 classrooms</b></i> and actually <i><b>saves them time,</b></i> rather than placing new burdens on teachers.</div>
					</div>
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="2" @click="more(2)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>Students experience {{app_noun}} as a game <v-btn x-small color="primary" class="k-tight-btn" text @click="more(2)">More…</v-btn>
					<div class="k-about-more-info" data-more-info-section="2">The first challenge in getting a student to learn is get them to <b><i>try</i></b> to learn. To students, a {{app_noun}} activity is an <b>interactive game</b> where their goal is to earn as many stars as they can. So completing a {{app_noun}} activity is much more <b>engaging</b> for a student than completing another worksheet.</div>
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="3" @click="more(3)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>Cognitive science makes learning experiences highly effective <v-btn x-small color="primary" class="k-tight-btn" text @click="more(3)">More…</v-btn>
					<div class="k-about-more-info" data-more-info-section="3">What students are really doing in most {{app_noun}} activities is reading (and/or watching a video), then responding to a series of “queries” that ask them about what they’ve read (or watched). If they don’t know the correct answer to a query right away, they’re guided to try to <b>generate</b> the answer themselves, and are then given <b>immediate feedback</b> to correct any misunderstanding. These and other activity policies are based on firmly established <b>cognitive scientific principles</b> known to optimize learning and memory.</div>
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="4" @click="more(4)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>Teachers can monitor and interact with students as they work <v-btn x-small color="primary" class="k-tight-btn" text @click="more(4)">More…</v-btn>
					<div class="k-about-more-info" data-more-info-section="4">Teachers can monitor student progress and interact with students as they’re completing an activity.  So while {{app_noun}} can be done by students on their own at home, it really shines as an activity for teachers to do with their students <b>together in the classroom</b>.</div>
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="5" @click="more(5)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>{{app_noun}} saves teachers time <v-btn x-small color="primary" class="k-tight-btn" text @click="more(5)">More…</v-btn>
					<div class="k-about-more-info" data-more-info-section="5">{{app_noun}} has an AI grading assistant called “{{app_noun}}-Bot” that can decrease teacher grading time for constructed response questions by <b>more than 50%</b>. Furthermore, {{bot_noun}} can award preliminary scores as students enter their responses, and all other query types in {{app_noun}} are automatically machine-graded. So each student’s “star score” is <b>instantly translated to an activity grade</b> when the student completes the activity (and if used with an LMS, instantly transferred to the LMS gradebook). Teachers can then <b>review and revise</b> grades as much as necessary and whenever they have time.</div>
						 <!-- each student’s “star score” is instantly translated to an activity grade, which can be transferred automatically to an LMS gradebook.</div> -->
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="6" @click="more(6)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>{{app_noun}} activities can be created in a matter of minutes <v-btn x-small color="primary" class="k-tight-btn" text @click="more(6)">More…</v-btn>
					<div class="k-about-more-info" style="border-bottom:0" data-more-info-section="6">Any teacher can take <b>any lesson materials</b> they already use and turn them into a {{app_noun}} activity in minutes. So teachers can use the same content they’re already comfortable with, and use it more effectively with {{app_noun}}.</div>
				</div>
				<div class="k-about-feature"><v-btn class="mr-2" small icon color="primary" data-more-info-btn="7" @click="more(7)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>{{app_noun}} can be used with Google Classroom, an LMS, or on its own <v-btn x-small color="primary" class="k-tight-btn" text @click="more(7)">More…</v-btn>
					<div class="k-about-more-info" style="border-bottom:0" data-more-info-section="7">Teachers can deploy {{app_noun}} in any assignment system they’re already using with their class.<span v-if="site_config.flavor!='velocity'"> <v-btn x-small color="primary" class="k-tight-btn mx-1" @click="show_assigning_dialog">Click here</v-btn> for more info.</span></div>
				</div>

				<div class="k-about-footer">{{app_noun}} is developed by <a href="https://commongoodlt.com/" target="_blank">Common Good Learning Tools</a>. <v-btn v-if="site_config.flavor!='velocity'" x-small color="primary" class="k-tight-btn" text @click="more(8)">More…</v-btn><!--<v-btn small icon color="primary" data-more-info-btn="8" @click="more(8)"><v-icon>fas fa-arrow-circle-right</v-icon></v-btn>-->
					<div class="k-about-more-info" style="border-bottom:0; font-size:14px;" data-more-info-section="8">We are 100% committed to making {{app_noun}} available for free, forever, to any teacher that wants to use it. We also seek to partner with other non-profit organizations and for-profit companies to a) distribute {{app_noun}} as widely as possible; b) provide support to districts and teachers who need help administering {{app_noun}}; or c) publish pre-made {{app_noun}} activities.<div class="mt-3"><v-btn small color="primary" href="https://commongoodlt.com/contact-us" target="_blank"><v-icon small class="mr-2">fas fa-envelope</v-icon> CONTACT US</v-btn></div></div>
				</div>

			</v-card-text>
			<v-card-actions class="pa-3" style="border-top:1px solid #666;">
				<v-btn v-if="show_teacher_access_request" color="pink darken-4" dark @click="request_teacher_access" class="mx-2">Request Teacher Access <v-icon small class="ml-2">fas fa-chalkboard-teacher</v-icon></v-btn>
				<v-btn v-if="show_demo_activities_btn" color="primary" dark @click="show_demo_activities" class="mx-2">View Demo Activities <v-icon class="ml-2">fas fa-arrow-alt-circle-right</v-icon></v-btn>
				<v-btn v-if="show_demo_activities_links" color="pink darken-4" dark href="https://sparkl-ed.com/732-demo" target="_blank" class="k-tight-btn ml-2 mr-1">{{app_noun}} Student Intro</v-btn>
				<v-btn v-if="show_demo_activities_links" color="pink darken-4" dark href="https://sparkl-ed.com/763-demo" target="_blank" class="k-tight-btn ml-1">{{app_noun}} Teacher Intro</v-btn>
				<v-spacer></v-spacer>
				<v-btn color="secondary" @click="$emit('dialog_done')"><v-icon small class="mr-2">fas fa-check</v-icon> Done</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</div></template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
	components: {  },
	props: {
	},
	data() { return {
		dialog_open: true,
		more_open: [false, false, false, false, false, false, false, ]
	}},
	computed: {
		...mapState(['site_config']),
		...mapGetters(['app_noun', 'bot_noun']),
		// show teacher access request btn if user_is_instructor is explicitly set to false and we're not in the teacherapp
		// so this means we show the btn unless the user is already a teacher
		show_teacher_access_request() { return this.$store.state.user_is_instructor === false && !this.$store.state.ad && this.$vuetify.breakpoint.smAndUp },
		// show the demo activities btn if we're not already showing the demo activities and we're not showing an individual activity
		show_demo_activities_btn() { 
			return false
			return !this.$store.state.demo_collection && !vapp.activity_id 
		},
		// don't show either btn if we're in the teacherapp, in which case state.ad will be set
		show_demo_activities_links() { 
			return false
			return this.$store.state.ad 
		},
	},
	created() {
	},
	mounted() {
	},
	methods: {
		more(i) {
			let showing = (this.more_open[i-1] = !this.more_open[i-1])
			$(sr('[data-more-info-section=$1]', i)).slideToggle()
			$(sr('[data-more-info-btn=$1]', i)).css('transform', showing?'rotate(-90deg)':'rotate(0deg)')
		},

		request_teacher_access() {
			vapp.request_teacher_access()
		},

		show_demo_activities() {
			U.loading_start()
			vapp.initialize_app({show_demo_activities:'true'}, true)
			this.$emit('dialog_done')
		},

		show_assigning_dialog() {
			vapp.show_assigning_dialog()
		},
	}
}
</script>

<style lang="scss">
.k-about-more-info {
	display:none;
	// margin-top:8px;
	margin-left:12px;
	text-indent:0;
	margin-bottom:8px;
	border-bottom:1px solid #ccc;
	padding-bottom:16px;
	padding-top:4px;
	font-size:16px;
	// line-height:18px;
}

.k-about-feature {
	margin-left:36px;
	text-indent:-36px;
	margin-bottom:4px;
	line-height:20px;
}

.k-about-footer {
	font-size:14px;
	text-align:center;
	line-height:18px;
	border-top:1px solid #ccc;
	padding:8px 32px 0 32px;
	margin-top:16px;
}

.k-frontmatter-logo {
	font-family: $display-font;
	font-weight:bold;
	font-size:1.5em;
	letter-spacing:1px;
	margin:0;
}
</style>
