<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template><a class="k-activity-card elevation-3" :class="card_class" :style="card_style" @click="card_clicked" :href="card_click_href"><v-hover v-slot:default="{hover}"><div>
	<div class="k-activity-card--title">
		<a @click.stop="card_clicked" :href="card_click_href" v-html="activity.title" class="k-activity-card--title-link"></a>

		<v-menu offset-y bottom left><template v-slot:activator="{on}"><v-btn v-if="!ac_show_all" v-visible="hover" class="k-activity-card-collapsed-menu-btn" v-on="on" @click.native.stop.prevent icon x-small color="#fff"><v-icon x-small>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item v-if="user_is_instructor" :href="student_preview_url"><v-icon small style="width:40px;padding-right:10px;">fas fa-glasses</v-icon><v-list-item-title>Preview Activity as a Student</v-list-item-title></v-list-item>
				<v-list-item @click="show_activity_info=true"><v-icon small style="width:40px;padding-right:10px;">fas fa-info-circle</v-icon><v-list-item-title>More info about this activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="show_activity_link=true"><v-icon small style="width:40px;padding-right:10px;">fas fa-link</v-icon><v-list-item-title>Show Activity Link</v-list-item-title></v-list-item>
				<v-divider v-if="user_is_instructor"/>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="rename_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-edit</v-icon><v-list-item-title>Rename Activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="duplicate_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-clone</v-icon><v-list-item-title>Duplicate Activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor" @click="export_archive"><v-icon small style="width:40px;padding-right:10px;">fas fa-file-export</v-icon><v-list-item-title>Export Activity Archive</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="delete_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-times</v-icon><v-list-item-title>Delete Activity</v-list-item-title></v-list-item>
				<v-divider/>
				<v-list-item><v-list-item-title class="text-center"><i>Activity ID: {{activity.activity_id}}</i></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>

	</div>

	<div v-show="ac_show_all" class="k-activity-card--stats">
		<div>{{stars_available_display}} <v-icon color="#fff" small style="margin-top:-4px">fas fa-star</v-icon><span v-if="result_count_display!=''"> ({{result_count_display}})</span></div>
		<v-btn v-if="card_btn_text" x-small color="rgba(100,100,100,0.4)" dark class="k-tight-btn ml-1" @click.stop="card_clicked" :href="card_click_href">{{card_btn_text}} <v-icon class="ml-1" x-small>fas fa-arrow-alt-circle-right</v-icon></v-btn>

		<v-menu offset-y bottom left><template v-slot:activator="{on}"><v-btn v-on="on" @click.native.stop.prevent icon small color="#fff"><v-icon small>fas fa-ellipsis-v</v-icon></v-btn></template>
			<v-list dense>
				<v-list-item v-if="user_is_instructor" :href="student_preview_url"><v-icon small style="width:40px;padding-right:10px;">fas fa-glasses</v-icon><v-list-item-title>Preview Activity as a Student</v-list-item-title></v-list-item>
				<v-list-item @click="show_activity_info=true"><v-icon small style="width:40px;padding-right:10px;">fas fa-info-circle</v-icon><v-list-item-title>More info about this activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="show_activity_link=true"><v-icon small style="width:40px;padding-right:10px;">fas fa-link</v-icon><v-list-item-title>Show Activity Link</v-list-item-title></v-list-item>
				<v-divider v-if="user_is_instructor"/>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="rename_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-edit</v-icon><v-list-item-title>Rename Activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="duplicate_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-clone</v-icon><v-list-item-title>Duplicate Activity</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor" @click="export_archive"><v-icon small style="width:40px;padding-right:10px;">fas fa-file-export</v-icon><v-list-item-title>Export Activity Archive</v-list-item-title></v-list-item>
				<v-list-item v-if="user_is_instructor&&!context_lti&&mine" @click="delete_activity"><v-icon small style="width:40px;padding-right:10px;">fas fa-times</v-icon><v-list-item-title>Delete Activity</v-list-item-title></v-list-item>
				<v-divider/>
				<v-list-item><v-list-item-title class="text-center"><i>Activity ID: {{activity.activity_id}}</i></v-list-item-title></v-list-item>
			</v-list>
		</v-menu>
	</div>

	<!-- <div v-show="hover" class="k-activity-card-hover-activity_id">Activity ID: {{activity.activity_id}}</div> -->

	<ActivityLink v-if="show_activity_link" :activity_id="activity.activity_id" :activity_title="activity.title" :gc_share_body="gc_share_body" :mine="mine" :from_portal="true" @dialog_done="show_activity_link=false" />

	<v-dialog v-model="show_activity_info" persistent max-width="650px">
		<v-card class="k-activity-card-more-info-card" :class="card_class">
			<v-card-title><b>{{activity.title}}</b></v-card-title>
			<v-card-text style="font-size:16px;">
				<div v-if="activity.activity_instructions" class="mb-4">
					<div class="k-activity-card--info-header">Activity Instructions:</div>
					<div v-html="activity_instructions"></div>
				</div>

				<div class="mb-4">Students can earn up to <b>{{stars_available_display}}</b> <v-icon small style="margin-top:-4px">fas fa-star</v-icon>, and can be expected to take approximately <b>{{avg_time_mins}} {{avg_time_mins_word}}</b> to complete the activity.</div>
				<ul v-if="user_is_instructor">
					<li><span class="k-activity-card--info-header">Subject(s):</span> <span v-html="subjects_info_display"></span></li>
					<li><span class="k-activity-card--info-header">Grade Level(s):</span> <span v-html="grades_info_display"></span></li>
					<li><span class="k-activity-card--info-header">Created:</span> <span v-html="creation_info_display"></span></li>
					<li v-if="result_count_display!=''"><span class="k-activity-card--info-header">Student Result Records:</span> <span v-html="result_count_display"></span></li>
					<li><span class="k-activity-card--info-header">Activity ID:</span> {{activity.activity_id}} <span v-if="activity.duplicate_of">(duplicate of Activity ID {{activity.duplicate_of}})</span></li>
				</ul>

			</v-card-text>
			<v-card-actions class="pa-4">
				<v-btn color="secondary" dark @click.stop="show_activity_info=false">Close</v-btn>
				<v-spacer/>
				<v-btn v-if="user_is_instructor" color="secondary" dark :href="student_preview_url" class="mr-2"><v-icon small class="mr-2">fas fa-glasses</v-icon>Student Preview</v-btn>
				<v-btn v-if="card_btn_text_verbose" color="primary" @click="card_clicked" :href="card_click_href">{{card_btn_text_verbose}} <v-icon class="ml-2" small>fas fa-arrow-alt-circle-right</v-icon></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</div></v-hover></a></template>

<script>
import { mapState, mapGetters } from 'vuex'
import ActivityLink from '@/../../app-common/components/ActivityLink'

export default {
	components: { ActivityLink },
	props: {
		activity: { type: Object, required: true },
		ac_show_all: { type: Boolean, required: true },
	},
	data() { return {
		card_color: '',
		highlight_color: '#FFA000',	// amber-darken-2
		show_activity_link: false,
		show_activity_info: false,
	}},
	computed: {
		...mapState(['user_is_instructor', 'user_is_student', 'user_info', 'groups', 'teacher_collections', 'demo_collection']),
		...mapGetters(['context_standalone', 'context_gc', 'context_lti', 'signed_in']),
		activity_url() {
			let url = vapp.activity_url(this.activity.activity_id)
			return url
		},
		student_preview_url() {
			let url = this.activity_url
			if (url.indexOf('?') > -1) url += '&demo'	// localhost form
			else url += '-demo'	// server form
			return url
		},
		card_click_href() {
			if (!this.card_btn_text || this.context_lti || this.context_gc) {
				return ''	// not openable

			// if we can only preview, return the student_preview_url
			} else if (this.activity_can_only_be_previewed) {
				return this.student_preview_url

			} else {
				// open the activity with its "normal" url
				return this.activity_url
			}
		},
		activity_in_allaccess_collection() {
			for (let c of this.teacher_collections) {
				if (c.type == 'allaccess-s' && c.includes_activity(this.activity.activity_id)) return true
			}
			return false
		},
		activity_can_be_previewed() {
			// return true if the activity CAN be previewed (note that an activity could be openable both normally and in demo mode)

			// console.log(this.activity.title + ': ' + this.activity.student_access_policy)

			// teachers can preview anything
			if (this.user_is_instructor) return true

			// activities marked as student_access_policy => no_sign_in can always be previewed
			if (this.activity.student_access_policy == 'no_sign_in') return true

			// if we get to here you can't preview it
			return false
		},
		activity_can_only_be_previewed() {
			// return true if the activity can ONLY be previewed -- i.e. you have to open it in the '-demo' mode

			// if you're not signed in, you can only preview everything
			if (!this.signed_in) return true

			// if you're an editor, you can surely open it normally
			if (this.activity.user_is_editor()) return false

			// if you're a student
			if (this.user_is_student) {
				// you can open it normally if it's in a *student* group that *includes the activity*
				// (you might have a featured_collection class that you're not in, but in that case the group won't have loaded the activity)
				for (let c of this.groups) if (c.includes_activity(this.activity.activity_id)) return false

				// and you can also open it normally if it's in an allaccess-s collection
				if (this.activity_in_allaccess_collection) return false

			} else {
				// else you're a teacher, and you can open it normally iff it's in a student or teacher collection you're subscribed to, or it's a non-restricted teacher activity
				// NOTE: as of now, a teacher can open in teacher mode any activity they can see in the portal, so we're always going to find the activity here and return false
				for (let c of this.groups) if (c.includes_activity(this.activity.activity_id) && c.includes_user()) return false
				for (let c of this.teacher_collections) if (c.includes_activity(this.activity.activity_id) && (!c.restricted || c.includes_user())) return false
				// or you can open it normally if it's in the demo collection
				if (this.demo_collection && this.demo_collection.includes_activity(this.activity.activity_id)) return false
			}

			// if we get to here you have to open in demo mode
			return true
		},
		card_style() {
			if (!empty(this.card_color)) {
				return 'background-color:' + this.card_color
			}
		},
		card_class() {
			let c = ''

			let n = this.activity.activity_id
			for (let i = 0; i < this.activity.title.length; ++i) {
				n += this.activity.title.charCodeAt(i)
			}
			n = (n % 15) + 1
			c += ' k-tile-color-' + n

			// add class to reduce font size for long names
			let title_text = U.html_to_text(this.activity.title)
			let title_len = title_text.length
			let word_len = title_text.split(/\s+/).sort((a,b)=>b.length-a.length)[0].length
			if (title_len > 45 || word_len > 12) c += ' k-activity-card-really-long-title'
			else if (title_len > 30 || word_len > 9) c += ' k-activity-card-long-title'

			return c
		},
		card_btn_text() {
			if (this.user_is_instructor) {
				// NOTE: as of now, a teacher can open in teacher mode any activity they can see in the portal, so they would never see 'Preview'
				if (this.activity_can_only_be_previewed) return 'Preview'
				if (this.context_lti) return 'Choose'
				if (this.context_gc) return 'Assign'
				// context_standalone
				return 'Open'

			} else if (this.user_is_student) {
				if (this.activity_can_only_be_previewed) {
					if (!this.activity_can_be_previewed) return ''
					return 'Preview'
				}
				return 'Open'

			} else { // not signed in
				if (!this.activity_can_be_previewed) return ''
				return 'Preview'
			}
		},
		card_btn_text_verbose() {
			if (this.user_is_instructor) {
				if (this.activity_can_only_be_previewed) return 'Preview Activity'
				if (this.context_lti) return 'Choose Activity'
				if (this.context_gc) return 'Assign in GC'
				// context_standalone
				return 'Open Activity'

			} else if (this.user_is_student) {
				if (this.activity_can_only_be_previewed) {
					if (!this.activity_can_be_previewed) return ''
					return 'Preview Activity'
				}
				return 'Open Activity'

			} else { // not signed in
				if (!this.activity_can_be_previewed) return ''
				return 'Preview Activity'
			}
		},
		stars_available_display() {
			if (this.activity.stars_available_computed) return this.activity.stars_available
			else return '–'
		},
		result_count_display() {
			// return a result count if the result count was actually looked up; empty string if not (indicated by -1)
			if (this.activity.result_count > -1) return this.activity.result_count
			else return ''
			// if (this.activity.result_count > 0) return this.activity.result_count
			// else return ''
		},
		avg_time_mins() {
			if (!this.activity.stars_available_computed) {
				return ''
			}
			// for now, estimate 12 seconds per star
			let stars = (this.activity.stars_available_computed) ? this.activity.stars_available : 0
			let s = 12 * stars
			let m = Math.round(s / 60)
			if (m < 1) m = 1
			return m
		},
		avg_time_mins_word() {
			if (empty(this.avg_time_mins)) return ''
			return U.ps('minute', this.avg_time_mins)
		},
		avg_time_mins_word_abbrev() {
			if (empty(this.avg_time_mins)) return ''
			return 'm'
			return U.ps('min.', this.avg_time_mins, 'mins.')
		},
		gc_share_body() {
			let body = 'Complete this Sparkl activity.'
			if (this.activity.stars_available_computed) {
				body += sr(' You can earn up to $1 $2, and you can expect the activity to take around $3 $4 to complete.', this.activity.stars_available, U.ps('star', this.activity.stars_available), this.avg_time_mins, this.avg_time_mins_word)
			}
			return body
		},
		grade_range() {
			let grades = this.activity.mappings.filter(x=>x.type=='grade').sort((a,b)=>{
				return U.grade_val(a.mapping) - U.grade_val(b.mapping)
			})
			if (grades.length == 0) return ''
			if (grades.length == 1) return 'Grade ' + grades[0].mapping[2]
			return sr('Grades $1-$2', grades[0].mapping.substr(2), grades[grades.length-1].mapping.substr(2))
		},
		subjects_info_display() {
			let subjects = this.activity.mappings.filter(x=>x.type=='subject')
			if (subjects.length == 0) return '–'
			let text = ''
			for (let s of subjects) {
				if (!empty(text)) text += ', '
				if (s.mapping == 'featured') {
					text += 'Featured Activities'
				} else if (!empty(this.$store.state.subjects[s.mapping])) {
					text += this.$store.state.subjects[s.mapping].l
				} else {
					text += s.mapping
				}
			}
			return text
		},
		grades_info_display() {
			let gr = this.grade_range
			if (empty(gr)) return '–'
			return gr
		},
		creation_info_display() {
			return this.activity.created_at.replace(/ \d\d:\d\d:\d\d/, '')

			// let text = this.activity.created_at.replace(/ \d\d:\d\d:\d\d/, '') + ', by ' + this.activity.editors[0]
			// if (this.mine) text += ' <i>(that’s you!)</i>'
			// return text
		},
		mine() {
			return this.activity.user_is_editor()
		},
		activity_instructions() {
			return this.activity.activity_instructions.replace(/\n/g, '<br>')
		},
	},
	created() {
	},
	mounted() {
		this.set_animation_duration()
	},
	methods: {
		set_animation_duration() {
			return
			// while this wiggling looks cool, it takes a significant amount of processor time to do if we have a lot of activities
			if (this.$store.state.activities.length > 25) return

			// start by setting every activity to flash at some point in the first 10.25 seconds
			$(this.$el).addClass('k-activity-card-wiggle-fast')
			let initial_duration = Math.random() * 10 + 0.25
			this.$el.style.animationDuration = initial_duration.toFixed(3) + 's'
			setTimeout(x=>{
				// then 100 ms after this first flash, start it wiggling indefinitely at a random rate
				$(this.$el).removeClass('k-activity-card-wiggle-fast')
				$(this.$el).addClass('k-activity-card-wiggle')
				let duration = (Math.random() * 10 + 10).toFixed(3) + "s"
				this.$el.style.animationDuration = duration
			}, initial_duration * 1000 + 100)
		},

		open_url(url) {
			// window.open(url)
			window.location = url
		},

		card_clicked($evt) {
			// if card_btn_text is empty, the user can't actually open the activity, so tell them that nicely
			if (!this.card_btn_text) {
				let msg
				// if the activity is part of an allaccess-s collection, they *can* access if the sign in, so tell them that
				if (this.activity_in_allaccess_collection && !this.signed_in) {
					msg = '<div class="mb-2">You can access the student version of this activity by signing in.</div> If you’re a teacher and would like to preview the activity or use it with your students, click “REQUEST TEACHER ACCESS” below.'
				} else {
					msg = '<div class="mb-2">Sorry, but you don’t have access to open this activity.</div> If you’re a teacher and would like to preview the activity or use it with your students, click “REQUEST TEACHER ACCESS” below.'
				}

				this.$confirm({
				    // title: 'Are you a witch?',
				    text: msg,
					cancelColor: 'pink darken-4',
				    cancelText: 'Request Teacher Access',
					dialogMaxWidth: 600
				}).then(y => {
					// the 'OK' btn means dismiss the dialog
				}).catch(n=>{
					// the 'cancel' btn means request teacher access
					vapp.request_teacher_access()
				}).finally(f=>{})

				$evt.preventDefault()

			// LTI - whether mine or not
			} else if (this.context_lti) {
				// choose this activity to use in the LMS
				this.choose_for_lti()
				$evt.preventDefault()

			// GC - whether mine or not
			} else if (this.context_gc) {
				// choose this activity to use in GC
				this.choose_for_gc()
				$evt.preventDefault()
			}

			// if we get to here, link will be followed
		},

		delete_activity() {
			this.card_color = 'red'
			this.$confirm({
				text: 'Are you sure you want to delete this Activity?',
				acceptText: 'Delete'
			}).then(y => {
				this.$store.dispatch('delete_activity', this.activity.activity_id)
			}).catch(n=>{
			}).finally(f=>this.card_color = '');
		},

		rename_activity() {
			this.card_color = this.highlight_color
			this.$prompt({
				text: 'Enter a new title for this Activity:',
				initialValue: this.activity.title,
				acceptText: 'Save'
			}).then(title => {
				if (!empty(title)) {
					this.$store.dispatch('save_activity', {activity_id: this.activity.activity_id, title: title})
				}
			}).catch(n=>{
				console.log(n)
			}).finally(f=>this.card_color = '');
		},

		gc_share_link(url) {
			// see also app-common / ActivityLink.vue -- we want this url to be the same for both of these places
			return sr('https://classroom.google.com/share?url=$1&title=$2&body=$3&itemtype=assignment', url, this.activity.title, this.gc_share_body)
		},

		choose_for_gc() {
			// if you don't own the activity, make a copy of the activity, then send it to GC to be inserted
			// if you do own the activity, add its link to GC without first copying
			this.card_color = this.highlight_color

			let msg = '<p>Click “Choose Activity” to be redirected to an interface for adding the activity to Google Classroom, where you’ll select a class and then edit the GC assignment to which the Sparkl activity will be linked. <a href="javascript:$(\'.k-gc-more\').toggle()"><b>MORE INFO</b></a></p>'
			if (this.mine) {
				msg += '<p class="k-gc-more" style="display:none">If you’d like to create a version of this Sparkl activity that you can customize for a specific class, click “Cancel”, then click the three-dot menu in the lower corner of the tile for your activity and select “Duplicate Activity.” This will create a new copy of the activity that you can add to Google Classroom, then edit without changing the original.</p>'
			} else {
				msg += '<p class="k-gc-more" style="display:none">Since you’re not the author of this Sparkl activity, when you click “Choose Activity” the system will automatically create a copy of the activity for you, and this copy will be linked in your GC assignment. Any edits you make to the activity copy will have no effect on the original.</p>'
			}

			this.$confirm({
				title: 'Choose Activity for Google Classroom',
				text: msg,
				acceptText: 'Choose Activity',
				cancelText: 'Cancel',
				dialogMaxWidth: 700,
			}).then(y=>{
				// if this activity doesn't belong to the user, duplicate it first, then open the gc_share_link for the duplicate
				if (!this.mine) {
					let data = {
						original_activity_id: this.activity.activity_id,
						editor_email: this.$store.state.user_info.email,
					}
					this.$store.dispatch('duplicate_activity', data).then((activity_data)=>{
						let new_activity_id = activity_data.activity_id
						let url = this.gc_share_link(vapp.activity_url(new_activity_id))
						this.open_url(url)
					})
				} else {
					// else just open the gc_share_link
					let url = this.gc_share_link(this.activity_url)
					this.open_url(url)
				}
				this.card_color = ''
			}).catch(n=>{
				this.card_color = ''
			})
		},

		choose_for_lti() {
			this.card_color = this.highlight_color

			let msg = '<p>Click “Choose Activity” to verify that you want to link this activity with your LMS course assignment.</p>'

			// let msg = '<p>Click “Choose Activity” to use this activity with your LMS course. <a href="javascript:$(\'.k-lti-more\').toggle()"><b>MORE INFO</b></a></p>'
			// msg += '<p class="k-lti-more" style="display:none">If you created this activity yourself,.</p>'

			this.$confirm({
				title: 'Choose Activity for LMS',
				text: msg,
				acceptText: 'Choose Activity',
			}).then(y=>{
				this.$store.dispatch('configure_lti_link', {
					activity_id: this.activity.activity_id,
				})
				// configure_lti_link will redirect to the activity
				this.card_color = ''
			}).catch(n=>{
				this.card_color = ''
			})
		},

		duplicate_activity() {
			this.card_color = this.highlight_color
			let title, text, acceptText
			if (this.mine) {
				title = 'Duplicate Activity'
				text = 'Enter a title for the duplicate activity:'
				acceptText = 'Duplicate Activity'
			} else {
				title = 'Copy Activity'
				text = 'To copy the activity for use with your class, edit the activity title if you wish, then click “Copy Activity”:'
				acceptText = 'Copy Activity'
			}
			this.$prompt({
				title:title,
				text: text,
				initialValue: this.activity.title,
				acceptText: acceptText
			}).then(title => {
				if (!empty(title)) {
					let data = {
						original_activity_id: this.activity.activity_id,
						editor_email: this.$store.state.user_info.email,
						activity_data: {title: title},
					}
					this.$store.dispatch('duplicate_activity', data)
				}
			}).catch(n=>{
				console.log(n)
			}).finally(f=>this.card_color = '');
		},

		// note that this fn is also in teacherapp's App.vue; keep the two fns in synch
		export_archive() {
			// rather than reconstructing the activity and exercise records from the data in the client, pull all json from the db
			// to make sure we get an exact copy of what should go into the db when we import the archive

			U.ajax('get_json_for_archive', { activity_id: this.activity.activity_id }, result=>{
				if (result.status != 'ok') {
					console.log('Error getting JSON', result)
					reject()
					return
				}
				let json = JSON.parse(result.json)

				// slightly hacky solution from here: https://stackoverflow.com/questions/19721439/download-json-object-as-a-file-from-browser
				var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
			    var downloadAnchorNode = document.createElement('a')
			    downloadAnchorNode.setAttribute('href', dataStr)
				// set filename of downloaded json file
			    downloadAnchorNode.setAttribute('download', sr('ARCHIVE-$1.json', this.activity.title))
			    document.body.appendChild(downloadAnchorNode) // required for firefox
			    downloadAnchorNode.click()
			    downloadAnchorNode.remove()
			});
		},
	}
}
</script>

<style lang="scss">
// @keyframes wiggle_portal_activity_card {
// 	from { transform: rotate(0deg) scale(1); }
// 	1.5% { transform: rotate(-2deg) scale(1); }
// 	3% { transform: rotate(2deg) scale(1); }
// 	4.5% { transform: rotate(0deg) scale(1); }
// 	to { transform: rotate(0deg) scale(1);; }
// }
@keyframes wiggle_portal_activity_card {
	from { transform: rotate(0deg) scale(1); }
	24% { transform: rotate(0deg) scale(1); }
	25.5% { transform: rotate(-2deg) scale(1); }
	27% { transform: rotate(2deg) scale(1); }
	28.5% { transform: rotate(0deg) scale(1); }
	to { transform: rotate(0deg); }
}
@keyframes wiggle_portal_activity_card_fast {
	from { transform: rotate(0deg) scale(1); }
	80% { transform: rotate(0deg) scale(1); }
	85% { transform: rotate(-2deg) scale(1); }
	90% { transform: rotate(2deg) scale(1); }
	95% { transform: rotate(0deg) scale(1); }
	to { transform: rotate(0deg) scale(1);; }
}
.k-activity-card-wiggle {
	animation-name: wiggle_portal_activity_card;
	animation-iteration-count: infinite;
	// animation-duration: 0;
}
.k-activity-card-wiggle-fast {
	animation-name: wiggle_portal_activity_card_fast;
	animation-iteration-count: 1;
}

.k-activity-card {
	display:block;
	text-decoration:none;
	margin:6px;
	background-color:#222;
	border-radius:10px;
	padding:4px;
	// color:#111;
	color:#fff!important;
	border:0px solid transparent;
	position:relative;
	cursor:pointer;

	.k-activity-card-hover-activity_id {
		position:absolute;
		bottom:-6px;
		right:5px;
		font-size:8px;
		// color:#000;
		opacity:0.6;
	}
}

.k-activity-card--title {
	padding:4px;
	font-size:13px;
	line-height:15px;
	text-align: center;
	.k-activity-card--title-link {
		// color:#111!important;
		color:#fff!important;
		font-weight:bold;
		text-decoration:none;
	}
	.k-activity-card--title-link:hover {
		text-decoration:underline;
	}
}
.k-portal-collection-minimized .k-activity-card--title {
	margin:0 12px;
}

.k-activity-card-collapsed-menu-btn {
	position:absolute;
	right:2px;
	// top:calc(50% - 10px);
	top:6px;
}

.k-activity-card-long-title .k-activity-card--title { font-size:12px; line-height:14px; }
.k-activity-card-really-long-title .k-activity-card--title { font-size:10px; line-height:12px; }

.k-activity-card--stats {
	display:flex;
	justify-content: space-between;
	align-items: center;
	margin:4px 2px 2px 2px;
	padding:2px 0px 2px 8px;
	// color:$v-amber-darken-2;
	background-color:rgba(0,0,0,0.05);
	border-radius:4px;
	font-size:12px;
}

.k-activity-card--info-header {
	// color:#666;
	font-size:14px;
	font-weight:bold;
}

.k-activity-card--actions {
	display:flex;
	justify-content: space-between;
}

.k-activity-card-more-info-card {
	border-width:5px;
	border-style:solid;
	// background-color:#eee;
	border-radius:10px!important;
}

.k-tile-color-0 { background-color:$v-grey-darken-4; }
.k-tile-color-1 { background-color:$v-red-darken-4; }
.k-tile-color-2 { background-color:$v-pink-darken-4; }
.k-tile-color-3 { background-color:$v-purple-darken-4; }
.k-tile-color-4 { background-color:$v-deep-purple-darken-4; }
.k-tile-color-5 { background-color:$v-indigo-darken-4; }
.k-tile-color-6 { background-color:$v-blue-darken-4; }
.k-tile-color-7 { background-color:$v-light-blue-darken-4; }
.k-tile-color-8 { background-color:$v-cyan-darken-4; }
.k-tile-color-9 { background-color:$v-teal-darken-4; }
.k-tile-color-10 { background-color:$v-green-darken-4; }
.k-tile-color-11 { background-color:$v-light-green-darken-4; }
.k-tile-color-12 { background-color:$v-lime-darken-4; }
.k-tile-color-13 { background-color:$v-blue-grey-darken-4; }
.k-tile-color-14 { background-color:$v-brown-darken-4; }
.k-tile-color-15 { background-color:$v-deep-orange-darken-4; }
// orange, yellow, and amber are too light to work; they fail WCAG test

.v-dialog {
	.k-tile-color-0 { border-color:$v-grey-darken-4; }
	.k-tile-color-1 { border-color:$v-red-darken-4; }
	.k-tile-color-2 { border-color:$v-pink-darken-4; }
	.k-tile-color-3 { border-color:$v-purple-darken-4; }
	.k-tile-color-4 { border-color:$v-deep-purple-darken-4; }
	.k-tile-color-5 { border-color:$v-indigo-darken-4; }
	.k-tile-color-6 { border-color:$v-blue-darken-4; }
	.k-tile-color-7 { border-color:$v-light-blue-darken-4; }
	.k-tile-color-8 { border-color:$v-cyan-darken-4; }
	.k-tile-color-9 { border-color:$v-teal-darken-4; }
	.k-tile-color-10 { border-color:$v-green-darken-4; }
	.k-tile-color-11 { border-color:$v-light-green-darken-4; }
	.k-tile-color-12 { border-color:$v-lime-darken-4; }
	.k-tile-color-13 { border-color:$v-blue-grey-darken-4; }
	.k-tile-color-14 { border-color:$v-brown-darken-4; }
	.k-tile-color-15 { border-color:$v-deep-orange-darken-4; }
}

// smartphone screens, landscape or portrait
@media (max-width: 760px) {
	.k-activity-card {
		margin:5px;

		.k-activity-card-hover-activity_id {
			display:none!important;
		}
	}

	.k-activity-card--title {
		padding:0 0 4px 4px;
		font-size:13px;
		line-height:16px;
	}

	.k-activity-card--stats {
		display:flex;
		justify-content: space-between;
		margin:4px 2px 2px 2px;
		padding:4px;
		font-size:10px;

		.fa-star {
			font-size:10px!important;
		}
	}

	.k-activity-card--info-header {
		color:#666;
		font-size:14px;
		font-weight:bold;
	}

	.k-activity-card--actions {
		display:none;
	}
}
</style>
